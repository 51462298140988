
/* [src] src/support-app/KontoController.js */
(function () {
  "use strict";

  angular.module("MeldecenterApp").controller("KontoController", function ($scope, ToolsService, _http, Requests, $timeout) {
    $scope.setTitle("Firmen-Konto");
    
    var vm = this;
    vm.umsaetze = [];
    vm.rdyForExport = 0;
    
    vm.filter = "Unbekannt";
    $scope.$watch(function(){ return vm.filter;}, function(){ load(); });
    
    vm.ladeNeueUmsaetze = function(e){
      var historisch = e.altKey || e. shiftKey || e.metaKey || e.ctrlKey;
      ToolsService.showToast("Umsätze werden geladen (historisch: " + historisch + ")", "info");
      
      _http.post("/api/intern/crm/firmenkonto/laden?historisch=" + historisch, function (data) {
        ToolsService.showToast("Umsätze aktuell", "info");
        load();
      });
    };
    
    vm.changeStatus = function(u, newStatus){
      if(u.status === 'Buchung_durch_CRM'){
        if(!confirm("Hierdurch wird die bestehende FiBu-Buchung entfernt. Soll fortgefahren werden?")) { return; }
      }
      
      var update = function(newStatus, kdnr, beleg){
          _http.post("/api/intern/crm/firmenkonto/umsatz/" + u.id + "/status",
            { neuerStatus: newStatus, kdnr: kdnr, beleg: beleg },
            function (uNeu) {
              u.status = uNeu.status;
              u.fibuid = uNeu.fibuid;
            }
        );
      };
      
      if(newStatus === 'Buchung_durch_CRM'){
        var kdnr = prompt("Kundennummer", getSelectedKdnr());
        if(!kdnr){ return; }
        
        _http.get("api/intern/crm/rechnung/fibu/buchungen/" + kdnr, function (data) {
          var o = data.ops.length>0 ? data.ops[0].posten : "";

          var beleg = prompt("Belegnummer", o);
          if(!beleg){ return; }
          update(newStatus, kdnr, beleg);
        }, function(){
          var beleg = prompt("Belegnummer");
          if(!beleg){ return; }
          update(newStatus, kdnr, beleg);
        });
        
      }else{
        update(newStatus, "", "");
      }
    };
    
    var getSelection = function() {
      var kdnr = "";
      if (window.getSelection) { kdnr = window.getSelection().toString();} else if (document.selection && document.selection.type !== "Control") { kdnr = document.selection.createRange().text; }
      return kdnr;
    };
    
    var getSelectedKdnr = function(){
      var kdnr = getSelection();
      if(!kdnr || !(/^\d\d\d\d\d$/.test(kdnr))){ return; }
      return kdnr;
    };

    var getSelectedRNr = function(){
      var kdnr = getSelection();
      if(!kdnr || !(/^\d\d\d\d\d\d$/.test(kdnr))){ return; }
      return kdnr;
    };
    
    
    
    $scope.addGlobalKeyListener({
      pagename: "konto",
      handler: function(e){
        if(e.key === " "){
          var kdnr = getSelectedKdnr();
          if(kdnr){
            window.open("#ckundendetails?kdnr=" + kdnr + "&rechnung",'_blank');
            return;
          }
          var rnr = getSelectedRNr();
          if(rnr){
            window.open("#rechnungen?rnr=" + rnr,'_blank');
          }
        }
      }
    });
    
    
    vm.currentDate = function(){
      var two = function(x){ return x.toString().length === 1 ? "0" + x : x; };
      var date=new Date();
      return     date.getFullYear()   + "-" +
             two(date.getMonth() + 1) + "-" +
             two(date.getDate()     ) + "_" +
             two(date.getHours()    ) + "-" +
             two(date.getMinutes()  );
    };
    
    
    vm.triggerAutoHandling = function(){
      _http.post("/api/intern/crm/firmenkonto/automatische_verarbeitung", function () {
        ToolsService.showToast("Automatische Verarbeitung abgeschlossen", "info");
        load();
      });
    };
    
    vm.editKommentar = function(u){
      var kommentarNeu = prompt("Kommentar", u.kommentar);
      if(kommentarNeu === null) { return; }
      
      _http.post("/api/intern/crm/firmenkonto/umsatz/" + u.id + "/kommentar", { kommentar: kommentarNeu }, function () {
          u.kommentar = kommentarNeu;
      });
    };
    
    
    vm.selectedRow = null;
    
    vm.select = function(u){
      if(vm.selectedRow === u){
        vm.selectedRow = null;
      }else{
        vm.selectedRow = u;
      }
    };
    
    vm.loadWithSmallDelay = function(){
      setTimeout(load, 1000);
    };
    
    vm.kontostand = "";
    vm.ladeKontostand = function(){
      _http.getPlain("/api/intern/crm/firmenkonto/kontostand", function (kontostand) {
        vm.kontostand = "Aktueller Kontostand: " + kontostand + "€";
      });
    };
    
    vm.load = function(){ load(); };
    
    var load = function(){
      _http.get("/api/intern/crm/firmenkonto?filter=" + vm.filter, function (data) {
        vm.umsaetze = data;
      });
      _http.get("/api/intern/crm/firmenkonto/anzahl_markiert_fuer_export", function (data) {
        vm.rdyForExport = data;
      });
    };
    
    return vm;
  });
})();
