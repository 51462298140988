
/* [src] src/support-app/TooltipDirective.js */
(function () {
  "use strict";

  var app = angular.module("MeldecenterApp");

  var tooltipPosition = function (event, x, y) {
    $('div.mc-tooltip').css({
      top: event.pageY + 10,
      left: event.pageX + 10,
      "z-index": 1000
    });
  };
  
  var tooltipHide = function (event) {
    $('div.mc-tooltip').remove();
  };

  var tooltipShow = function (event, content, x, y) {
    tooltipHide();
    var valueToShow = event.target.getAttribute("tooltip");
    if(valueToShow) { valueToShow = valueToShow.replace(/\n/g, "<br>"); }
    $('<div class="mc-tooltip">' + valueToShow + '</div>')
      .appendTo('body');
    tooltipPosition(event, x, y);
  };

  app.directive('tooltip', function () {
    return {
      restrict: 'A',
      scope: {
        active: '@active'
      },
      link: function (scope, element, attrs) {
        $(element).bind({
          mousemove: tooltipPosition,
          mouseenter: tooltipShow,
          mouseleave: tooltipHide,
          mousedown: tooltipHide
        });
      }
    };
  });
})();
