
/* [src] src/support-app/DatenListController.js */
(function () {
  "use strict";

  angular.module("MeldecenterApp").controller("DatenListenController",
    function ($scope, ToolsService, Requests, _http, _) {
      $scope.setTitle("Daten-Suche");
      
      var vm = this;
      
      
      // *********************************************************************************************************
      // ***** Table information
      vm.tables = [];
      
      _http.get("api/intern/ueberblick/structure?short=false", function (data) {  // load table infos
        vm.tables = data.tables;
      });
      
      
      // *********************************************************************************************************
      // ***** QUERY
      $scope.$on('pageUpdated', function (event, page) {
        if (page.indexOf("datenliste") > -1) {
          vm.loadQueryByUrlParameters();
          vm.loadData(true);
        }
      });
      
      /*** QUERY - MODEL ***/
      vm.query = {
        table: "QLDS" ,
        id: "",
        ident: "",
        type: "",
        status : "",
        inhalt : "",
        pagesize: 50,
        page: 1,
        date: ""
      };
      vm.querySent = {};
      
      vm.queryChanged = function(){
        var q = vm.query;var r = vm.querySent; return q.table !== r.table || q.id !== r.id || q.ident !== r.ident || q.type !== r.type || q.status !== r.status || q.inhalt !== r.inhalt || q.pagesize !== r.pagesize || q.date !== r.date;        
      };
      vm.loadQueryByUrlParameters = function(){
        var
        x = ToolsService.qs("table"   ); if(x){ vm.query.table    = x; }
        x = ToolsService.qs("id"      ); if(x){ vm.query.id       = x; }
        x = ToolsService.qs("ident"   ); if(x){ vm.query.ident    = x; }
        x = ToolsService.qs("type"    ); if(x){ vm.query.type     = x; }
        x = ToolsService.qs("status"  ); if(x){ vm.query.status   = x; }
        x = ToolsService.qs("inhalt"  ); if(x){ vm.query.inhalt   = x; }
        x = ToolsService.qs("pagesize"); if(x){ vm.query.pagesize = parseInt(x); }
        x = ToolsService.qs("page"    ); if(x){ vm.query.page     = parseInt(x); }
        x = ToolsService.qs("date"    ); if(x){ vm.query.date     = x; }
      };
      vm.loadQueryByUrlParameters();
      
      /** liefert true zurück, wenn url verändert worden ist */
      vm.urlRewrite = function(){
        var url = "#datenliste?";
                                          url +=     "table=" + vm.query.table;
        if   (vm.query.id             ) { url +=       "&id=" + vm.query.id      ; }
        else {
          if (vm.query.ident          ) { url +=    "&ident=" + vm.query.ident   ; }
          if (vm.query.type           ) { url +=     "&type=" + vm.query.type    ; }
          if (vm.query.status         ) { url +=   "&status=" + vm.query.status  ; }
          if (vm.query.inhalt         ) { url +=   "&inhalt=" + vm.query.inhalt  ; }
          if (vm.query.pagesize !== 50) { url += "&pagesize=" + vm.query.pagesize; }
          if (vm.query.page     !==  1) { url +=     "&page=" + vm.query.page    ; }
          if (vm.query.date           ) { url +=     "&date=" + vm.query.date    ; }
        }

        if(window.location.href.endsWith(url)){
          return false;
        }else{
          window.location.replace(url);
          return true;
        }
      };
      
      // ***** Type selection
      vm.selectedTypes = {};
      vm.typeSelectionWasOpened = false;
      $scope.$watch(function(){return angular.toJson(vm.selectedTypes);}, function(){   // selection -> string
        if(!vm.typeSelectionWasOpened){ return; }
        var types = [];
        var t = vm.selectedTypes;
        angular.forEach(t, function(value, key) {if(value) { this.push(key); } }, types);
        vm.query.type = types.sort().join(",");
      });
      vm.selectedTypesUpdate = function(){                                              // string -> selection
        vm.typeSelectionWasOpened = true;
        var t = vm.query.type;if(!t){t = "";}
        var parts = t.split(",").map(function(it){ return it.toUpperCase().trim(); });
        vm.types().forEach(function(existingType){ vm.selectedTypes[existingType.name] = parts.includes(existingType.name); });
      };
      vm.types = function(){                                                            // all types
        var table = vm.tables.find(function(t){return t.name === vm.query.table;});
        return table ? table.types : [];
      };
      
      // ***** Status selection
      vm.selectedStatuses = {};
      vm.statusSelectionWasOpened = false;
      $scope.$watch(function(){return angular.toJson(vm.selectedStatuses);}, function(){   // selection -> string
        if(!vm.statusSelectionWasOpened){ return; }
        var statuses = [];
        var s = vm.selectedStatuses;
        angular.forEach(s, function(value, key) {if(value) { this.push(key); } }, statuses);
        vm.query.status = statuses.sort().join(",");
      });
      vm.selectedStatusesUpdate = function(){                                              // string -> selection
        vm.statusSelectionWasOpened = true;
        var s = vm.query.status;if(!s){s = "";}
        var parts = s.split(",").map(function(it){ return it.toUpperCase().trim(); });
        vm.statuses().forEach(function(existingStatus){ vm.selectedStatuses[existingStatus.name] = parts.includes(existingStatus.name); });
      };
      vm.statuses = function(){
        var table = vm.tables.find(function(t){return t.name === vm.query.table;});
        return table ? table.statuses : [];
      };
      
      // *********************************************************************************************************
      // ***** Live-Filter
      vm.liveFilter = "";
      vm.dataFiltered = [];
      
      $scope.$watch(function(){ return vm.liveFilter;}, function(){ vm.filterData();} );
      
      vm.filterData = function(){
        if(!vm.liveFilter){
          vm.dataFiltered = vm.data;
          return;
        }
        
        vm.dataFiltered = [];
        var f = vm.liveFilter.toLowerCase();
        vm.data.forEach(function(d){
          if(
              d.some(function(v){ return v.toLowerCase().indexOf(f) > -1; })              // in den geladenden Daten  
              ||
              (vm.history[d[0]] && vm.history[d[0]].toLowerCase().indexOf(f) > -1)        // oder in der history
            ){
                vm.dataFiltered.push(d);
          }
        });
      };
      
      // *********************************************************************************************************
      // ***** History
      
      vm.history = {};
      vm.historyGeladen = false;
      vm.loadHistory = function(){
        var ids = vm.dataFiltered.map(function(d){ return d[0];}).join();
        
        _http.post("api/intern/datensuche/history", {"table": vm.querySent.table, "ids"    : ids}, function (data) {
          vm.history = data;
          vm.historyGeladen = true;
        }, "History konnte nicht geladen werden");
        
      };
      
      
      // *********************************************************************************************************
      // ***** Loading ....
      vm.loading = false;
      vm.keydown = function(e, field){
        if(field === "live"){
          if(e.key === "Escape"){ vm.liveFilter = ""; }
          return;
        }
        
        if(e.key === "Enter"){ vm.loadDataUi(); }
        if(e.key === "Escape"){ vm.query[field] = ""; }
      };
      vm.cols = [];
      vm.data = [];
      
      vm.nextPage = function(){
        if(vm.queryChanged())                         { vm.query.page = 1; } 
        else if(vm.data.length === vm.query.pagesize) { vm.query.page++;   }
        else { return; }
        vm.loadData();
      };

      vm.prevPage = function(){
        if(vm.queryChanged()     ){ vm.query.page = 1; }
        else if(vm.query.page > 1){ vm.query.page--;   }
        else { return; }
        vm.loadData();
      };
      
      
      vm.loadDataUi = function(){
        if(vm.queryChanged()){ vm.query.page = 1; }
        vm.loadData();
      };
      
      vm.loadData = function(skipRewriteUrl){
        if(!skipRewriteUrl) { 
          var urlVeraendert = vm.urlRewrite();
          if(urlVeraendert) { return; }           // durch urlRewrite wird Query automatisch neu gestartet
        }
        vm.historyGeladen = false;
        vm.query.type = vm.query.type.toUpperCase();
        vm.query.status = vm.query.status.toUpperCase();
        var r = vm.query;var q = vm.querySent; q.table = r.table;q.id = r.id;q.ident = r.ident;q.type = r.type;q.status = r.status; q.inhalt = r.inhalt; q.pagesize = r.pagesize; q.date = r.date;
        vm.loading = true;
        vm.dataFiltered = [];
        
        _http.post("api/intern/datensuche", {
          "table"    : vm.query.table   ,
          "id"       : vm.query.id      ,
          "ident"    : vm.query.ident   ,
          "type"     : vm.query.type    ,
          "status"   : vm.query.status  ,
          "inhalt"   : vm.query.inhalt  ,
          "pagesize" : vm.query.pagesize,
          "page"     : vm.query.page    ,
          "date"     : vm.query.date
        }, function (data) {
          vm.cols = data.cols;
          vm.data = data.data;
          vm.filterData();
          vm.loading = false;
        }, function () {
          vm.loading = false;
          ToolsService.showToast("Fehler beim Abfragen der Daten", "error");
        });
        
      };
      
      vm.loadData(true);
      
      vm.openTabs = function(){
        if(vm.dataFiltered.length > 20 && !confirm("Wollen Sie wirklich  >> " + vm.dataFiltered.length + " << Tabs öffnen?")){ return ;}
        
        vm.dataFiltered.forEach(function(d){
          window.open("#datendetails?table=" + vm.querySent.table + "&id=" + d[0],'_blank');
        });
      };

      
      return vm;
    });
})();