
/* [src] src/support-app/KundenListController.js */
(function() {
  "use strict";

  angular.module("MeldecenterApp").controller("KundenListController", function ($scope, _http, Requests, ToolsService) {
    $scope.setTitle("Kunden-Suche");

    var vm = this;

    vm.loading = true;
    vm.filter = "";
    vm.appliedFilter = "";
    vm.page = 1;
    vm.kunden = [];
    
    vm.changePage = function(delta){
      vm.page += delta;
      vm.load();
    };
    
    vm.addKunde = function() {
      var kdnr = prompt("Kundennummer?");
      if(!kdnr){ return; }
      var name = prompt("Firmenname?");
      if(!name){ return; }
      var weiter = confirm("Soll die Firma angelegt werden:\nKundennummer: "+kdnr+"\nName:"+name);
      if(!weiter){ return; }
      
      _http.post("api/intern/kunden/create",{kdnr: kdnr, name: name},function (res) {
        console.log(res);
        if(res.error){
          ToolsService.showToast("Fehler beim Anlegen: " + res.error, "error");
          return;  
        }
      
        ToolsService.showToast("Kunde angelegt", "info");
        location.href = "#kundendetails?kdnr=" + kdnr;
      }, "Fehler beim Request");
    };
    
  
    vm.load = function () {
      vm.loading = true;
      _http.get("api/intern/kunden/list?" + "filter=" + vm.filter + "&page=" + vm.page,function (kunden) {
        vm.kunden = kunden;
        vm.appliedFilter = vm.filter;
        vm.loading = false;
      }, "Fehler beim Laden der Kundenliste");
    };
    
    vm.onKey = function(event){
      if(event.code === "Enter"){
        vm.page = 1;
        vm.load();
      }else if(event.code === "Escape"){
        vm.page = 1;
        vm.filter = "";
        vm.load();
      }
    };
    

    vm.load();
  });
})();