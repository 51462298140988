
/* [src] src/support-app/QLBeitragssatzdateiController.js */
(function () {
  "use strict";

  angular.module("MeldecenterApp").controller("QLBeitragssatzdateiController", function ($scope, _http, Requests, ToolsService) {
    $scope.setTitle("QL-Beitragssatzdatei verwalten");
    
    var vm = this;
    vm.loading = false;
    
    var load = function(){
      vm.loading = true;
      _http.get("api/intern/qlbeitragssatzdatei/info", function(result){
        vm.data = result;
        vm.loading = false;
      });
    };
    
    vm.erzeugen = function(){
      _http.post("api/intern/qlbeitragssatzdatei/create", function(result){
        history.go(0);
      });
    };
    vm.freigeben = function(){
      _http.post("api/intern/qlbeitragssatzdatei/freigeben", function(result){
        history.go(0);
      });
    };
    
    load();
    return vm;
  });
})();
