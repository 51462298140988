
/* [src] src/reporting-app/ZeitraumDirective.js */
(function() {
  "use strict";

  angular.module("ReportingApp").directive('zeitraum', function($compile) {
    return {
      restrict: 'A',
      replace: true,
      templateUrl: 'reporting-app/zeitraum.tpl.html',
      controller: 'ZeitraumController',
      controllerAs: 'zctrl',
      scope: {
        'selectedDate': '=',
        'mode': '@mode',
        'toDateExcluded': '@toDateExcluded',
        'onChange': '&'
      }
    };
  });
})();