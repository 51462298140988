
/* [src] src/support-app/CopyableDirective.js */
(function () {
  "use strict";

  var app = angular.module("MeldecenterApp");

  app.directive('copyable', function () {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        $(element).bind({
          mouseenter: selectText,
          mouseleave: removeSelection
        });
      }
    };
  });

  var selectText = function (event, content, x, y) {
    var range;
    var doc = document;
    var element = event.target;
    if (doc.body.createTextRange) {
      range = document.body.createTextRange();
      range.moveToElementText(element);
      range.select();
    } else if (window.getSelection) {
      var selection = window.getSelection();
      range = document.createRange();
      range.selectNodeContents(element);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  };

  var removeSelection = function () {
    if (window.getSelection) {
      window.getSelection().removeAllRanges();
    } else if (document.selection) {
      document.selection.empty();
    }
  };

})();