
/* [src] src/support-app/HttpService.js */
(function () {
  "use strict";

  angular.module("MeldecenterApp").factory("_http", function ($http, $location, ToolsService) {

    var isColorInstance = $location.host().indexOf('color.grld.eu') !== -1;
    return {
      post: function (url, data, onSuccess, onError) {
        performRequest("POST", url, "json", data, onSuccess, onError);
      },
      postPlain: function (url, data, onSuccess, onError) {
        performRequest("POST", url, "text", data, onSuccess, onError);
      },
      get: function (url, data, onSuccess, onError) {
        performRequest("GET", url, "json", data, onSuccess, onError);
      },
      getPlain: function (url, data, onSuccess, onError) {
        performRequest("GET", url, "text", data, onSuccess, onError);
      },
      head: function (url, data, onSuccess, onError) {
        performRequest("HEAD", url, "json", data, onSuccess, onError);
      },
      delete: function (url, data, onSuccess, onError) {
        performRequest("DELETE", url, "json", data, onSuccess, onError);
      }
    };

    function performRequest(method, url, contentType, p0, p1, p2) {
      // ***** some crazy js parameter magic
      // s - string, o - object/null, f - function
      var types =
        (typeof p0).substring(0, 1) +
        (typeof p1).substring(0, 1) +
        (typeof p2).substring(0, 1);

      var data = null, onSuccess = null, successMessage = null, onError = null, errorMessage = null;
      if (types[0] === 's') {           // --> s**
        successMessage = p0;
        if (types[1] === 's') {         // --> ss*
          errorMessage = p1;
        } else if (types[1] === 'f') {  // --> sf*
          onError = p1;
        }
      } else if (types[0] === 'f') {    // --> f**
        onSuccess = p0;
        if (types[1] === 's') {         // --> fs*
          errorMessage = p1;
        } else if (types[1] === 'f') {  // --> ff*
          onError = p1;
        }
      } else {                          // --> o**
        data = p0;
        if (types[1] === 's') {         // --> os*
          successMessage = p1;
        } else if (types[1] === 'f') {  // --> of*
          onSuccess = p1;
        }
        if (types[2] === 's') {         // --> o*s
          errorMessage = p2;
        } else if (types[2] === 'f') {  // --> o*f
          onError = p2;
        }
      }

      // TODO: color instance specific handling needs to be pulled out into a separate service, needs to be reviewed by Marcel or Alexander
      var internalUrl = isColorInstance && url[0] === '/' ? url.substr(1) : url;

      // ***** actually perform the request
      $http({
        method: method,
        url: internalUrl,
        data: (data ? $.param(data) : null),
        headers: {
          'Content-Type': "application/x-www-form-urlencoded; charset=UTF-8"
        },
        responseType: contentType,
        transformResponse: [function (data) {
          return data;
        }]
      }).then(function (response) {
        if (onSuccess) {
          onSuccess(response.data);
        } else if (successMessage) {
          ToolsService.showToast(successMessage, "info");
        }
      }, function (response) {
        if (onError) {
          onError(response.data);
        } else if (errorMessage) {
          ToolsService.showToast(errorMessage, "error");
        } else {
          ToolsService.showToast("Fehler beim Request " + method + " " + url + " : " + response.status, "error");
        }
      });
    }
  });
})();
