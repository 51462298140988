
/* [src] src/reporting-app/Requests.js */
(function (angular) {
  'use strict';

  angular
    .module("ReportingApp")
    .constant("Requests", {
      "krankenkassendaten": "api/intern/reporting/krankenkassen",

      "elstam": "api/intern/reporting/elstam",
      "lstb": "api/intern/reporting/lstb",
      "lsta": "api/intern/reporting/lsta",
      "aag": "api/intern/reporting/aag",
      "gkvmm": "api/intern/reporting/gkvmm",
      "lndabfrage": "api/intern/reporting/lndabfrage",
      "lndnachweis": "api/intern/reporting/lndnachweis",
      "uvjm": "api/intern/reporting/uvjm",
      "sofortmeldung": "api/intern/reporting/sofortmeldung",
      "svmeldung": "api/intern/reporting/svmeldung",
      "beitragsnachweis": "api/intern/reporting/beitragsnachweis",
      "beitragserhebung": "api/intern/reporting/beitragserhebung",
      "betriebsdaten": "api/intern/reporting/betriebsdaten",
      "vsnrabfrage": "api/intern/reporting/vsnrabfrage",

      "elstam_change_code": "api/intern/reporting/elstam/config/status/",
      "lstb_change_code": "api/intern/reporting/lstb/config/status/",
      "lsta_change_code": "api/intern/reporting/lsta/config/status/",
      "aag_change_code": "api/intern/reporting/aag/config/status/",
      "gkvmm_change_code": "api/intern/reporting/gkvmm/config/status/",
      "lndabfrage_change_code": "api/intern/reporting/lndabfrage/config/status/",
      "lndnachweis_change_code": "api/intern/reporting/lndnachweis/config/status/",
      "uvjm_change_code": "api/intern/reporting/uvjm/config/status/",
      "sofortmeldung_change_code": "api/intern/reporting/sofortmeldung/config/status/",
      "svmeldung_change_code": "api/intern/reporting/svmeldung/config/status/",
      "beitragsnachweis_change_code": "api/intern/reporting/beitragsnachweis/config/status/",
      "beitragserhebung_change_code": "api/intern/reporting/beitragserhebung/config/status/",
      "betriebsdaten_change_code": "api/intern/reporting/betriebsdaten/config/status/",
      "vsnrabfrage_change_code": "api/intern/reporting/vsnrabfrage/config/status/",

      "elstam_vh_info": "api/intern/reporting/info/elstam_vh"
    });
})(window.angular);
