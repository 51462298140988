
/* [src] src/reporting-app/ReportingApp.module.js */
/**
 * Reporting-Modul definieren
 */
(function() {
  "use strict";

  var app = angular.module(
    "ReportingApp", ["MeldecenterApp", "jsonFormatter", "ui.bootstrap", "angularMoment", "anguFixedHeaderTable"],
    function($locationProvider) {
      $locationProvider.html5Mode({
        enabled: true,
        requireBase: false
      });
    });

  app.constant('_', window._);
  app.constant('Chart', window.Chart);
})();
