
/* [src] src/support-app/SaldenController.js */
(function () {
  "use strict";

  angular.module("MeldecenterApp").controller("SaldenController", function ($scope, ToolsService, _http, Requests, $timeout) {
    $scope.setTitle("Salden");
    
    var vm = this;
    
    vm.data = {};
    vm.loading = false;
    vm.selectedFilter = "";

    vm.haendler = "lorey";
    vm.haendlerFilterEnabled = false;
    vm.haendlerFilter = function(){
      if(vm.haendlerFilterEnabled){
        vm.haendler = prompt("Händler eingeben (Kundennummer)", vm.haendler);
      }
      vm.load();
    };

    
    vm.load = function(){
      vm.loading = true;
      var haendler = vm.haendlerFilterEnabled ? vm.haendler : "";
      
      _http.get("api/intern/crm/rechnung/fibu/salden?haendler="+haendler, function(data){
        vm.data = data;
        vm.loading = false;
        vm.selectFilter(vm.selectedFilter);
      });
    };
    
    vm.filter = function(nr){
      switch(nr){
      case 1: vm.selectFilter("saldoNullAberOP"); break;
      case 2: vm.selectFilter("saldoNullAberHinweis"); break;
      case 3: vm.selectFilter("kundenImHaben"); break;
      case 4: vm.selectFilter("kundenSaldoUngleichNull"); break;
      case 5: vm.selectFilter("kundenImSoll"); break;
      case 6: vm.selectFilter("kundenImSollUeberfaellig"); break;
      }
    };
    
    vm.selectFilter = function(name){
      vm.selectedFilter = name;
      vm.dataSelected = vm.data[name]; 
    };
    
    vm.updateSperre = function(k, status){
      var s = status;
      if(status === ''){
        k.sperre = null;
        s = 'keineSperre';
      }else{
        k.sperre = status;
      }
      _http.post("api/intern/crm/"+k.kdnr+"/sperre/" + s);
    };
   
    vm.filter(4);
    vm.load();
    
    return vm;
  });
})();
