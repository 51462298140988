
/* [src] src/support-app/MandantenDetailController.js */
(function () {
  "use strict";

  angular.module("MeldecenterApp").controller("MandantenDetailController", function ($scope, ToolsService, _http, Requests) {
    $scope.loading = true;

    $scope.mimId = 0;
    $scope.mims = [];
    $scope.currentlySelectedMim = null;

    $scope.daten = {};

    $scope.includeLinkedMandanten = !!ToolsService.qs("linked");
    $scope.showDS = !!ToolsService.qs("showDS");
    $scope.showMD = true;
    $scope.colorizeMandanten = false;
    $scope.colorizeByStNr = false;
    $scope.showSmall = true;

    $scope.$watch("includeLinkedMandanten", function (newValue) {
      $scope.colorizeMandanten = newValue;
    });
    $scope.$watch("colorizeMandanten", function () {
      $scope.colorMandanten();
    });
    $scope.$watch("colorizeByStNr", function () {
      $scope.colorMandanten();
    });

    $scope.loadMandanten = function () {
      $scope.mimId = ToolsService.qs("mimid");
      $scope.setTitle("Mandantendetails - " + $scope.mimId);
      _http.get(Requests.mandantLoadByMimId($scope.mimId),
        function (data) {
          $scope.mims = data;
          $scope.currentlySelectedMim = $scope.mims.filter(function (e) {
            return e.selected;
          })[0];
          $scope.colorMandanten();
          $scope.loadMeldedaten();
        }, "Fehler beim Laden");
    };

    $scope.colorMandanten = function () {
      var colorCounter = 1;
      var colorizeRow = function (currentStNr) { // FIXME: das kann z.B. bootstrap ziemlich einfach über css
        return function (row) {
          if (row.cells[1].stnr === currentStNr) {
            row.cells[1].colorClass = "c" + colorCounter;
          }
          if (row.cells[3].stnr === currentStNr) {
            row.cells[3].colorClass = "c" + colorCounter;
          }
        };
      };

      if ($scope.colorizeMandanten) {
        if ($scope.colorizeByStNr) {
          $scope.mims.forEach(function (mim) {
            mim.color = "";
          });

          for (var i = 0; i < $scope.mims.length; i++) {
            var mim = $scope.mims[i];
            if (mim.color) {
              continue;
            }
            // MIM
            mim.color = "c" + colorCounter;
            var currentStNr = mim.elsterstnr;
            var colorizeRowForStNr = colorizeRow(currentStNr);

            // andere Mandanten mit StNr
            for (var j = i; j < $scope.mims.length; j++) {
              var otherMandant = $scope.mims[j];
              if (otherMandant.elsterstnr === currentStNr) {
                otherMandant.color = "c" + colorCounter;
              }
            }
            // MDs/RMDs
            if ($scope.daten.rows) {
              $scope.daten.rows.forEach(colorizeRowForStNr);
            }

            colorCounter++;
          }
        } else {
          $scope.mims.forEach(function (mim) {
            mim.color = "c" + (colorCounter);
            // MDs/RMDs
            if ($scope.daten.rows) {
              $scope.daten.rows.forEach(function (row) {
                if (row.cells[1].mimid === '' + mim.id) {
                  row.cells[1].colorClass = "c" + colorCounter;
                }
                if (row.cells[3].mimid === '' + mim.id) {
                  row.cells[3].colorClass = "c" + colorCounter;
                }
              });
            }
            colorCounter++;
          });
        }
      } else {
        $scope.mims.forEach(function (mim) {
          mim.color = "";
        });
        if ($scope.daten.rows) {
          $scope.daten.rows.forEach(function (row) {
            row.cells[1].colorClass = "";
            row.cells[3].colorClass = "";
          });
        }
      }
    };

    $scope.loadMeldedaten = function () {
      $scope.loading = true;
      _http.post(Requests.mandantLoadMeldedatenByMimId($scope.mimId),
        {"includeLinked": $scope.includeLinkedMandanten, "includeDS": $scope.showDS},
        function (json) {
          $scope.daten.rows = json;
          $scope.colorMandanten();
          $scope.loading = false;
        }, "Fehler beim Laden");
    };

    $scope.changeMandant = function (mandantenId) {
      var url = "#mandantendetails?mimid=" + mandantenId;
      window.location.replace(url);
    };

    $scope.openKunde = function (kdnr) {
      window.location.href = "#kundendetails?kdnr=" + kdnr;
    };

    $scope.detail = function (table, id) {
      return "#datendetails?table=" + table + "&id=" + id;
    };

    $scope.$on('pageUpdated', function (event, page) {
      if (page.indexOf("mandantendetails") > -1) {
        $scope.loadMandanten();
      }
    });

    $scope.loadMandanten();

    var mandantenLocked = false;

    $("#contentbox").scroll(function () {
      var killerHeight = 66;
      var scroll = $("#contentbox").scrollTop();
      if (scroll > killerHeight && !mandantenLocked) {
        $("#mandanten").addClass("locked");
        mandantenLocked = true;
      } else if (scroll < killerHeight && mandantenLocked) {
        $("#mandanten").removeClass("locked");
        mandantenLocked = false;
      }
    });
  });
})();
