
/* [src] src/support-app/KundenDetailsController.js */
(function () {
  "use strict";

  angular.module("MeldecenterApp").controller("KundenDetailsController", function ($scope, ToolsService, _http, Requests, ClipboardService, $http) {
    $scope.setTitle("Kundendetails - " + ToolsService.qs('kdnr'));

    if($scope.config.qlmode){
      window.location.replace("/#ckundendetails?kdnr=" + ToolsService.qs('kdnr'));
      return;
    }

    var vm = this;
    vm.kunde = {};
    vm.isms = [];
    vm.ismsConcat = "";

    vm.load = function () {
      _http.get("api/intern/kunden/" + ToolsService.qs('kdnr'), function (data) {
        vm.kunde = data;
      }, "Fehler beim Laden der Kundendetails");

      _http.get("api/intern/ism/kunde/" + ToolsService.qs('kdnr'), function (data) {
        vm.isms = data;
        vm.ismsConcat = "";
        data.forEach(function (ism) {
          if (vm.ismsConcat !== "") {
            vm.ismsConcat += ",";
          }
          vm.ismsConcat += ism.institution + ism.merkmal;
        });
      }, "Fehler beim Laden der ISMs des Kunden");
    };

    $scope.$on('pageUpdated', function (event, page) {
      if (page.indexOf("kundendetails") > -1) {
        vm.load();
      }
    });
    
    // ********************************************************************************
    vm.changeName = function(){
      var newName = prompt("Wie soll der neue Name lauten?", vm.kunde.name);
      if(newName){
        vm.kunde.name = newName;
        save();
      }
    };
    
    vm.changeSperre = function(){
      if(vm.kunde.gesperrt){
        var entsperren = confirm("Soll der Kunde wirklich entsperrt werden?");
        if(entsperren){
          vm.kunde.gesperrt = false;
          vm.kunde.sperrtext = "";
          save();
        }  
      }else{
        var sperren = confirm("Soll der Kunde wirklich gesperrt werden?");
        if(sperren){
          var newText = prompt("Wie soll der optionale Sperrtext lauten?");
          vm.kunde.gesperrt = true;
          vm.kunde.sperrtext = newText;
          save();
        }
      }
    };
    
    vm.changeMeldenAb = function(){
      if(vm.kunde.meldenAb){
        vm.kunde.meldenAb = null;
        save();
      }else{
        var abWann = prompt("Ab wann soll der Kunde senden dürfen (dd.MM.yyyy)?");
        if(abWann){
          vm.kunde.meldenAb = abWann;
          save();
        }
      }
    };
    
    vm.changeMeldenBis = function(){
      if(vm.kunde.meldenBis){
        vm.kunde.meldenBis = null;
        save();
      }else{
        var bisWann = prompt("Bis wann soll der Kunde senden dürfen (dd.MM.yyyy)?");
        if(bisWann){
          vm.kunde.meldenBis = bisWann;
          save();
        }
      }
    };
    
    vm.changePasswort = function(){
      if(vm.kunde.passwort){
        var weiter = confirm("Soll das bestehende Passwort wirklich verändert werden?");
        if(!weiter){
          return;
        }
      }
      
      var neuesPW = prompt("Wie soll das neue Passwort lauten?");
      if(neuesPW){
        vm.kunde.passwort = neuesPW;
        save();
      }
    };
    
    vm.delete = function(){
      if(!confirm("Soll der Kunde " + vm.kunde.kdnr + " wirklich gelöscht werden?")){ return; }
      
      _http.get("api/intern/kunden/" + ToolsService.qs('kdnr') + "/meldungen", function (data) {
        console.log(data);
        if(data.qlds > 0 || data.ids > 0){
          alert("Der Kunde " + vm.kunde.kdnr + " hat " + data.qlds + " eingelieferte Meldungen und " + data.ids + " bereitgestellte Rückmeldungen und kann daher nicht gelöscht werden.");
          return;
        }
        
        if(prompt("Bitte 'löschen' eingeben, um den Kunden " + vm.kunde.kdnr + " wirklich zu löschen!") !== "löschen"){ return; }
        
        _http.delete("api/intern/kunden/" + ToolsService.qs('kdnr') , function (data) {
          window.location.href = "#kundenliste";
          ToolsService.showToast("Kunde gelöscht", "info");
        }, "Fehler beim Löschen des Kunden");
      }, "Fehler beim Laden der Kunden-Meldungen");
    };

    var save = function(){
      $http({                     //  TODO generische Request-Method
        method: "POST",
        url: "api/intern/kunden/" + vm.kunde.kdnr,
        data: vm.kunde,
        headers: {
          'Content-Type': "application/json; charset=UTF-8"
        },
        transformResponse: [function (data) {
          return data;
        }]
      }).then(function (response) {
        vm.load();
        ToolsService.showToast("Änderungen gespeichert", "info");
      }, function (response) {
        ToolsService.showToast("Fehler beim Speichern der Änderungen", "error");
      });
    };

    // ********************************************************************************
    vm.load();
  });
})();