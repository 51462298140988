
/* [src] src/support-app/KrankenkassenUebersichtController.js */
(function () {
  "use strict";

  angular.module("MeldecenterApp").controller("KrankenkassenUebersichtController", function (_http, $scope, $timeout, Requests, _) {
    $scope.setTitle("Krankenkassenübersicht");

    var vm = this;
    vm.krankenkassenDaten = []; // die volle Liste
    vm.filteredKrankenkassendaten = []; // gefiltert nach den Suchparametern
    vm.scrollTo = scrollTo;
    vm.selectedBbnr = null;
    vm.search = {"bbnr": null, "name": null, "date": null};
    var sortColumn = "bbnr";
    var sortAscending = true;

    vm.getFilteredKrankenkassendaten = getFilteredKrankenkassendaten;
    vm.getLastZeitraum = getLastZeitraum;
    vm.isSelected = isSelected;
    vm.select = select;
    vm.toggleDetails = toggleDetails;
    vm.getSortMarker = getSortMarker;
    vm.changeSorting = changeSorting;

    _http.get(Requests.krankenkassenUebersicht, function (data) {
      vm.krankenkassenDaten = _buildKrankenkassenDaten(data.krankenkassendaten);
    }, "Abruf der Krankenkassendaten fehlgeschlagen");

    function getSortMarker(field) {
      if (field === sortColumn) {
        return sortAscending ? "▲" : "▼";
      }
      return "  ";
    }

    function changeSorting(field) {
      if (field === sortColumn) {
        sortAscending = !sortAscending;
      } else {
        sortColumn = field;
        sortAscending = true;
      }
    }

    function toggleDetails(krankenkassendatum) {
      krankenkassendatum.details = !krankenkassendatum.details;
    }

    function getLastZeitraum(krankenkassenDatum) {
      return krankenkassenDatum.zeitraeume[krankenkassenDatum.zeitraeume.length - 1];
    }

    function toDate(dateVal) {
      if ("" + dateVal === dateVal) {
        if (dateVal.indexOf(".") > 0) {
          var indexOfFirstDot = dateVal.indexOf(".");
          var indexOfSecondDot = dateVal.indexOf(".", indexOfFirstDot + 1);
          var dayPart = dateVal.substring(0, indexOfFirstDot);
          var monthPart = dateVal.substring(indexOfFirstDot + 1, indexOfSecondDot);
          var yearPart = dateVal.substring(indexOfSecondDot + 1);
          var xday = parseInt(dayPart);
          var xmonth = parseInt(monthPart);
          var xyear = parseInt(yearPart);
          return new Date(xyear, xmonth - 1, xday);
        }
        return new Date(dateVal);
      }
      var year = dateVal.length > 0 ? dateVal[0] : 0;
      var month = (dateVal.length > 1 ? dateVal[1] : 1) - 1;
      var day = (dateVal.length > 2 ? dateVal[2] : 1);
      return new Date(year, month, day);
    }

    function getFilteredKrankenkassendaten(searchCriteria) {
      var date = searchCriteria.date && isFinite(toDate(searchCriteria.date)) ? toDate(searchCriteria.date) : null;
      vm.filteredKrankenkassendaten = _.filter(vm.krankenkassenDaten, function (val) {
        if (searchCriteria.bbnr && !val.bbnr.startsWith(searchCriteria.bbnr)) {
          return false;
        }
        if (date && val.letzte_aenderung.getTime() < date.getTime()) {
          return false;
        }
        return !(searchCriteria.name && !val.name.toLowerCase().includes(searchCriteria.name.toLowerCase()));
      });
      return _.orderBy(vm.filteredKrankenkassendaten, [sortColumn], [sortAscending ? "asc" : "desc"]);
    }

    function scrollTo(bbnr) {
      if (!_.find(vm.filteredKrankenkassendaten, {bbnr: bbnr})) {
        if (confirm("Die gesuchte Krankenkasse ist mit dem aktuellen Filter nicht sichtbar. Der Filter wird entfernt.")) {
          vm.search = {"bbnr": null, "name": null, "date": null};
          $timeout(function () {
            scrollTo(bbnr);
          }, 1000);
          return;
        }
      }
      var element = $("#" + bbnr);
      select(bbnr);
      $("#contentbox").scrollTo(element, 500, {offset: -($("#contentbox").height() / 2)});
      $timeout(function () {
        select(bbnr);
      }, 50);
    }

    function isSelected(bbnr) {
      return vm.selectedBbnr === bbnr;
    }

    function select(bbnr) {
      vm.selectedBbnr = bbnr;
    }

    function _buildKrankenkassenDaten(krankenkassendaten) {
      var result = {};
      for (var idx = 0; idx < krankenkassendaten.length; ++idx) {
        var krankenkassendatum = krankenkassendaten[idx];
        var resultElement = result[krankenkassendatum.bbnr] || (result[krankenkassendatum.bbnr] = {});
        var letzte_aenderung = toDate(krankenkassendatum.letzte_aenderung);
        resultElement.letzte_aenderung = resultElement.letzte_aenderung || letzte_aenderung;
        if (letzte_aenderung.getTime() > resultElement.letzte_aenderung.getTime()) {
          resultElement.letzte_aenderung = letzte_aenderung;
        }
        resultElement.bbnr = krankenkassendatum.bbnr;
        resultElement.name = krankenkassendatum.name.kurzname;
        var zeitraeume = resultElement.zeitraeume || (resultElement.zeitraeume = []);
        var zeitraum = {
          gueltig_ab: (krankenkassendatum.gueltig_ab && krankenkassendatum.gueltig_ab[0] > 0) ? toDate(krankenkassendatum.gueltig_ab) : "<offen>",
          gueltig_bis: (krankenkassendatum.gueltig_bis && krankenkassendatum.gueltig_bis[0] > 0) ? toDate(krankenkassendatum.gueltig_bis) : "<offen>",
          alle_daten: krankenkassendatum,
          letzte_aenderung: letzte_aenderung
        };
        zeitraeume.push(zeitraum);
        resultElement.nachfolge_bbnr = krankenkassendatum.nachfolge_bbnr;
      }
      _.forEach(result, function (v) {
        v.gueltig_bis = (getLastZeitraum(v).gueltig_bis === "<offen>") ? new Date(9999, 11, 31) : getLastZeitraum(v).gueltig_bis;
      });
      return result;
    }

    return vm;
  });
})();
