
/* [src] src/support-app/EELController.js */
(function () {
  "use strict";

  angular.module("MeldecenterApp").controller("EELController", function ($scope, _http, Requests) {
    $scope.setTitle("EEL Nutzung");

    var vm = this;
    vm.loading = true;

    vm.data = [];
    vm.toggle = [];

    var load = function(){
      _http.get(
          "api/intern/service/eel/usage-statistics", function(data){
            console.log(data);
            vm.loading = false;
            vm.data = data;
          }
        );
    };

    load();

    return vm;
  });
})();