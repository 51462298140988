
/* [src] src/support-app/CKundenSimilarityController.js */
(function () {
  "use strict";

  angular.module("MeldecenterApp").controller("CKundenSimilarityController", function ($scope, ToolsService, _http, Requests, ClipboardService, $http) {
    $scope.setTitle("Kundenvergleich");

    var vm = this;
    
    // *****************************************************************************************************************
    // ***** SUCHE ÄHNLICHE *****
    vm.aehnlicheKundenSucheKdnr = ToolsService.qs("kdnr");
    vm.aehnliche = [];
    vm.aehnlicheShowMarkiert = false;
    vm.sucheAktiv = false; 
    vm.versteckte = 0;
    
    vm.sucheAehnliche = function(kdnr){
      _http.get("api/intern/ckundensimilarity/suche_aehnliche/" + (kdnr ? kdnr : "alle"), function(data){ 
        vm.aehnliche = data;
        vm.sucheAktiv = false;
        
        var geladen = false; 
        vm.versteckte = 0;
        vm.aehnliche.forEach(function(a){
          if(a.markiertAlsUngleich){
            vm.versteckte++;
          }
          if(!geladen && !a.markiertAlsUngleich ){
            geladen = true;
            vm.ladeInVergleich(a);
          }
        });
      });
      vm.sucheAktiv = true;
    };
    
    vm.ladeInVergleich = function(a){
      vm.searchdata.kdnr1 = a.kdnr1;
      vm.searchdata.kdnr2 = a.kdnr2;
      vm.load(1); vm.load(2);
      document.getElementById("contentbox").scrollTop = 5000;
    };
    
    if(vm.aehnlicheKundenSucheKdnr){ vm.sucheAehnliche(vm.aehnlicheKundenSucheKdnr); }
    
    // *****************************************************************************************************************
    // ***** VERGLEICH *****
    
    vm.searchdata = { kdnr1: "", kdnr2: "" };
    vm.data = { kd1: {}, kd2: {} };
    vm.compare = { total: 80, name: 80, adress: 80, ap: 80, kontakt: 80 };
    
    $scope.$watch(function() { return JSON.stringify(vm.data); }, function() {
      compare();
    });
    
    vm.load = function(nr){
      var kdnr = nr === 1 ? vm.searchdata.kdnr1 : vm.searchdata.kdnr2;
      if(!kdnr){ return; } 
      _http.get("api/intern/ckundensimilarity/" + kdnr, function(data){
        if(nr === 1){ vm.data.kd1 = data; vm.searchdata.kdnr1 = "";}
        else { vm.data.kd2 = data;vm.searchdata.kdnr2 = "";}
        compare();
      });
      
    };
    
    var compare = function(){
      _http.post("api/intern/ckundensimilarity/compare",{
        kd1 : JSON.stringify(vm.data.kd1),
        kd2 : JSON.stringify(vm.data.kd2)
      }, function(data){ vm.compare = data; });
    };
    
    vm.toggleUngleich = function(){
      if(!vm.compare){ return; }
      
      _http.post("api/intern/ckundensimilarity/markiereUngleich",{
        kdnr1 : vm.data.kd1.kdnr,
        kdnr2 : vm.data.kd2.kdnr,
        ungleich: !vm.compare.markiertAlsUngleich
      }, function(data){ compare(); });
    };
    
    // *****************************************************************************************************************
    // ***** MERGE *****
    
    vm.mdata = { geladen: false, kd1: {}, kd2: {}, neu: {} };
    
    vm.merge = function(){
      if(!vm.data.kd1.kdnr || !vm.data.kd2.kdnr){ return; }
      vm.mdata.kd1 = {};
      vm.mdata.kd2 = {};
      vm.mdata.neu = {};
      
      _http.get("api/intern/crm/" + vm.data.kd1.kdnr + "/details", function(data){ vm.mdata.kd1 = data; createNew(); });
      _http.get("api/intern/crm/" + vm.data.kd2.kdnr + "/details", function(data){ vm.mdata.kd2 = data; createNew(); });
      vm.mdata.geladen = true;
    };
    
    var createNew = function(){
      if(!vm.mdata.kd1.kundennummer || !vm.mdata.kd2.kundennummer){ return; }
      
      vm.mdata.neu.kundennummer                   = vm.mdata.kd1.kundennummer; 
      vm.mdata.neu.name                           = vm.mdata.kd1.name; 
      vm.mdata.neu.name2                          = vm.mdata.kd1.name2;
      vm.mdata.neu.status                         = {};                   
      vm.mdata.neu.status.info                    = vm.mdata.kd1.status.info;
      vm.mdata.neu.status.passwort                = vm.mdata.kd1.status.passwort;
      vm.mdata.neu.anschrift                      = {};
      vm.mdata.neu.anschrift.strasseUndHausnummer = vm.mdata.kd1.anschrift.strasseUndHausnummer;
      vm.mdata.neu.anschrift.zusatz               = vm.mdata.kd1.anschrift.zusatz;
      vm.mdata.neu.anschrift.plz                  = vm.mdata.kd1.anschrift.plz;
      vm.mdata.neu.anschrift.ort                  = vm.mdata.kd1.anschrift.ort;
      
      document.getElementById("contentbox").scrollTop = 5000;
    };
    
    vm.startMerge = function(){
      var keep = vm.mdata.neu.kundennummer;
      var remove = (vm.mdata.kd1.kundennummer === keep ? vm.mdata.kd2.kundennummer : vm.mdata.kd1.kundennummer);
      
      var yes = confirm("Sollen die Kunden " + keep + " und " + remove + " wirklich zusammengeführt werden?\n\n" + 
        "Der Kunde " + remove + " wird hierbei gelöscht!");
      
      if(!yes){ return; }
      
      $http({                                                                                                           //  TODO generische Request-Method
        method: "POST",
        url: "api/intern/ckundensimilarity/merge",
        data: {
          "remove" : remove,
          "neu" : vm.mdata.neu 
        },
        headers: { 'Content-Type': "application/json; charset=UTF-8"  },
        transformResponse: [function (data) { return data; }]
      }).then(function (response) {
      
        var win = window.open("#ckundendetails?kdnr="+vm.mdata.neu.kundennummer, '_blank');
        win.focus();
        
        vm.mdata.geladen = false;
        vm.searchdata = { kdnr1: "", kdnr2: "" };
        vm.data = { kd1: {}, kd2: {} };
      }, function (response) {
        ToolsService.showToast("Kunden konnten nicht zusammengeführt werden", "error");
      });
    };
  });
})();
