
/* [src] src/support-app/ElstamFehlercodesController.js */
(function () {
  "use strict";

  angular.module("MeldecenterApp").controller("ElstamFehlercodesController", function ($scope, _http, Requests) {
    $scope.setTitle("Elstam Fehlercodes");
    
    $scope.monate = [];
    $scope.codes = [];
    $scope.delta = [];
    $scope.loading = true;
    $scope.codeRequestIndex = 0;
    $scope.showDesc = true;
    $scope.showHint = false;
    $scope.codeDetails = [];

    // ********************************************************************************* @formatter:off
    $scope.codeDetails = function (code) { // jshint ignore:line
      switch (code) {
        case '551000009': return { t: 'B', d: "Bitte verwenden Sie im Verfahren ElsterLohn II die im Attribut Steuernummer enthaltene Steuernummer"          };
        case '551001001': return { t: 'C', d: "Der Arbeitnehmer mit der ID ist mehrfach angegeben / Datenteil ist leer / Die Nutzdaten sind nicht zu parsen / Das RefDat-Datum darf nicht vor dem Beschäftigungsbeginn liegen / Technischer Fehler beim Bereitstellen der Daten / o.a."          };
        case '551005005': return { t: 'B', d: "Der Arbeitgeber zur Steuernummer [9255027020274] kann nicht bestätigt werden."};        
        case '551005007': return { t: 'C', d: "Der Arbeitgeber ist als nicht lebend gemeldet: [3238011501654]"};
        case '551005008': return { t: 'B', d: "Die Steuernummer [9255027020274] ist nicht als Arbeitgeber gemeldet."};
        case '552010000': return { t: 'A', d: "Keine besonderen Hinweise zu den in der Liste enthaltenen Arbeitnehmern"};
        case '552010001': return { t: 'A', d: "Für die bei Ihnen beschäftigten Arbeitnehmer haben sich keine Änderungen ergeben."};
        case '552010002': return { t: 'A', d: "Besondere Hinweise zu mindestens einem Arbeitnehmer"};
        case '552010102': return { t: 'C', d: "Das in der An-/Ummeldung für den AG mitgeteilte Bundesland weicht von dem im Datenpool ELStAM gespeicherten Bundesland ab."          };
        case '552010103': return { t: 'B', d: "refDatumAG liegt nicht zwischen dem 01. des Monats des Eingangs und dem 01. des Folgemonats nach Eingang."          };
        case '552020000': return { t: 'A', d: "Keine besonderen Hinweise"};
        case '552020001': return { t: 'A', d: "Abmeldung erfolgreich"};
        case '552020101': return { t: 'B', d: "Keine Abrufberechtigung, da der Abruf der ELStAM für den Arbeitgeber bereits anderweitig erfolgt ist."          };
        case '552020102': return { t: 'B', d: "Keine Abrufberechtigung mehr ab Datum [Datum = 20151001]"};
        case '552020103': return { t: 'A', d: "Wechsel des Hauptarbeitgebers. Neues refDatumAG für das aktuelle Arbeitsverhältnis. [refDatumAG = 20151006]"          };
        case '552020104': return { t: 'C', d: "Das Arbeitsverhältnis wurde durch einen Bundeslandwechsel des Arbeitgebers angelegt. Referenzdatum des Arbeitgebers ist der Termin des Bundeslandwechsels.[refDatumAG = 20241007]"          };
        case '552020200': return { t: 'B', d: "Keine Anmeldeberechtigung"};
        case '552020202': return { t: 'A', d: "Arbeitnehmer unbekannt: Die IdNr des Arbeitnehmers kann nicht verifiziert werden."};
        case '552020203': return { t: 'A', d: "Erneute Anmeldung nicht möglich - Arbeitnehmer ist bereits angemeldet."};
        case '552020204': return { t: 'C', d: "refDatumAG liegt vor Verfahrensstart."};
        case '552020205': return { t: 'A', d: "refDatumAG liegt vor Beginn der Meldepflicht."};
        case '552020206': return { t: 'B', d: "refDatumAG liegt vor Jahresbeginn (bei Eingangsdatum ab 01.03. des Jahres)."};
        case '552020209': return { t: 'C', d: "Für ein refDatumAG nach dem Eingangsdatum der Anmeldung in der Clearingstelle ist nur der Tag des Verfahrensstarts erlaubt."          };
        case '552020213': return { t: 'A', d: "Das refDatumAG der Anmeldung muss nach dem Ende der Beschäftigung aus der letzten Abmeldung liegen. [Beschäftigungsende = 20140331]"          };
        case '552020214': return { t: 'A', d: "Anmeldung nach Kulanzfrist, neues refDatumAG der aktuellen Beschäftigung. [refDatumAG = 20140904]"          };
        case '552020215': return { t: 'A', d: "Zu dem refDatumAG der Anmeldung liegt bereits ein Hauptarbeitsverhältnis vor."};
        case '552020300': return { t: 'A', d: "Ab-/Ummeldung des Arbeitnehmers (IdNr und Geburtsdatum) ist nicht möglich, weil kein Arbeitsverhältnis besteht."          };
        case '552020303': return { t: 'B', d: "refDatumAG aus der Abmeldung stimmt nicht mit refDatumAG aus der An-/Ummeldung überein. [refDatumAG = 20150911]"          };
        case '552020304': return { t: 'B', d: "Abmeldung nicht möglich, da Abmeldedatum kleiner als refDatumAG aus der An-/Ummeldung[refDatumAG = 20150501]"          };
        case '552020305': return { t: 'C', d: "Abmeldung nicht möglich, da Abmeldedatum in der Zukunft"};
        case '552020306': return { t: 'A', d: "Beschäftigungsende des Arbeitsverhältnisses geändert [Beschäftigungsende alt = 20140902][Beschäftigungsende neu = 20140831]"          };
        case '552020307': return { t: 'A', d: "Wechsel des Hauptarbeitgebers. Abmeldung zum übermittelten Beschäftigungsende nur mit aktualisiertem refDatumAG des Arbeitsverhältnisses möglich.[refDatumAG = 20151015]"          };
        case '552020400': return { t: 'B', d: "Keine Ummeldeberechtigung"};        
        case '552020402': return { t: 'A', d: "refDatumAG liegt nicht nach dem gespeicherten refDatumAG des umzumeldenden Arbeitsverhältnisses.[refDatumAG = 20140301]"          };
        case '552020900': return { t: 'C', d: "Verarbeitungsabbruch - bitte kontaktieren Sie Ihr zuständiges Betriebsstätten-Finanzamt."          };
        default:          return { t: 'C', d: "DIESER CODE IST UNBEKANNT", h: "DIESER CODE IST UNBEKANNT"};
      }
    };

    // ********************************************************************************* @formatter:on

    $scope.ladeMonate = function () {
      _http.get(Requests.elstamFehlercodesLadeMonate, function (data) {
        $scope.monate = data;
        $scope.codeRequestIndex = 0;
        $scope.loadCodes();
      }, "Fehler beim Laden");
    };

    $scope.loadCodes = function () {
      if ($scope.codeRequestIndex < $scope.monate.length) {
        _http.get(
          Requests.elstamFehlercodesLadeCodes + "/" + $scope.monate[$scope.codeRequestIndex].monat.replace("/", "-"),
          function (data)  {
            if(data.codes === undefined) {
              setTimeout(() => {  $scope.loadCodes(); }, 500);
            } else {
              $scope.applyMonth(data);
              $scope.codeRequestIndex++;
              $scope.loadCodes();
            }
          },
          "Fehler beim Laden"
        );
      } else {
        $scope.calculate();
      }
    };

    $scope.applyMonth = function (data) {
      // add scopes to main list if not exist
      for (var code in data.codes) {
        if (!data.codes.hasOwnProperty(code)) {
          continue;
        }
        if ($scope.codes.indexOf(code) === -1) {
          $scope.codes.push(code);
        }
      }

      $scope.monate.forEach(function (m) {
        if (m.monat === data.monat) {
          m.codes = data.codes;
        }
      });
      $scope.codes.sort();
    };

    $scope.calculate = function () {
      $scope.codes.forEach(function (code) {
        var prev = -999;

        $scope.monate.slice().reverse().forEach(function (monat) {
          if (monat.codes[code] === null || monat.codes[code] === undefined) {
            monat.codes[code] = 0;
          }
          if (monat.delta === undefined) {
            monat.delta = [];
          }
          monat.delta[code] = 0;
          if (prev !== -999) {
            monat.delta[code] = monat.codes[code] - prev;
          }
          prev = monat.codes[code];
        });
      });
      $scope.loading = false;
    };


    $scope.ladeMonate();
  });
})();