
/* [src] src/support-app/MockSystemController.js */
(function () {
  "use strict";

  angular.module("MeldecenterApp").controller("MockSystemController", function ($scope, $timeout, ToolsService, _http, Requests) {
    $scope.setTitle("Mock System");

    var vm = this;
    
    vm.data = {};
    
    vm.loadInfo = function(){
      _http.get("api/intern/mock/info", function (data) {
        vm.data = data;
      });
    };
    
    
    // *****************************************************************************************************************
    // ***** Logs
    vm.logs = "";
    
    vm.loadLogs = function(){
      _http.getPlain("api/intern/mock/logs", function (data) {
        vm.logs = data;
      });
    };
    
    
    vm.resetLogs = function(){
      _http.post("api/intern/mock/logs/reset");
      vm.logs = "";
    };
    
    // *****************************************************************************************************************
    // ***** Rückmeldungen hochladen
    vm.inject = {
      format: "isf",
      type: "ELSTAM_AENDERUNGSLISTE",
      content: ""
    };
    vm.injectUrl = "XX";
    vm.injectResult = {};
    
    $scope.$watch(function(){ return "" + vm.inject.format + vm.inject.type;}, function(){ 
      vm.injectUrl = "POST {SERVER}:{PORT}/api/intern/mock/inject/" + vm.inject.format + "/" + vm.inject.type; 
    });
    
    
    vm.rueckmeldungSpeichern = function(){
      _http.post("api/intern/mock/inject/" + vm.inject.format + "/" + vm.inject.type, 
      {content: vm.inject.content},
       function(res){
        vm.injectResult = res;
      });
    };
    
    vm.qldsFinalisieren = function(){
      _http.post("api/intern/mock/finalisiere-qlds/" + vm.qlds_fin_id, 
        {content: vm.inject.content},
        function(value){
          if(value.msg === "OK"){
            ToolsService.showToast("erfolgreich aktualisiert", "info");
          }else{
            ToolsService.showToast("Fehler: " + value.msg, "error");
          }
        }
      );
    };
    
    // *****************************************************************************************************************
    // ***** Kunden anlegen
    vm.neuerKunde = {
      kdnr: "",
      name: "",
      passwort: ""
    };
    
    vm.legeKundenAn = function(){
      _http.post("api/intern/mock/kunde", {
        kdnr: vm.neuerKunde.kdnr,
        name: vm.neuerKunde.name,
        passwort: vm.neuerKunde.passwort
      },function (value) {
          ToolsService.showToast("erfolgreich gespeichert", "info");
      }, function(e){
          ToolsService.showToast("Fehler beim Speichern: " +e.fehler, "error");
      });
    };
    
    
    vm.loadInfo();
    vm.loadLogs();
    
    return vm;
  });
})();