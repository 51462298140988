
/* [src] src/reporting-app/DrillDownViewController.js */
(function() {
  "use strict";

  angular.module("ReportingApp").controller("DrillDownViewController",

    function (drillDown, $uibModalInstance) {

      var vm = this;
      vm.drillDown = drillDown;
      vm.close = function() {
        $uibModalInstance.close();
      };
    });
})();
