
/* [src] src/support-app/MainController.js */
(function () {
  "use strict";

  angular.module("MeldecenterApp").controller("MainController", function ($scope, Requests, $window, _http, ToolsService, ConfigService, $document) {
    $scope.contentHtml = "";
    $scope.contentTitle = "";
    $scope.contentError = false;
    $scope.targetAfterLogin = null;
    $scope.loginFailure = "";

    ConfigService.applyConfig($scope);

    /** hierdurch wird verhindert, dass angular ungewollt durch die Gegend scollt */
    $scope.scroll = function (a, b, c) {
    };

    $scope.menu = // @formatter:off
      [{
        groupName: "Kundenbetreuung", role: "BETREUER",  qlOnly: true,
        entries: [
          {label: "Kunden-Management"          , page: "ckundenliste"                      , role: "BETREUER"                          },
          {label: "Kunden-Details"             , page: "ckundendetails"                    , role: "-"                                 },
          {label: "Kunden-Vergleich"           , page: "ckundensimilarity"                 , role: "-"                                 },
          {label: "Tickets"                    , page: "tickets"                           , role: "BETREUER"                          }
        ]
      }, {
        groupName: "Suche", role: "BETREUER",
        entries: [
          {label: "Überblick"                  , page: "ueberblick"                        , role: "BETREUER"                          },
          {label: "Mandanten-Suche"            , page: "mandantenliste"                    , role: "BETREUER"   , qlOnly: true         },
          {label: "Mandanten-Details"          , page: "mandantendetails"                  , role: "-"                                 },
          {label: "Kunden-Suche"               , page: "kundenliste"                       , role: "BETREUER"   , qlNot: true          },
          {label: "Kunden-Details"             , page: "kundendetails"                     , role: "-"                                 },
          {label: "Kunden-CSV-Import"          , page: "kundencsvimport"                   , role: "-"                                 },
          {label: "Firmen-Suche"               , page: "firmen"                            , role: "BETREUER"                          },
          {label: "Institutions-Merkmal-Suche" , page: "ism"                               , role: "BETREUER"                          },
          {label: "Daten-Suche"                , page: "datenliste"                        , role: "BETREUER"                          },
          {label: "Daten-Details"              , page: "datendetails"                      , role: "-"                                 },
        ]
      }, {
        groupName: "Rechnung", role: "BETREUER", qlOnly: true,
        entries: [
          {label: "Rechnungsläufe"             , page: "rechnungslaeufe"                   , role: "-"                                 },
          {label: "Rechnungen"                 , page: "rechnungen"                        , role: "RECHNUNG"                          },
          {label: "Rechnungen"                 , page: "rechnung"                          , role: "-"                                 },
          {label: "Händlerrechungen"           , page: "haendlerrechnung"                  , role: "-"                                 },
          {label: "Kontoumsätze"               , page: "konto"                             , role: "RECHNUNG"                          },
          {label: "FiBu"                       , page: "fibu"                              , role: "RECHNUNG"                          },
          {label: "Salden / OP"                , page: "salden"                            , role: "BETREUER"   , qlOnly: true         }
        ]
      }, {
        groupName: "Betreuung", role: "BETREUER", hideEau: true,
        entries: [
          {label: "Krankenkassenübersicht"     , page: "kk_uebersicht"                     , role: "BETREUER"                          },
        /*{label: "Elstam Fehlende Antworten"  , page: "fehlendeelstams"                   , role: "ADMIN"                             },*/
          {label: "Elstam Abmeldungsservice"   , page: "abmeldungsservice"                 , role: "ADMIN"                             },
          {label: "Elstam Rückmeldung"         , page: "elstam_rueckmeldung"               , role: "-"                                 },
          {label: "Elstam Abholungs-Service"   , page: "elstam_abholung"                   , role: "-"                                 },
          {label: "Elstam nicht zugeordnet"    , page: "nicht_zugeordnete_aenderungslisten", role: "ADMIN"      , qlOnly: true         },
          {label: "Textnachricht erzeugen"     , page: "textnachricht"                     , role: "ADMIN"      , qlOnly: true         },
          {label: "QL-Beitragssatzdatei"       , page: "qlbeitragssatzdatei"               , role: "BETREUER"   , qlOnly: true         },
          {label: "QLC Versionen"              , page: "qlcversionen"                      , role: "BETREUER"   , qlOnly: true         },
          {label: "eVpT Rückmeldungen"         , page: "evpt_rueckmeldung"                 , role: "BETREUER"   , evpt: true           }
        ]
      }, {
        groupName: "Administration", role: "ADMIN",
        entries: [
          {label: "Verarbeitung"               , page: "verarbeitung"                      , role: "ADMIN"                             },
          {label: "System-Events"              , page: "system_events"                     , role: "ADMIN"                             },
          {label: "Test-System"                , page: "mock_system"                       , role: "BETREUER"   , testSystemOnly: true },
          {label: "SV-Meldungs-Parser"         , page: "svparser"                          , role: "ADMIN"                             },
          {label: "Systemkonfiguration"        , page: "konfiguration"                     , role: "ADMIN"                             },
          {label: "Zertifikate"                , page: "zertifikate"                       , role: "ADMIN"                             },
          {label: "Activity-Log"               , page: "activitylog.html"                  , role: "ACTIVITYLOG"                       }
        ]
      }, {
        groupName: "Reporting", role: "ADMIN", hideEau: true,
        entries: [
          {label: "LND Lohnnachweis"           , page: "lohnnachweis"                      , role: "BETREUER"                          },
          {label: "SV-Bestandsmeldungen"       , page: "bestandsmeldungen"                 , role: "BETREUER"                          },
          {label: "AAG"                        , page: "aag"                               , role: "BETREUER"                          },
          {label: "Elstam Fehlercodes"         , page: "elstam_fehlercodes"                , role: "BETREUER"                          },
          {label: "EEL"                        , page: "eel"                               , role: "BETREUER"   , qlOnly: true         },
          {label: "Beta-Reports"               , page: "beta_reports"                      , role: "REPORTING"                         },
          {label: "Reporting"                  , page: "reporting.html"                    , role: "REPORTING"                         }
        ]
      }, {
        groupName: "Quick-Lohn", role: "BETREUER", qlOnly: true,
        entries: [
          {label: "Online-Datensicherung"      , page: "ods"                               , role: "BETREUER"                          }
        ]
      }
      ];// @formatter:on

    window.onhashchange = function () {
      console.log("hash changed");
      $scope.loadConfig();
    };

    $scope.loadConfig = function () {
      console.log("loading config...");
      _http.get(Requests.config, function (config) {
        $scope.config = config;
        console.log("config loaded, user:" + config.user);
        $scope.loadQLSpecific();
        $scope.loadMainPage();
      }, "Fehler beim Laden der Konfiguration");


      var loginerror = ToolsService.qs("loginerror");
      if (loginerror) {
        $scope.loginFailure = true;
        window.history.replaceState({}, document.title, "/"); // removes query param
      }
    };

    $scope.loadMainPage = function () {
      console.log("loading main page");
      if (!$scope.config.user) {
        console.log(" no user loading login.html (target: " + location.hash + ")");
        $scope.targetAfterLogin = location.hash;
        $scope.contentTitle = "Login";
        $scope.contentHtml = "login.html";
      } else if ($scope.targetAfterLogin) {
        console.log("targetAfterLogin exists, redirecting: " + $scope.targetAfterLogin);
        location.replace($scope.targetAfterLogin);
        $scope.targetAfterLogin = null;
        $scope.loadMainPage();
        return;
      } else {
        var hash = window.location.hash.substr(1);
        console.log("loading content: " + hash);
        var indexOfQuestionMark = hash.indexOf("?");
        var target = indexOfQuestionMark === -1 ? hash : hash.substring(0, indexOfQuestionMark);
        if (target.length === 0) {
          console.log("no path -> redirect to ueberblick");
          window.location.replace("#ueberblick");
          return;
        }
        if (target === "ismdetails") {
          console.log("alte ISM-Details-Url, forwarde zu neuer");
          window.location.replace("#" + hash.replace("ismdetails", "ism"));
        }
        $scope.contentHtml = "./" + target + ".html";
        $scope.contentTitle = "Seite konnte nicht gefunden werden";
        $scope.contentError = true;
        $scope.menu.forEach(function (group) {
          group.entries.forEach(function (entry) {
            entry.clazz = "";
            if (entry.page === target) {
              entry.clazz = "selected";
              $scope.contentTitle = entry[0];
              $scope.contentError = false;
            }
          });
        });
        if ($scope.contentError) {
          $scope.contentHtml = "404.html";
        }
      }

      $scope.$broadcast('pageUpdated', $scope.contentHtml);
      window.scrollTo(0, 0);
    };

    $scope.login = function () {
      console.log("login..");
      _http.post("login", {
        username: $("#username").val(),
        password: $("#password").val()
      }, function (success) {
        console.log("login done");
        $scope.loadConfig();
      }, function (failure) {
        console.log("login fehlgeschlagen");
        $scope.loginFailure = true;
      });
    };

    $scope.isGoogleEnabled = function () {
      return $scope.config.googleEnabled;
    };

    $scope.isUsernamePasswordEnabled = function () {
      return $scope.config.usernamePasswordEnabled;
    };

    $scope.logout = function () {
      console.log("logout...");
      $scope.config.user = null;
      location.href = "logout";
    };

    $scope.userHasRole = function (role) {
      return $scope.config.user && $scope.config.user.roles.indexOf(role) > -1;
    };

    $scope.isTestSystem = function () {
      return !!$scope.config && $scope.config.testSystem;
    };

    $scope.isEvptSystem = function () {
      return !!$scope.config && $scope.config.evptmode;
    };

    $scope.timeDelta = function () {
      return $scope.config ? $scope.config.timedelta : null;
    };

    $scope.loadConfig();

    $scope.setTitle = function (title) {
      $scope.contentTitle = title;
      window.document.title = "Meldecenter | " + title;
    };

    // @formatter:off
    $scope.longName = function (table) {
      switch (table){
        case "QLDS": return $scope.config.eaumode ? "Meldung" : $scope.config.meldeDatensatz ? "Melde-Datensatz" : "Lohn-Datensatz";
        case "QLVP": return $scope.config.eaumode ? "Meldungs-VP" :$scope.config.meldeDatensatz ? "Melde-Versandpaket" : "Lohn-Versandpaket";
        case "IVP": return $scope.config.eaumode ? "Rückmeldungs-VP" : "Institutions-Versandpaket";
        case "IDS": return $scope.config.eaumode ? "Rückmeldung" :"Institutions-Datensatz";
        case "Meldedatei": return "MD";
        case "Rueckmeldedatei": return "RMD";
        default: return table;
      }
    };

    $scope.shortName = function (table) {
      switch (table){
        case "QLDS": return $scope.config.meldeDatensatz ? "MDS" : "LDS";
        case "QLVP": return $scope.config.meldeDatensatz ? "MVP" : "LVP";
        case "IVP": return "IVP";
        case "IDS": return "IDS";
        default: return table;
      }
    };
    // @formatter:on

    $scope.eur = function (float) {
      if (!float) {
        return "0,00";
      }
      var str = float.toString().replace(".", ",");

      if (str.indexOf(",") === -1) {
        return str + ",00";
      } else if (str.match(/^.*\,\d$/gm)) {
        return str + "0";
      } else {
        return str;
      }
    };

    // ******************************************************************************************************************************
    // ******************************************************************************************************************************
    // ******************************************************************************************************************************
    // ***** QL-specific stuff *****
    $scope.globalKeyListener = [];    // <-- no direct access from controller
    // kl = { pagename: "ckundendetails", handler: function(e){ .. }}
    $scope.addGlobalKeyListener = function (kl) {
      // ***** remove kl if it already exists 
      var indexOfExistingKL = -1;
      $scope.globalKeyListener.forEach(function (existingKL, i) {
        if (existingKL.pagename === kl.pagename) {
          indexOfExistingKL = i;
        }
      });
      if (indexOfExistingKL > -1) {
        console.log("removing existing");
        $scope.globalKeyListener.splice(indexOfExistingKL, 1);
      }

      // ***** register KL
      $scope.globalKeyListener.push(kl);
    };

    $scope.loadQLSpecific = function () {
      if (!$scope.config.qlmode) {
        return;
      }

      if ($scope.qlinitDone) {
        return;
      } // prevent double initialization
      $scope.qlinitDone = true;
      
      // load service users into scope ...
      _http.get("api/intern/crm/service-users", function (res) { $scope.qlServiceUsers = res; });
      

      $scope.tabID = sessionStorage.tabID ? sessionStorage.tabID : sessionStorage.tabID = ('_' + Math.random().toString(36).substr(2, 9));
      $scope.mainTelTab = false;
      if (ToolsService.qs("newtab")) {
        window.location.href = window.location.href.replace(/\??newtab=true/, "");
        $scope.tabID = sessionStorage.tabID = ('_' + Math.random().toString(36).substr(2, 9));
      }

      if(!$scope.config.eauservermode){
        setInterval(function () {
          if (!$scope.config.user) {
            return;
          }                                // erst nach dem einloggen
          _http.get("api/intern/telefon/main-tel-tab?tabid=" + $scope.tabID + "&focus=" + document.hasFocus(), function (res) {
            $scope.mainTelTab = res.isMain;
          }, function () {
            $scope.mainTelTab = false;
          });
        }, 950);
      }


      // ************************
      // ***** global keys
      $document.bind('keyup', function (e) {
        if (e.key === "F1") {
          window.location.href = "#ueberblick";
          e.stopPropagation();
          event.preventDefault();
        }
        if (e.key === "F2") {
          window.location.href = "#ckundenliste";
          e.stopPropagation();
          event.preventDefault();
        }
        if (e.key === "F3") {
          window.location.href = "#tickets";
          e.stopPropagation();
          event.preventDefault();
        }
        if (e.key === "F8") {
          if ($scope.telefon && $scope.telefon.show) {
            $scope.telefon.erfassen = !$scope.telefon.erfassen;
            $scope.$apply();
            if ($scope.telefon.erfassen) {
              setTimeout(function () {
                document.getElementById("tel-erfassen").focus();
              }, 0);
            }
          }
        }
        $scope.globalKeyListener.forEach(function (kl) {
          if (window.location.href.includes(kl.pagename)) {
            kl.handler(e);
          }
        });
      });

      // ************************
      // ***** permission for notifications
      Notification.requestPermission().then(function (result) {
        console.log("Request permission:" + result);
      });

      // ************************
      // ***** open tickets
      $scope.loadOpenTickets = function () {
        if (!$scope.config.user) {
          return;
        }                                // erst nach dem einloggen
        _http.get("api/intern/crm/tickets/count-open-for-user", function (res) {
          $scope.openTickets = res.count;
        }, function () { /* ignore */
        });
      };

      $scope.loadOpenTickets();

      // ************************
      // ***** call logic
      var color_red = "#ff8a8a";
      var color_yellow = "#ffeeab";
      var color_green = "#6bff84";
      $scope.telefon = {
        status: "Pending", // Klingelt, Verbunden, Beendet
        closeAfter: 0,

        nummer: "", kdnr: "", firma: "", ansprechpartner: "",
        sollErfassen: false,

        show: false, geschlosseneNummer: "",
        background: "#ffeeab",
        typ: "",
        erfassen: false, message: "",
        callStart: 0, duration: 0,
        close: function () {
          closeTelefon();
        },
        keyDown: function (event) {
          keyDownTelefon(event);
        }
      };

      var keyDownTelefon = function (event) {
        if (event.key === "s" && event.ctrlKey) {
          event.stopPropagation();
          event.preventDefault();
          closeTelefon();
        }
      };

      var closeNotification = function () {
        var t = $scope.telefon;
        if (t.notification) {
          t.notification.close();
          t.notification = null;
        }
      };

      var createNotification = function () {
        var t = $scope.telefon;
        var icon = "/images/kontakt-phone.png";

        t.notification = new Notification("Eingehender Anruf", {
          body: "von " + (t.firma
            ? "" + t.firma + " (" + t.nummer + ")"
            : "" + t.nummer + " (unbekannt)"),
          icon: icon,
          tag: "Anruf-Notification"
        });
        t.notification.onclick = function () {
          if (t.kdnr && t.kdnr !== "10738") {
            window.focus();
            location.href = "#ckundendetails?kdnr=" + t.kdnr;
          }
          closeNotification();
        };
      };

      var loadFirmaByNumber = function () {
        var t = $scope.telefon;
        t.kdnr = "";
        t.firma = "";
        if (!t.nummer) {
          return;
        }

        _http.get("api/intern/crm/find-by-tel/" + t.nummer, function (kd) {
          if (t.status === "Pending") {
            return;
          }
          if (kd.kdnr) {
            t.kdnr = kd.kdnr;
            t.ansprechpartner = kd.ansprechpartner;
          }
          if (kd.name) {
            t.firma = kd.name;
            t.sollErfassen = kd.erfassen;
          }
          if (t.notification) {
            createNotification(); // update notification
          }
        }, function (e) { /* ignore errors */
        });
      };

      var messageErfassen = function () {
        var t = $scope.telefon;
        console.log(t);
        if (!t.erfassen) {
          return;
        }
        // kdnr ermitteln
        var kdnr = t.kdnr;
        if (!kdnr) {
          if (location.href.indexOf("kundendetails") > -1) {
            kdnr = location.href.replace(/.*kdnr=/, "");
          }
        }

        if (kdnr) {
          _http.post("api/intern/crm/" + kdnr + "/add-anruf", {inhalt: t.message, dauer: t.duration, eingehend: t.eingehend, nummer: t.nummer}, function () {
            setTimeout(function () {
              location.href = "#kundendetails?kdnr=" + kdnr + "&editLastActivity";
            }, 200);
          }, "Anruf konnte nicht erfasst werden");
        } else {
          t.message = "";
          ToolsService.showToast("Aktivität konnte nicht gespeichert werden, da kein Kunde erkannt wurde", "error");
        }

        t.message = "";
      };

      var closeTelefon = function () {
        var t = $scope.telefon;
        closeNotification();
        messageErfassen();
        t.status = "Pending";
        t.show = false;
        t.eingehend = true;
        t.erfassen = false;
        t.sollErfassen = false;
        t.closeAfter = 0;
        t.geschlosseneNummer = t.nummer;
        t.nummer = "";
        t.kdnr = "";
        t.firma = "";
        t.ansprechpartner = "";
        t.duration = 0;
        t.callStart = 0;
      };

      setInterval(function () {
        if (!$scope.config.user) {
          return;
        }                                // erst nach dem einloggen

        var t = $scope.telefon;

        if (t.show && t.callStart) {                                        // wenn Anruf läuft ... Anrufdauer berechnen
          t.duration = Math.floor((Date.now() - t.callStart) / 1000);
        }

        if (t.status === "Pending" && !$scope.mainTelTab) {
          return;
        }       // wenn gerade kein Anruf läuft und nicht Main-Tab ... nichts machen

        _http.get("api/intern/telefon/status?tabid=" + $scope.tabID, function (tel) {                                           // ***** REQUEST TEL STATUS *****
          /* jshint maxcomplexity:30 */

          var eingehenderAnruf = (tel.status === 'EingehenderAnruf');
          var esKlingelt = (tel.status === 'EingehenderAnruf' || tel.status === 'AusgehenderAnruf');
          var laufenderAnruf = (tel.status === 'LaufenderEingehenderAnruf' || tel.status === 'LaufenderAusgehenderAnruf');
          var pending = (tel.status === 'Pending');

          if (t.geschlosseneNummer) {
            if (t.geschlosseneNummer === tel.nr) {
              return;
            }
            t.geschlosseneNummer = "";
          }

          if (t.status === "Beendet" && t.closeAfter && Date.now() > t.closeAfter) {
            t.closeAfter = 0;
            if (!t.erfassen || !t.message) {                          // wir schließen nur automatisch, wenn nichts erfasst wurde
              closeTelefon();
            }
          }

          if ((t.status === "Pending" && esKlingelt) ||
            (t.status === "Pending" && laufenderAnruf)) {            // Pending -> direkt zu lfd. Anruf --> simuliere klingeln
            t.show = true;
            t.status = "Klingelt";
            t.nummer = tel.nr;
            t.background = color_yellow;
            t.eingehend = eingehenderAnruf;
            t.typ = eingehenderAnruf ? "Eingehender Anruf" : "Ausgehender Anruf";
            loadFirmaByNumber();
            if (eingehenderAnruf) {
              createNotification();
            }
          }

          if (t.status === "Klingelt" && esKlingelt && t.nummer !== tel.nr) {
            t.nummer = tel.nr;
            t.kdnr = "";
            t.firma = "";
            t.ansprechpartner = "";
            loadFirmaByNumber();
            if (eingehenderAnruf) {
              createNotification();
            }
          }

          if (t.status === "Klingelt" && laufenderAnruf) {
            closeNotification();
            t.status = "Verbunden";
            t.callStart = Date.now();
            t.background = color_green;
            t.typ = "Laufender Anruf";
            if (t.sollErfassen) {
              t.erfassen = true;
            }
            if (t.kdnr && t.kdnr !== "10738") {
              location.href = "#kundendetails?kdnr=" + t.kdnr;
            }
            if (t.erfassen) {                                                                           // ... falls erfassen eingeschaltet --> focus
              setTimeout(function () {
                document.getElementById("tel-erfassen").focus();
              }, 50);
            }
          }

          if ((t.status === "Klingelt" || t.status === "Verbunden") && pending) {
            closeNotification();
            t.status = "Beendet";
            t.typ = "Anruf beendet";
            t.background = color_red;
            t.callStart = null;              // stoppt den Timer
            t.closeAfter = Date.now() + 2500;  // 2,5s
          }


        }, function () {/* ignore failure */
        });
      }, 1000);
    };


  });
})();
