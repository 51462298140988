
/* [src] src/support-app/RechnungsListController.js */
(function () {
  "use strict";

  angular.module("MeldecenterApp").controller("RechnungsListController", function ($scope, ToolsService, _http, Requests, $timeout) {
    $scope.setTitle("Rechnungen");
    
    var vm = this;
    
    // **************************************************
    // ***** Filter
    vm.loading = false;
    vm.filter = {
      show: true,
      art: {
        Allgemein: true,
        Update: true,
        Abschlag: true,
        Abschluss: true
      },
      jahr: new Date().getFullYear(),
      status: {
        Draft: true,
        Freigegeben: true,
        Storniert: true
      },
      kdnr: "",
      rnummer: ""
    };
    if(ToolsService.qs("rnr")){
      vm.filter.rnummer = ToolsService.qs("rnr");
    }
    vm.summe = 0.0;
    
    vm.predefinedFilter = function(name){
      vm.filter.show = true;
      vm.filter.jahr = "";              
      vm.filter.art.Allgemein = true;          
      vm.filter.art.Update = true;       
      vm.filter.art.Abschlag = true;
      vm.filter.art.Abschluss = true;
      vm.filter.status.Draft = true;
      vm.filter.status.Freigegeben = true;
      vm.filter.status.Storniert = true;
      vm.filter.kdnr = "";
      vm.filter.rnummer = "";
      
      if(name === 'none'){
        vm.filter.show = false;
      } 
      
      if(name === 'draft'){
        vm.filter.status.Freigegeben = false;
        vm.filter.status.Storniert = false;
      } 
      
      if(name === 'year'){
        vm.filter.jahr = new Date().getFullYear();
      }         
    };
    
    $scope.$watch(function() { return JSON.stringify(vm.filter); }, function() {
      vm.ladeRechnungen();
    });
    
    // **************************************************
    
    vm.neu = function(){
      window.location="#rechnung?id=neu";
    };
    
    vm.freigeben = function(r){
      vm.data.draftOnly = false;
      _http.post("api/intern/crm/rechnung/" + r.id + "/freigeben", function(){
        r.status = "Freigegeben";
      });
    };
    
    vm.bulkFreigeben = function(){
      _http.post("api/intern/crm/rechnung/bulk/freigeben" + buildQuery(), function(){
        vm.ladeRechnungen();
      });
    };
    vm.bulkLoeschen = function(){
      _http.post("api/intern/crm/rechnung/bulk/loeschen" + buildQuery(), function(){
        vm.ladeRechnungen();
      });
    };
    
    vm.bulkMail = function(){
      if(!confirm("Sollen wirklich " + vm.data.anzahlFilter + " Rechnungen per Mail an den Kunden gesendet werden? Dies dauert möglicherweise mehrere Stunden.")) { return; }
      var pw = prompt("Bitte Passwort eingeben (mail):");
      if(!pw || pw !== "mail") { return; }
      ToolsService.showToast("Mails werden verschickt", "info");
      _http.post("api/intern/crm/rechnung/bulk/mail" + buildQuery(), function(){
        vm.ladeRechnungen();
      });
    };
    
    // **************************************************
    var buildQuery = function(){
      return "?jahr="    +  vm.filter.jahr                                      +
             "&art="     + (vm.filter.art.Allgemein      ? "Allgemein,"   : "") +
                           (vm.filter.art.Update         ? "Update,"      : "") +
                           (vm.filter.art.Abschlag       ? "Abschlag,"    : "") +
                           (vm.filter.art.Abschluss      ? "Abschluss"    : "") +
             "&status="  + (vm.filter.status.Draft       ? "Draft,"       : "") +
                           (vm.filter.status.Freigegeben ? "Freigegeben," : "") +
                           (vm.filter.status.Storniert   ? "Storniert,"   : "") +
             "&kdnr="    +  vm.filter.kdnr                                      +
             "&rnummer=" +  vm.filter.rnummer;
    };
    
    vm.ladeRechnungen = function(){
      var query = vm.filter.show ? buildQuery() : "";
      
      vm.loading = true;  
      _http.get("api/intern/crm/rechnung" + query, function(data){
        vm.data = data;
        vm.loading = false;
      });
    };
    
    // **************************************************
    
    return vm;
  });
})();
