
/* [src] src/support-app/CKundenDetailsController.js */
(function () {
  "use strict";

  angular.module("MeldecenterApp").controller("CKundenDetailsController", function ($scope, ToolsService, _http, Requests, ClipboardService, $http) {
    $scope.setTitle("Details für " + ToolsService.qs('kdnr'));

    var vm = this;

    // **************************************************************************
    // **************************************************************************
    // **************************************************************************
    // ***** HELPER ****
    var stopEvent = function(event){
      event.stopPropagation();
      event.preventDefault();
    };
    
    $scope.addGlobalKeyListener({
      pagename: "ckundendetails",
      handler: function(e){
        if(e.key === "PageUp"){
          vm.eKey({key: "Escape", stopPropagation: function(){}, preventDefault: function(){} }); // cancel all edits
          _http.getPlain("api/intern/crm/search/next/" + vm.data.kundennummer, function(kdnr){
            location.href = "#ckundendetails?kdnr=" + kdnr;
          });
        }else if(e.key === "PageDown"){
          vm.eKey({key: "Escape", stopPropagation: function(){}, preventDefault: function(){} }); // cancel all edits
          _http.getPlain("api/intern/crm/search/prev/" + vm.data.kundennummer, function(kdnr){
            location.href = "#ckundendetails?kdnr=" + kdnr;
          });
        }
      }
    });
    
    vm.silentToastMessage = "";

    var showSilentToast = function(text, requiresApply) {
      vm.silentToastMessage = text;
      if(requiresApply){
        $scope.$apply();
      }
      $("#cKundendetailsDiv #silent-toast").removeClass("hidden");

      setTimeout(function(){
        $("#cKundendetailsDiv #silent-toast").addClass("hidden");
      }, 2000);
    };

    var currentDate = function(inclHours){
      // returns current date in format "dd.MM.yyyy"
      // if inclHours in format "dd.MM.yyyy HH:mm:ss"
      var two = function(x){
        return x.toString().length === 1 ? "0" + x : x;
      };

      var date=new Date();

      if(inclHours){
        return two(date.getDate()     ) + "." +
               two(date.getMonth() + 1) + "." +
                   date.getFullYear()   + " " +
               two(date.getHours()    ) + ":" +
               two(date.getMinutes()  ) + ":" +
               two(date.getSeconds()  );
      } else {
        return two(date.getDate()     ) + "." +
               two(date.getMonth() + 1) + "." +
                   date.getFullYear();
      }
    };

    var compareDates = function(d1, d2){
      // compares two dates in format dd.mm.yyyy ...
      // -1 if d1 < d2
      // 0  if d1 = d2
      // +1 if d1 > d2
      if(d1 === d2) { return 0; }
      var d1s = d1.substr(6,4) + d1.substr(3,2) + d1.substr(0,2);
      var d2s = d2.substr(6,4) + d2.substr(3,2) + d2.substr(0,2);
      return d1s < d2s ? -1 : 1;
    };

    // **************************************************************************
    // **************************************************************************
    // **************************************************************************
    // ***** EDIT ****
    var currentEdit = null;
    var currentEditObject = null;
    var currentEditField = null;

    var setEdit = function(editId){
      currentEdit = editId;                                           // show edit
      if(editId){
        setTimeout( function(){
          $("#cKundendetailsDiv #" + editId + " .focusable").focus();  // set focus (after it is visible)
        }, 0);
      }
    };
    
    vm.trimInhalt = function( h ){
      if(h.inhalt){ h.inhalt = h.inhalt.trim(); }
    };


    var saveEdit = function(){
      if(currentEditObject && currentEditField) {
        var element = $("#cKundendetailsDiv #" + currentEdit + " .focusable");
        var newValue = element.val();

        if((element.attr("c-type") === "date")){
          newValue = ToolsService.parseDate(newValue);
        }
        currentEditObject[currentEditField] = newValue; // write data

        var pu = element.attr("post-update");   // ctrl.method ( oneParam )
        if(pu) {
          pu = pu.replace(/ctrl./,"");          // remove "ctrl."
          pu = pu.replace(/\)/,"");             // remove ")"
          var parts = pu.split("(");
          var method = parts[0];
          var param = parts[1].trim();
          vm[method](currentEditObject, currentEditField, newValue, param);       // invoke handler
        }

        currentEditObject = null;
        currentEditField = null;
        anyChanges();
      }
    };
    
    vm.cursorToStart = function(x){
      x.target.selectionStart = 0;
      x.target.selectionEnd = 0;
    };

    vm.eClick = function(event, parents){
      if(!parents || parents === 1){
        setEdit(event.target.parentElement.id);
      }else if(parents === 2){
        setEdit(event.target.parentElement.parentElement.id);
      }
    };

    vm.eVis = function(element, isEdit, label){
      return (currentEdit === element) === isEdit;
    };

    vm.eBlur = function(event) {
      saveEdit();
      setEdit(null);
    };

    vm.eFocus = function(event, object, field) {
      currentEditObject = object;
      currentEditField = field;
      if(field === "inhalt"){
        event.target.value = "\n\n" + object[field];
      }else {
        event.target.value = object[field];
      }
    };

    vm.endEdit = function() {
      setEdit(null);
    };

    vm.eKey = function(event, ignoreReturn) {
      if(event.key === "Tab"){
        saveEdit();
        stopEvent(event);
        setEdit(event.target.parentElement.parentElement.getAttribute(event.shiftKey ? "prev-tab" : "next-tab"));
        return;
      }

      if(event.key === "Enter" || (event.ctrlKey && event.key === "s")){
        if(event.key === "Enter" && !event.shiftKey && ignoreReturn){
          return;
        }
        saveEdit();
        setEdit(null);
        stopEvent(event);
        return;
      }

      if(event.key === "Escape"){
        currentEditObject = null; // disable save
        currentEditField = null;
        setEdit(null);
        stopEvent(event);
        return;
      }
    };

    // **************************************************************************
    // **************************************************************************
    // **************************************************************************
    // ***** TOGGLES ****
    vm.toggles = {
      kontakt: false,
      status: true,
      activity: true,
      tickets: false,
      statushistoryshowall: false,
      rechnungen: false,
      statistiken: false
    };

    vm.toggle = function(name, only){
      if(only === true){
        vm.toggles = {};
      }

      vm.toggles[name] = !vm.toggles[name];
    };

    vm.isShown = function(name){
      return vm.toggles[name];
    };
    
    if(window.location.href.indexOf("rechnung") > -1){
      vm.toggles.status = false;
      vm.toggles.activity = false;
      vm.toggles.rechnungen = true;
    }

    // **************************************************************************
    // **************************************************************************
    // **************************************************************************
    // ***** BUSINESS ****
    vm.users = $scope.qlServiceUsers;
    vm.kundenAction = "";
    vm.data = {};
    vm.rights = {};
    vm.tickets = [];
    vm.limitActivity = 10;

    vm.addContact = function(){
      var typ = vm.mailKontaktVorhanden() ? "Tel" : "Mail";
      var neu = { typ: typ, wert:"", name: "", anrede: "Unbekannt", rolle: "" };
      if(typ === 'Tel' && $scope.telefon && $scope.telefon.show && $scope.telefon.nummer){
        neu.wert = $scope.telefon.nummer;
      }
      vm.data.anschrift.kontakte.push(neu);

      setTimeout(function() {
        saveEdit();
        setEdit("kontakt-1-" + (vm.data.anschrift.kontakte.length-1));
        $scope.$apply();
      }, 0);
    };

    vm.mailKontaktVorhanden = function(){
      if(!vm.data||!vm.data.anschrift||!vm.data.anschrift.kontakte){
        return false;
      }
      return vm.data.anschrift.kontakte.some(function(k){ return k.typ === 'Mail'; });
    };

    vm.kTypOnKey = function(kontakt, event){
      var endTyp = function(){ event.key = "Tab";  vm.eKey(event);  };
      var key = event.key.toLowerCase();
      if(key === 'e' || key === 'm'){
        if(!vm.mailKontaktVorhanden()){
          event.target.value = "Mail";       endTyp();
        }
      }
      if(key === 'r'){ event.target.value = "Mail_Rechnung"; endTyp();  }
      if(key === 't'){ event.target.value = "Tel";           endTyp();  }
      if(key === 'f'){ event.target.value = "Fax";           endTyp();  }
      if(key === 's'){ event.target.value = "Sonstige";      endTyp();  }
      if(key === 'backspace' || key === 'delete'){ event.target.value = "DELETE"; endTyp();  }
    };

    vm.kAnredeOnKey = function(kontakt, event){
      var endAnrede = function(){ event.key = "Tab";  vm.eKey(event);  };
      var key = event.key.toLowerCase();
      if(key === 'h'){ event.target.value = "Herr";      endAnrede();  }
      if(key === 'f'){ event.target.value = "Frau";      endAnrede();  }
      if(key === 'x'){ event.target.value = "Unbekannt"; endAnrede();  }
    };

    vm.addKontaktOnTab = function(index, event){
      if(event.key === 'Tab' && !event.shiftKey && index === vm.data.anschrift.kontakte.length - 1){
        vm.addContact();
        stopEvent(event);
      } else {
        vm.eKey(event);
      }
    };

    vm.kTypDeleteCheck = function(object, field, newValue, index){
      if(newValue === "DELETE"){
        vm.data.anschrift.kontakte.splice(index, 1);
      }
    };

    vm.toClipboard = function(text) {
      ClipboardService.copyTextToClipboard(text);
      showSilentToast("In Zwischenablage kopiert");
    };
    
    vm.sendeKdnrMail = function() {
      if(confirm("Soll die E-Mail wirklich versandt werden?")){
        _http.post("api/intern/crm/" + ToolsService.qs('kdnr') + "/email/kdnr_pwd", function (data) {
          ToolsService.showToast("E-Mail versandt", "info");
          vm.load();
        }, "Fehler beim Senden der E-Mail");
       }
    };
    vm.sendeKdnrMailErneut = function() {
      if(confirm("Soll die E-Mail wirklich versandt werden?")){
        _http.post("api/intern/crm/" + ToolsService.qs('kdnr') + "/email/kdnr_pwd_erneut", function (data) {
          ToolsService.showToast("E-Mail versandt", "info");
          vm.load();
        }, "Fehler beim Senden der E-Mail");
       }
    };
    
    vm.erstelleNeukundenRechnung = function() {
      _http.postPlain("api/intern/crm/" + ToolsService.qs('kdnr') + "/rechnung/neukunde", function (data) {
        vm.ladeRechnungen();
        vm.toggles.rechnungen = true;
        window.open("#rechnung?id=" + data, "_blank");
      });
    };
    
    vm.sendRechnungMail = function(rid){
      if(!confirm("Soll die Rechnung wirklich per Mail an den Kunden gesendet werden?")) { return; }
      _http.post("api/intern/crm/rechnung/" + rid + "/mail", function(){
        ToolsService.showToast('E-Mail versandt', 'info');
        vm.load();
      });
    };

    vm.sendeInaktivMail = function(rid){
      if(!confirm("Soll der Kunde wirklich per Mail gefragt werden, ob er Quick-Lohn noch aktiv nutzt?")) { return; }
      _http.post("api/intern/crm/" + ToolsService.qs('kdnr') + "/email/inaktiv", function (data) {
          ToolsService.showToast("E-Mail versandt", "info");
          vm.load();
        }, "Fehler beim Senden der E-Mail");
    };
    
    vm.sendeFernwartungMail = function(rid){
      if(!confirm("Soll der Kunde wirklich eine Mail mit dem Link zur Fernwartung bekommen?")) { return; }
      _http.post("api/intern/crm/" + ToolsService.qs('kdnr') + "/email/fernwartung", function (data) {
          ToolsService.showToast("E-Mail versandt", "info");
          vm.load();
        }, "Fehler beim Senden der E-Mail");
    };
    
    vm.starteKlicktipp = function(){
      if(confirm("Soll die Klicktipp-Kampagne wirklich gestartet werden?")){
        _http.post("api/intern/crm/" + ToolsService.qs('kdnr') + "/klicktipp/kampagne-neukunde-1", function (data) {
          ToolsService.showToast("Kampagne erfolgreich gestartet", "info");
          vm.load();
        }, "Fehler beim Starten der Kampagne, bitte Marcel informieren");
      }
    };

    vm.generateLink = function(kontakt) {
      if(kontakt.typ==="Tel") { return "tel:"+kontakt.wert.replace(/[^0-9+]/g, ""); }
      if(kontakt.typ==="Mail" || kontakt.typ==="Sonstige") { return "mailto:"+kontakt.wert; }

      return "#";
    };

    vm.generatePasswort = function(input, event){
      if(event.currentTarget.value) { return; }
      var chars = "ABCDEFGHJKLMNPQRSTUVWXYZ";
      var nums = "0123456789";
      var pw = chars[Math.floor(Math.random() * chars.length)] +
               chars[Math.floor(Math.random() * chars.length)] +
               nums [Math.floor(Math.random() * nums.length) ] +
               nums [Math.floor(Math.random() * nums.length) ] +
               nums [Math.floor(Math.random() * nums.length) ];
      event.currentTarget.value = pw;
    };

    vm.ansprechpartnerShort = function() {
      if(!vm.data || !vm.data.anschrift || !vm.data.anschrift.kontakte){  return ""; }

      var res = new Set();  // jshint ignore:line
      vm.data.anschrift.kontakte.forEach(function(k){
        if(k.name){
          var anrede = k.anrede === 'Unbekannt' ? ''     :
                       k.anrede === 'Herr'      ? 'Hr. ' :
                                                  'Fr. ' ;
          var rolle = k.rolle ? " ("+k.rolle+")" : "";
          var app = anrede + k.name + rolle;
          res.add(app);
        }
      });

      return Array.from(res).join(", ");
    };

    vm.typ = function(){
      if(!vm.data.status){ return ""; }
      switch(vm.data.status.typ){
       case "Kunde": return "Kunde";
       case "ExKunde": return "Ex-Kunde";
       case "Tester": return "Tester";
       case "Kontakt": return "Kontakt";
       default: return "?????";
      }
    };

    vm.typMz = function(){
      if(!vm.data.status){ return ""; }
      switch(vm.data.status.typ){
       case "Kunde": return "Kunden";
       case "ExKunde": return "Ex-Kunden";
       case "Tester": return "Tester";
       case "Kontakt": return "Kontakte";
       default: return "?????";
      }
    };

    vm.kBrancheAllgemein = function(entry){
      entry.bau = "keinBau";
      entry.branchen = [];
    };

    vm.kBranchenUpdateVersion = function(object, field, newValue, index){
      if(!newValue || newValue.length === 0){
        object.bau = "keinBau";
      }else if(newValue.indexOf("Bauhauptgewerbe") > -1){
        object.bau = "BauHaupt";
      }else{
        object.bau = "BauNeben";
      }
    };

    vm.resetCounter = function(){
      var sendMail = confirm("Soll der Kunde per Mail informiert werden, dass sein Passwort-Counter zurückgesetzt worden ist?");
      
      _http.postPlain("api/intern/crm/" + vm.data.kundennummer + "/pwcounter/reset?sendMail=" + !!sendMail, function(){
        ToolsService.showToast("Passwort-Counter zurückgesetzt", "info");
        setEdit(null);
        vm.load();
      });
    };

    vm.addStatusEntry = function(typ){
      var findeLetzteAnpassung = function(){                            // Helper ... letzte Anpassung finden
        for(var i = vm.data.status.history.length - 1; i >= 0; i--){    // ...
          if(vm.data.status.history[i].typ === 'Anpassung'){            // ...
            return vm.data.status.history[i];                           // ...
          }                                                             // ...
        }                                                               // ...
        return null;                                                    // ...
      };                                                                // -------------------

      var entry = {                                                     // Entry erzeugen
        gueltig_ab: currentDate(false)
      };

      var addEntry = true;
      if(typ==='anpassung'){                                            // ******************* Anpassung
        var letzteAnpassung = findeLetzteAnpassung();

        entry.typ = 'Anpassung';
        entry.branchen    = letzteAnpassung ? letzteAnpassung.branchen    : [];
        entry.bau         = letzteAnpassung ? letzteAnpassung.bau         : 'keinBau';
        entry.mitarbeiter = letzteAnpassung ? letzteAnpassung.mitarbeiter : 5;
        entry.module      = letzteAnpassung ? letzteAnpassung.module      : "";
      } else if(typ==='kuendigung') {                                   // ******************* Kündigung
        entry.typ = 'Kuendigung';
        entry.kuendigungs_grund = "";
        var letzterAbrMonat = prompt("Welcher ist der letzte Abrechnungsmonat?", "12/" + (new Date().getFullYear() - 2000));
        addEntry = vm.letzterAbrMonatUpdated(entry, letzterAbrMonat);
      }else{
        console.log("NOT IMPLEMENTED");
      }

      if(addEntry){                                                     // Entry hinzufügen
        vm.toggles.statushistoryshowall = true;
        vm.toggles.statusHistoryEdit = true;
        vm.data.status.history.unshift(entry);
        vm.statusHistoryCalculateCurrent();
      }
    };

    vm.letzterAbrMonatUpdatedUI = function(object, field, newValue, index) {
      var entry = vm.data.status.history[index];
      vm.letzterAbrMonatUpdated(entry, newValue);
    };

    /** returns true if update was successufl */
    vm.letzterAbrMonatUpdated = function(entry, letzterAbrMonat){
      if(letzterAbrMonat.match( "^[0-9][0-9][0-9]$")){ letzterAbrMonat = letzterAbrMonat.substr(0,1) + "/" + letzterAbrMonat.substr(1,2);  }   // myy -> m/yy
      if(letzterAbrMonat.match("^1[0-9][0-9][0-9]$")){ letzterAbrMonat = letzterAbrMonat.substr(0,2) + "/" + letzterAbrMonat.substr(2,2);  }   // mmyy -> mm/yy
      if(letzterAbrMonat.match("^[0-9]/[0-9][0-9][0-9][0-9]$")){ letzterAbrMonat = letzterAbrMonat.substr(0,1) + "/" + letzterAbrMonat.substr(4,2);  }   // myyyy -> mm/yy
      if(letzterAbrMonat.match("^1[0-9]/[0-9][0-9][0-9][0-9]$")){ letzterAbrMonat = letzterAbrMonat.substr(0,2) + "/" + letzterAbrMonat.substr(5,2);  }   // mmyyyy -> mm/yy
      if(!letzterAbrMonat || !letzterAbrMonat.match("[1]?[0-9]/[0-9][0-9]") ){
        ToolsService.showToast("Kündigung abgebrochen", "error");
        return false;
      }
      _http.get("api/intern/crm/util/kuendigungsdaten/"+letzterAbrMonat, function(data){
        entry.gueltig_ab = data.gekuendigt_zum;
        entry.letzter_abr_monat = data.letzter_abr_monat;
        entry.gekuendigt_zum = data.gekuendigt_zum;
        entry.senden_bis = data.senden_bis;
        entry.abrechnen_bis = data.abrechnen_bis;
        entry.update_laden = data.update_laden;
        entry.kunde_bis = data.kunde_bis;
        vm.statusHistoryCalculateCurrent();
      });
      return true;
    };

    vm.sendMailKuendigung = function(entry){
      var ab = entry.gueltig_ab;
      
      if(confirm("Soll dem Kunden wirklich die Kündigungsbestätigung zum " + ab + " per Mail zugesandt werden?")){
        _http.post("api/intern/crm/" + ToolsService.qs('kdnr') + "/email/kuendigung",{datum:ab}, function (data) {
          ToolsService.showToast("E-Mail versandt", "info");
          vm.load();
        }, "Fehler beim Senden der E-Mail");
      }
    };

    /*
      statusHistoryCurrentIndex - gibt den Index, des letzten aktuellen Eintrags an
    */
    vm.statusHistoryCurrentIndex = -1;
    vm.statusHistoryCalculateCurrent = function(){
      var today = currentDate();
      vm.statusHistoryCurrentIndex = -1;
      var h = vm.data.status.history;

      // geht alle Einträge durch - wenn Eintrag in Vergangenheit, springt er raus
      for(var i = 0; i<h.length; i++){
        var e = h[i];
        vm.statusHistoryCurrentIndex = i;
        var gueltigAbInVergangenheit = compareDates(e.gueltig_ab, today) < 1;
        if(gueltigAbInVergangenheit){
          break;
        }
      }

      // check, falls alle Einträge in der future
      if(vm.statusHistoryCurrentIndex === -1){ return; }
      var e1 = h[vm.statusHistoryCurrentIndex];
      var gueltigAbInZukunft = compareDates(e1.gueltig_ab, today) > 0;
      if(gueltigAbInZukunft){
        vm.statusHistoryCurrentIndex++;
      }
    };

    vm.statusHistoryFiltered = function(){
      var arr = vm.data.status.history;
      if(vm.toggles.statushistoryshowall){ return arr; }
      if(vm.statusHistoryCurrentIndex === -1){ return []; }
      return arr.slice(0, vm.statusHistoryCurrentIndex + 1);
    };

    vm.statusHistoryFilteredCounter = function(){
      if(!vm.data.status){ return 0; }
      return vm.data.status.history.length - vm.statusHistoryFiltered().length;
    };

    vm.removeStatusEntry = function(index){
      vm.endEdit();
      if(confirm("Soll der Eintrag wirklich gelöscht werden?")){
        vm.data.status.history.splice(index, 1);
        vm.statusHistoryCalculateCurrent();
      }
    };

    var sortActivity = function(){
      vm.data.history.entries.sort(function(a, b){  // -1 wenn a kleiner, +1 wenn a größer
        if(a.prio && !b.prio){ return -1; }
        if(!a.prio && b.prio){ return +1; }

        var getParts = function(x){
          return [
            x.substr(6,4),   // year
            x.substr(3,2),   // month
            x.substr(0,2),   // day
            x.substr(11,2),  // hour
            x.substr(14,2),  // minute
            x.substr(17,2)   // second
          ];
        };

        var aParts = getParts(a.datum);
        var bParts = getParts(b.datum);
        for(var i = 0; i < 6; i++){
          if(aParts[i] > bParts[i]){ return -1; }
          if(aParts[i] < bParts[i]){ return +1; }
        }
        return 0;
      });
    };

    vm.changeActivityPrio = function(activity){
      activity.prio = !activity.prio;
      sortActivity();
    };

    vm.activityTitle = function(str){
      if(!str){
        return "";
      }
      var parts = str.split("\n");
      var part1 = parts[0];
      
      if(part1.length>60) { return part1.substr(0,60) + " ..."; }
      if(parts.length>1) { return part1; }
      return str;
    };

    vm.newActivity = function(){
      var user = $scope.config.user.name.split(" ")[0];

      vm.data.history.entries.unshift({
        datum: currentDate(true),
        typ: "Notiz",
        inhalt: "$$NEU$$",
        bearbeiter: user
      });

      sortActivity();

      var correctIndex=0;
      vm.data.history.entries.forEach(function(h, index){
        if(h.inhalt === "$$NEU$$"){
          correctIndex = index;
          h.inhalt = "";
        }
      });
      setEdit("activity-typ-" + correctIndex);
    };

    vm.removeHistory = function(index){
      vm.data.history.entries.splice(index, 1);
      showSilentToast("Eintrag gelöscht");
    };

    vm.filter = {
      benutzer: [],
      inhalt: "",
      typ: [],
      text: ""
    };

    vm.filterChanged = function(){
      var f = vm.filter;
      var text = "";
      var append = function(x){
        if(!text){ text = x; }else{ text += "; " + x; }
      };

      if(!f.typ ||f.typ.length===0){
        // nothing
      }else if(f.typ.length===1){
        append("Typ = " + f.typ[0]);
      } else {
        append("Typ in (" + f.typ + ")");
      }

      if(f.inhalt){
        append("Inhalt = '" + f.inhalt + "'");
      }

      if(!f.bearbeiter || f.bearbeiter.length===0){
        // nothing
      } else if(f.bearbeiter.length===1){
        append("User = " + f.bearbeiter[0]);
      } else {
        append("User in (" + f.bearbeiter + ")");
      }
      f.text = text;
    };

    vm.filterHistory = function(h){
      var f = vm.filter;
      if(f.typ && f.typ.length>0){
        if(!f.typ.includes(h.typ)){
          return false;
        }
      }
      if(f.bearbeiter && f.bearbeiter.length>0){
        if(!f.bearbeiter.includes(h.bearbeiter)){
          return false;
        }
      }
      if(f.inhalt) {
        if(h.inhalt.toLowerCase().indexOf(f.inhalt) === -1){
          return false;
        }
      }

      return true;
    };

    vm.performKundenAction = function(){
      switch(vm.kundenAction){
      case "Tester zu Kunde":
        vm.data.status.typ = 'Kunde';
        vm.addStatusEntry('anpassung');
        vm.data.history.entries.unshift({
          datum: currentDate(true),
          typ: "Anpassung",
          inhalt: "Manuelle Umwandlung von Tester zu Kunde",
          bearbeiter: $scope.config.user.name.split(" ")[0]
        });
        anyChanges();   // muss manuell aufgerufen werden, damit die Änderungen erkannt werden und auch wirklich gespeichert wird
        saveKunde();
        saveActive = false;
        setTimeout(function(){
          _http.postPlain("api/intern/crm/" + vm.data.kundennummer + "/convert-tester-to-kunde", function(kdnr){
            location.href = "#cKundendetails?kdnr=" + kdnr;
          });
        }, 1500);
        break;
      case "Tester löschen":
        _http.delete("api/intern/crm/" + vm.data.kundennummer, function(){
          ToolsService.showToast("Tester gelöscht", "info");
          location.href = "#ckundenliste";
        });
        break;
      case "Kontakt löschen":
        _http.delete("api/intern/crm/" + vm.data.kundennummer, function(){
          ToolsService.showToast("Kontakt gelöscht", "info");
          location.href = "#ckundenliste";
        });
        break;
      case "Wieder aktivieren":
        vm.data.status.typ = "Kunde";
        break;
      case "Kunden löschen":
        _http.delete("api/intern/crm/" + vm.data.kundennummer, function(){
          ToolsService.showToast("Kunde gelöscht", "info");
          location.href = "#ckundenliste";
        }, function(e){
          ToolsService.showToast("Kunde kann nicht gelöscht werden, da er bereits Meldungen eingeliefert hat", "error");
        });
        break;
      }
      vm.kundenAction = "";
    };

    if(document.body.clientWidth > 1400){
      vm.kontaktOpenedWithTimer = true;
      vm.toggles.kontakt = true;
    }

    // **************************************************************************
    vm.firmen = null;
    vm.firmenToggles = {
      zeigeIsm: true,
      zeigeAlteIsm: false,
      zeigeAlteFirmen: false,
      filter: ""
    };
    vm.loadFirmen = function(){
      if(vm.firmen) { return; }
      console.log("Lade Firmen für " + vm.data.kundennummer);
      _http.get("api/intern/crm/" + ToolsService.qs('kdnr') + "/firmen", function (data) {
        vm.firmen = data;
      });
    };
    vm.firmenIsmTooltip = function(counter){
      var tooltip = "";
      Object.keys(counter).forEach(function(key){
        tooltip += key + ": " + counter[key] + "<br>";
      });
      return tooltip;
    };
    vm.firmenFilterKeydown = function(event){
      if(event.key === "Escape"){
        vm.firmenToggles.filter = "";
      }
    };
    vm.firmenNameFilter = function(firma){
      var filter = vm.firmenToggles.filter;
      if(!filter) { return true; }
      var name = firma.names.toString().toLowerCase();
      if(name.indexOf(filter.toLowerCase()) === -1){ return false;}
      return true;
    };
    vm.formatIsm = function(ism){
      if(typeof ism === 'string'){
        return ism.toString().replace(" ","");
      }
      var isms = [];
      ism.forEach(function(i){
        isms.push(i.ism.toString().replace(" ",""));
      });
      return isms.join(",");
    };
    
    // **************************************************************************
    // **************************************************************************
    // **************************************************************************
    vm.editHaendler = false;
    vm.haendler = [];
    
    vm.ladeHaendler = function(){
      _http.get("api/intern/crm/haendler", function (data) {
        vm.haendler = data;
      });
    };
    
    vm.fixEmptyHaendler = function(){
      if(vm.data.rechnung.haendlerKdnr === ""){
        vm.data.rechnung.haendlerKdnr = null;
      }
    };
    
    vm.haendlerName = function(){
      if(vm.haendler.length === 0){
        return "(wird geladen)";
      }
      return vm.haendler.find(function(h){return h.kdnr === vm.data.rechnung.haendlerKdnr;}).name;
    };
    
    
    // **************************************************************************
    // **************************************************************************
    // **************************************************************************
    vm.erzeugeTicketFuerActivityEintrag = function(h){
      _http.post("api/intern/crm/" + ToolsService.qs('kdnr') + "/erzeuge-ticket-aus-activity",
        { datum: h.datum },
        function (data) {
          location.href = "#tickets?ticket=" + data;
      });
    };


    // **************************************************************************
    // **************************************************************************
    // **************************************************************************
    vm.berechnungsdaten = null;
    
    vm.aktualisiereBerechnungsdaten = function(){
      ToolsService.showToast("Berechnungsdaten werden neu geladen. Dies kann einen Augenblick dauern. Aktuelle Werte werden automatisch angezeigt.", "info");
      _http.post("api/intern/crm/" + ToolsService.qs('kdnr') + "/rechnung/berechnungsdaten", function (data) {
        vm.ladeBerechnungsdaten();
      });
    };
    
    vm.ladeBerechnungsdaten = function(){
      _http.get("api/intern/crm/" + ToolsService.qs('kdnr') + "/rechnung/berechnungsdaten", function (data) {
        vm.berechnungsdaten = data;
      });
    };
    
    vm.rechnungen = [];
    vm.ladeRechnungen = function(){
      _http.get("api/intern/crm/rechnung?kdnr=" + ToolsService.qs('kdnr'), function (data) {
        vm.rechnungen = data.rechnungen;
      });
    };
    
    vm.neueRechnung = function(){
      window.location="#rechnung?id=neu&kdnr="+ToolsService.qs('kdnr');
    };
    
    vm.buchungen = {};
    vm.ladeBuchungen = function(){
      _http.get("api/intern/crm/rechnung/fibu/buchungen/" + ToolsService.qs('kdnr'), function (data) {
        vm.buchungen = data;
      });
    };
    
    vm.setZZ = function(){
      var value = prompt("Zahlungsziel (dd.MM.yyyy)", vm.buchungen.zz);
      _http.post("api/intern/crm/rechnung/fibu/buchungen/" + ToolsService.qs('kdnr') + "/zz", {
        zz: value
      },function (data) {
        vm.ladeBuchungen();
      });
    };

    vm.setZH = function(value){
      if(value !== ""){
        value = prompt("Zahlungshinweis");
      }
      _http.post("api/intern/crm/rechnung/fibu/buchungen/" + ToolsService.qs('kdnr') + "/zh", {
        zh: value
      },function (data) {
        vm.load();
      });
    };
    
    // **************************************************************************
    // **************************************************************************
    // **************************************************************************
    // ***** load & pageUpdated & save
    vm.anyChanges = false;
    var saveActive = false;
    var original = "";

    var anyChanges = function(){
      var actual = angular.toJson(vm.data);
      vm.anyChanges = (actual !== original);
    };

    setInterval(function(){
      if(!saveActive){ return; }
      var before = vm.anyChanges;
      anyChanges();
      if(before === vm.anyChanges){ return; }
      $scope.$apply();
    }, 500);

    var saveKunde = function(){
      if(!saveActive){
        return;
      }
      if(!vm.anyChanges) { return; }

      vm.fixEmptyHaendler();

      var actual = angular.toJson(vm.data);
      original = actual;

      $http({                     //  TODO generische Request-Method
        method: "POST",
        url: "api/intern/crm/" + vm.data.kundennummer + "/details",
        data: vm.data,
        headers: {
          'Content-Type': "application/json; charset=UTF-8"
        },
        transformResponse: [function (data) {
          return data;
        }]
      }).then(function (response) {
        showSilentToast("Änderungen gespeichert");
        loadRights();
      }, function (response) {
        ToolsService.showToast("Fehler beim Speichern der Änderungen", "error");
      });
    };

    // Auto-Save
    setInterval(saveKunde, 2000);

    vm.load = function () {
      saveActive = false;
      vm.firmen = null;
      vm.toggles.firmen = false;
      $scope.setTitle("Details für " + ToolsService.qs('kdnr'));
      _http.get("api/intern/crm/" + ToolsService.qs('kdnr') + "/details", function (data) {
        vm.data = data;

        sortActivity();
        vm.statusHistoryCalculateCurrent();

        original = angular.toJson(vm.data);
        saveActive = true;

        if(location.href.endsWith("editLastActivity")){
          window.location.replace(location.href.replace("&editLastActivity", ""));
          vm.toggles.activity = true;

          // suche letzten Eintrag und Setze Edit
          /*var foundFirst = false;
          vm.data.history.entries.forEach(function(entry, index){
            if(!entry.prio && !foundFirst){
              foundFirst = true;
              setTimeout(function(){
                setEdit("activity-inhalt-" + index);
                $scope.$apply();
              }, 1000);
            }
          });*/
        }

        if(!vm.data.name){
          vm.toggles.kontakt = true;
        }
        loadRights();
        vm.ladeBerechnungsdaten();
        vm.ladeRechnungen();
        vm.ladeBuchungen();
        vm.ladeHaendler();
        vm.loadFirmen();
        
        showSilentToast("Kunde geladen");
      }, function(){
        ToolsService.showToast("Der Kunde mit der Kundennummer '" + ToolsService.qs('kdnr') + "' existiert nicht oder kann nicht geladen werden.", "error");
        vm.data = {
          kundennummer: ToolsService.qs('kdnr'),
          name: "(Kunde nicht geladen)"
        };
      });
      _http.get("api/intern/crm/tickets?kdnr=" + ToolsService.qs('kdnr'), function(data){
        vm.tickets = data;
        vm.ticketsOpen = data.filter(function(ticket){return ticket.status === "Offen";});
      }, "Fehler beim Laden der Tickets zum Kunden");
    };

    var loadRights = function() {
      _http.get("api/intern/crm/" + ToolsService.qs('kdnr') + "/rights", function (data) {
        vm.rights = data;
      }, "Laden der Rechte");
    };

    $scope.$on('pageUpdated', function (event, page) {
      if (page.indexOf("kundendetails") > -1) {
        vm.load();
      }
    });

    vm.load();
  });
})();
