
/* [src] src/support-app/ToolsService.js */
(function () {
  "use strict";

  angular.module("MeldecenterApp").factory("ToolsService", function () {
    return {
      showToast: showToast,
      loadScript: loadScript,
      qs: qs,
      parseDate: parseDate
    };

    function showToast(message, type) {
      var $t = $("#toast");
      $t.html(message.replace("\n", "<br>"));
      switch (type) {
        case 'info':
          $t.removeClass('error');
          $t.addClass('info');
          break;
        case 'error':
          $t.addClass('error');
          $t.removeClass('info');
          break;
        default:
          alert("falscher type im toast!!");
      }

      $t.stop().fadeIn(400).delay(6000).fadeOut(400);
    }

    function qs(key) {
      key = key.replace(/[*+?^$.\[\]{}()|\\\/]/g, "\\$&"); // escape RegEx meta chars
      var match = location.href.match(new RegExp("[?&]" + key + "=([^&]+)(&|$)"));
      return match && decodeURIComponent(match[1].replace(/\+/g, " "));
    }

    /** takes any string input and returns a valid date string in format dd.MM.yyyy */
    function parseDate(dateStr) {   // jshint ignore:line
        var d = dateStr;
        var yyyy = new Date().getFullYear();
        if (!d) {return "01.01." + yyyy;}
        if (d.match(/^\d\d\d\d\d\dd\d$/  )) { d = d.substr(0, 2)        + "." + d.substr(2, 2)  + "." + d.substr(4, 4)     ; } // 01122021
        if (d.match(/^\d\d\d\d\d\d$/     )) { d = d.substr(0, 2)        + "." + d.substr(2, 2)  + ".20" + d.substr(4, 2)   ; } // 011221
        if (d.match(/^\d\.\d\.\d\d$/     )) { d = "0" + d.substr(0, 1)  + ".0" + d.substr(2, 1) + ".20" + d.substr(4, 2)   ; } // 1.1.18
        if (d.match(/^\d\d\.\d\.\d\^d$/   )) { d = d.substr(0, 2)        + ".0" + d.substr(3, 1) + ".20" + d.substr(5, 2)   ; } // 12.1.18
        if (d.match(/^\d\.\d\d\.\d\d$/   )) { d = "0" + d.substr(0, 1)  + "." + d.substr(2, 2)  + ".20" + d.substr(5, 2)   ; } // 1.12.18
        if (d.match(/^\d\.\d$/           )) { d = "0" + d.substr(0, 1)  + ".0" + d.substr(2, 1) + "." + yyyy               ; } // 1.1
        if (d.match(/^\d\.\d\.$/         )) { d = "0" + d.substr(0, 1)  + ".0" + d.substr(2, 1) + "." + yyyy               ; } // 1.1.
        if (d.match(/^\d\d\.\d$/         )) { d = d.substr(0, 2)        + ".0" + d.substr(3, 1) + "." + yyyy               ; } // 12.1
        if (d.match(/^\d\d\.\d\.$/       )) { d = d.substr(0, 2)        + ".0" + d.substr(3, 1) + "." + yyyy               ; } // 12.1.
        if (d.match(/^\d\.\d\d$/         )) { d = "0" + d.substr(0, 1)  + "." + d.substr(2, 2)  + "." + yyyy               ; } // 1.12
        if (d.match(/^\d\.\d\d\.$/       )) { d = "0" + d.substr(0, 1)  + "." + d.substr(2, 2)  + "." + yyyy               ; } // 1.12.
        if (d.match(/^\d\d\.\d\d$/       )) { d = d.substr(0, 2)        + "." + d.substr(3, 2)  + "." + yyyy               ; } // 12.12
        if (d.match(/^\d\d\.\d\d\.$/     )) { d = d.substr(0, 2)        + "." + d.substr(3, 2)  + "." + yyyy               ; } // 12.12.

        if (d.match(/^\d\d\.\d\d\.\d\d\d\d$/)) {
          return d;
        }else {
          return "01.01." + yyyy;
        }
    }

    function loadScript(url, callback) {

      var head = document.getElementsByTagName('head')[0];     // Adding the script tag to the head as suggested before
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;

      // Then bind the event to the callback function.
      // There are several events for cross browser compatibility.
      script.onreadystatechange = callback;
      script.onload = callback;

      head.appendChild(script);
    }
  });
})();
