
/* [src] src/reporting-app/ReportingService.js */
(function() {
  "use strict";

  angular.module("ReportingApp").service("ReportingService",

    function(Requests, $http, _, moment, NormalizationService, $q, GetQueryCacheService) {

      var service = this;
      var MISSERFOLG_FACHLICH = 'misserfolg_fachlich';
      var MISSERFOLG_TECHNISCH = 'misserfolg_technisch';
      var TEILERFOLG_FACHLICH = 'teilerfolg_fachlich';
      var ERFOLG = 'erfolg';
      var LAUFZEIT_MIN = 'laufzeitMin';
      var LAUFZEIT_MAX = 'laufzeitMax';
      var LAUFZEIT_AVG = 'laufzeitAvg';
      var LAUFZEIT_MED = 'laufzeitMed';

      service.loadElstamStats = loadElstamStats;
      service.loadLStBStats = loadLStBStats;
      service.loadLStAStats = loadLStAStats;
      service.loadAagStats = loadAagStats;
      service.loadGkvMmStats = loadGkvMmStats;
      service.loadLndAbfrageStats = loadLndAbfrageStats;
      service.loadLndNachweisStats = loadLndNachweisStats;
      service.loadUVJahresmeldungStats = loadUVJahresmeldungStats;
      service.loadSofortmeldungStats = loadSofortmeldungStats;
      service.loadSVMeldungStats = loadSVMeldungStats;
      service.loadBNachwStats = loadBNachwStats;
      service.loadBeitragserhebungStats = loadBeitragserhebungStats;
      service.loadBetriebsdatenStats = loadBetriebsdatenStats;
      service.loadVsnrAbfrageStats = loadVsnrAbfrageStats;

      function _buildStats(data, name, from, to, drilldownFn, reloadFn) {
        var parts = [ ERFOLG, TEILERFOLG_FACHLICH, MISSERFOLG_FACHLICH, MISSERFOLG_TECHNISCH, LAUFZEIT_MIN, LAUFZEIT_MAX, LAUFZEIT_AVG, LAUFZEIT_MED];
        var result = {
          from: moment(from, "YYYYMMDD").format("DD.MM.YYYY"),
          to: moment(to, "YYYYMMDD").format("DD.MM.YYYY"),
          drilldownFn: drilldownFn,
          reloadFn: reloadFn,
          name: name,
          anzahl: _toCount(data.total && _.last(data.total.values)),
          loading: false
        };
        _.forEach(parts, function(part) { _buildStatsForPart(data, part, result); });
        return result;
      }

      function _buildStatsForPart(data, part, result) {
        var partData = data[part] || { values: [] };
        var partDataPct = data[part + "_pct"] || { values: [] };
        result[part + "_raw"] = _.last(partData.values);
        result[part + "_data"] = _.last(partData.valuesBasedOn);
        result[part + "_pct"] =  _toPercent(_.last(partDataPct.values));
        result[part + "_link"] = true;
      }
      
      function buildLoadStatsFn(reportRequest, changeCodeRequest, name, errorTitle, from, to, interval, stats) {

        stats.data = {
          from: null,
          to: null,
          drilldownFn: function() {},
          reloadFn: function() {},
          name: name,
          anzahl: 0,
          loading: true
        };
        return function() {
          return GetQueryCacheService
            .getCached(reportRequest, { params: { from: from, to: to, interval: interval }})
            .then(
              function(response) {
                stats.data = _buildStats(response.data, name, from, to, _buildDrilldownFn(changeCodeRequest, errorTitle),
                  function() { buildLoadStatsFn(reportRequest, changeCodeRequest, name, errorTitle, from, to, interval, stats)(); });
              }, function(error) {
                stats.data.failure = "Vom Server gemeldeter Fehler: " + error.status + " " + error.statusText;
              });
        };
      }

      function loadElstamStats(from, to, interval, elstamStats) {
        return buildLoadStatsFn(Requests.elstam, Requests.elstam_change_code, "ELStAM", "Verfahrenshinweis/ Fehlercode", from, to, interval, elstamStats);
      }

      function loadLStBStats(from, to, interval, lStBStats) {
        return buildLoadStatsFn(Requests.lstb, Requests.lstb_change_code, "LStB", "Fehlercode", from, to, interval, lStBStats);
      }

      function loadLStAStats(from, to, interval, lStAStats) {
        return buildLoadStatsFn(Requests.lsta, Requests.lsta_change_code, "Lohnsteueranmeldung", "Fehlercode", from, to, interval, lStAStats);
      }

      function loadAagStats(from, to, interval, aagStats) {
        return buildLoadStatsFn(Requests.aag, Requests.aag_change_code, "AAG", "Abweichungsgrund/ Fehlercode", from, to, interval, aagStats);
      }

      function loadGkvMmStats(from, to, interval, gkvmmStats) {
        return buildLoadStatsFn(Requests.gkvmm, Requests.gkvmm_change_code, "GKV Monatsmeldungen", "Bestandsfehler", from, to, interval, gkvmmStats);
      }

      function loadLndAbfrageStats(from, to, interval, lndAbfrageStats) {
        return buildLoadStatsFn(Requests.lndabfrage, Requests.lndabfrage_change_code, "UV Stammdatenabfrage", "Bestandsfehler", from, to, interval, lndAbfrageStats);
      }

      function loadLndNachweisStats(from, to, interval, lndNachweisStats) {
        return buildLoadStatsFn(Requests.lndnachweis, Requests.lndnachweis_change_code, "UV Lohnnachweis", "Bestandsfehler", from, to, interval, lndNachweisStats);
      }

      function loadUVJahresmeldungStats(from, to, interval, uvjmStats) {
        return buildLoadStatsFn(Requests.uvjm, Requests.uvjm_change_code, "UV Jahresmeldung", "Bestandsfehler", from, to, interval, uvjmStats);
      }

      function loadSofortmeldungStats(from, to, interval, smStats) {
        return buildLoadStatsFn(Requests.sofortmeldung, Requests.sofortmeldnug_change_code, "Sofortmeldung", "Bestandsfehler", from, to, interval, smStats);
      }

      function loadSVMeldungStats(from, to, interval, svmStats) {
        return buildLoadStatsFn(Requests.svmeldung, Requests.svmeldung_change_code, "SV-Meldung", "Bestandsfehler", from, to, interval, svmStats);
      }

      function loadBNachwStats(from, to, interval, bnStats) {
        return buildLoadStatsFn(Requests.beitragsnachweis, Requests.beitragsnachweis_change_code, "Beitragsnachweis", "Bestandsfehler", from, to, interval, bnStats);
      }

      function loadBeitragserhebungStats(from, to, interval, beStats) {
        return buildLoadStatsFn(Requests.beitragserhebung, Requests.beitragserhebung_change_code, "Beitragserhebung", "Bestandsfehler", from, to, interval, beStats);
      }

      function loadBetriebsdatenStats(from, to, interval, beStats) {
        return buildLoadStatsFn(Requests.betriebsdaten, Requests.betriebsdaten_change_code, "Betriebsdaten", "Bestandsfehler", from, to, interval, beStats);
      }

      function loadVsnrAbfrageStats(from, to, interval, beStats) {
        return buildLoadStatsFn(Requests.vsnrabfrage, Requests.vsnrabfrage_change_code, "Versicherungsnummern-Abfrage", "Bestandsfehler", from, to, interval, beStats);
      }

      function _changeCode(request, code, newStatus) {
        $http
          .put(request + encodeURIComponent(code), newStatus);
        GetQueryCacheService.clearCache();
      }

      function _toPercent(val) {
        return NormalizationService.normalizedPercentStringOf(val) || "--";
      }

      function _toCount(val) {
        return val ? val : 0;
      }

      function _trimTo(textIn, maxLen) {
        if (!textIn) {
          return "";
        }
        var text = "" + textIn;
        if (text.length > maxLen) {
          text = text.substring(0, maxLen - 3) + "...";
        }
        return text;
      }

      function _getDataByType(data, type) {
        switch (type) {
          case MISSERFOLG_FACHLICH:
            return data.misserfolg_fachlich_data;
          case MISSERFOLG_TECHNISCH:
            return data.misserfolg_technisch_data;
          case TEILERFOLG_FACHLICH:
            return data.teilerfolg_fachlich_data;
          case ERFOLG:
            return data.erfolg_data;
          default:
            throw "Unbekannter Typ: " + type;
        }
      }

      function _getTitleByType(data, type) {
        switch (type) {
          case ERFOLG:
            return "Erfolgreiche ";
          case MISSERFOLG_TECHNISCH:
            return "Techn. nicht erfolgreiche ";
          case TEILERFOLG_FACHLICH:
            return "Fachl. teilw. erfolgreiche ";
          case MISSERFOLG_FACHLICH:
            return "Fachl. nicht erfolgreiche ";
          default:
            throw "Unbekannter Typ: " + type;
        }
      }

      function _buildDrilldownFn(changeCodeRequest, errorCodeColumnTitle) {
        return function(allData, type) {
          var drillDown = {};
          var title = _getTitleByType(allData, type);
          var drilldownData = _getDataByType(allData, type);
          var showColumn2 = type !== MISSERFOLG_TECHNISCH;
          var elementsByCode = _buildElementsByCode(allData, drilldownData);
          elementsByCode = _.sortBy(elementsByCode, function(item) { return -item.items.length; });
          drillDown.title = title + " Meldungen (" + allData.from + "-" + allData.to + ")";
          drillDown.caption1 = errorCodeColumnTitle;
          drillDown.caption2 = showColumn2 ? "Erläuterung" : null;
          drillDown.caption3 = "Anzahl (%)";
          drillDown.tooltip3 = "Die Prozentzahl bezieht sich auf die Zahl aller Meldungen in diesem Zeitraum.";
          drillDown.classification = type;
          drillDown.classificationCanBeChanged = false;
          drillDown.reloadAfter = { };
          drillDown.elements = _.map(elementsByCode, function(data, name) {
            var canBeChanged = (data.code && ("" + data.code).length > 0) && type !== MISSERFOLG_TECHNISCH;
            var code = _trimTo(data.code || "<Ohne>", 80);
            var label = _trimTo(data.label, 160);
            drillDown.classificationCanBeChanged = drillDown.classificationCanBeChanged || canBeChanged;
            return {
              data1: code,
              tooltip1: data.code,
              data2: label,
              data3: data.items.length + " (" + _toPercent((data.items.length / data.total)*100.0) + ")",
              data3_link: _getDrilldownLink(data),
              classificationCanBeChanged: canBeChanged,
              classification: type,
              changedClassification: function(e) {
                _changeCode(changeCodeRequest, code, e.classification);
                drillDown.reloadAfter[allData.name] = allData.reloadFn;
              }
            };
          });
          
          return drillDown;
        };
      }
      
      function _getDrilldownLink(drilldownItem) {
        if (drilldownItem.linkToRm) {
          return "/#datenliste?table=IDS&id=" + drilldownItem.rmIds.substr(0, drilldownItem.rmIds.length - 1);
        } else {
          return "/#datenliste?table=QLDS&id=" + drilldownItem.ids.substr(0, drilldownItem.ids.length - 1);
        }
      }

      function _buildElementsByCode(allData, drilldownData) {

        var elementsByCode = {};

        _.forEach(drilldownData, function(d) {
          var item = elementsByCode[d.code] = elementsByCode[d.code] || {
            code: d.code,
            label: d.text,
            total: allData.anzahl,
            ids: "",
            rmIds: "",
            items: [],
            linkToRm: true
          };
          item.items.push({
            id: d.datensatzId,
            rmId: d.rmId,
            link: d.datensatzLink
          });
          item.ids += d.datensatzId + ",";
          item.rmIds += d.rmId ? (d.rmId + ",") : "";
          item.linkToRm = item.linkToRm && !!d.rmId;
        });
        return elementsByCode;
      }

      return service;
    }
  );

})();
