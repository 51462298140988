
/* [src] src/support-app/Requests.js */
(function (angular) {
  'use strict';

  angular
    .module("MeldecenterApp")
    .constant("Requests", {
      
        // ELStAM-Abmeldungsservice-Controller
      "elstamLoadAbmeldungen": "api/intern/service/abmeldungsservice/loadAbmeldungen",
      "elstamLoadArbeitnehmer": "api/intern/service/abmeldungsservice/loadArbeitnehmer",
      "elstamErzeugeAbmeldung": "api/intern/service/abmeldungsservice/erzeugeAbmeldung",
      "elstamEntferneAbmeldung": "api/intern/service/abmeldungsservice/entferneAbmeldung",

      // Config-Controller
      "configOtfProperty": "api/intern/service/advanced/otfproperty",
      "configGetIntern": "api/intern/configIntern",
      "configCleanDb": "api/intern/testsystem/reset",
      "configCleanDbDfn": "api/intern/testsystem/resetdfn",

      // Daten-Details-Controller
      "datenDetailsStatus": "api/intern/daten",
      "datenDetailsBereitgestelltFuer": "api/intern/daten/bereitgestelltfuer",
      "datenDetailsRaw": "api/intern/daten/rawDaten",
      "datenDetailsIsf": "api/intern/daten/isf",
      "rmdName": "api/intern/daten/rmd/name",
      "rmdAssign": "api/intern/daten/rmd/assign",
      "hinAssign": "api/intern/daten/hin/assign",

      // Daten-List-Controller
      "datenListStruktur": "api/intern/daten/struktur",
      "datenListQuery": "api/intern/daten/query",
      "datenListCounts": "api/intern/daten/counts",

      // ELStAM-Fehlercodes-Controller
      "elstamFehlercodesLadeMonate": "api/intern/daten/elstamfehlercodes/monate",
      "elstamFehlercodesLadeCodes": "api/intern/daten/elstamfehlercodes/monat",

      // ISM-Detail-Controller
      "ismDetail": "api/intern/ism/info" ,
      "ismDetailDaten": "api/intern/ism/daten",

      // ISM-List-Controller
      "ismQuery": "api/intern/ism/query",

      // AAG-Controller
      "aagDatensaetzePerMonat":"api/intern/aag",

      // Lohnnachweis-Controller
      "lohnnachweisLadeBestandsfehler": "api/intern/service/lohnnachweis/bestandsfehler",

      // Mandanten-Detail-Controller
      "mandantLoadByMimId": function (mimId) { return "api/intern/mandanten/" + mimId; },
      "mandantLoadMeldedatenByMimId": function (mimId) { return "api/intern/mandanten/" + mimId + "/meldedaten"; },

      // Main-Controller
      "login": "login",
      "config": "config",

      // Mock-Controller
      "mockSignOfLife": "api/intern/mock/signoflife",
      "mockData": "api/intern/mock/data",
      "mockResetLogs": "api/intern/mock/logs/reset",
      "mockGetLogs": "api/intern/mock/logs",

      // Mock-Änderungslisten-Controller
      "mockListVerarbeitungshinweise": "api/intern/service/mockmonatslistenservice/hinweise",

      // Verarbeitungs-Controller
      "verarbeitungslaufValidate": "api/intern/verarbeitung/laeufe/validate",
      "verarbeitungslaufSave": "api/intern/verarbeitung/laeufe/speichere",
      "verarbeitungslaufLoadLauf": "api/intern/verarbeitung/laeufe",
      "verarbeitungslaufStart": "api/intern/verarbeitung/starte",
      "verarbeitungslaufDelete": "api/intern/verarbeitung/laeufe/loesche",
      "verarbeitungslaufLoadDaten": "api/intern/verarbeitung/daten",

      // SQL-Query-Controller
      "sqlQuery": "api/intern/service/sqlquery",

      // Zertifikate-Controller
      "certificatesLoad": "api/intern/certificate/info",

      // Krankenkassen-Stammdatenabruf
      "krankenkassenUebersicht": "api/v1/stammdaten/krankenkassendaten",

      // ActivityLog
      "activitylogAll": "api/intern/activitylog/all",
      "activitylogByMetadata": "api/intern/activitylog/bymetadata"
    });
})(window.angular);
