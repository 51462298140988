
/* [src] src/reporting-app/DateParserDirective.js */
(function() {
  "use strict";

  angular.module("ReportingApp").directive('dateParser', function (moment) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, element, attrs, ctrl) {
        if (!ctrl) {
          return;
        }

        ctrl.$parsers.unshift(function (data) {
          // convert data from view format to model format
          var val = moment(data);
          if (val.isValid()) {
            return val.toDate();
          }
          return null;
        });
      }
    };
  });
})();
