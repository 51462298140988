
/* [src] src/support-app/VerarbeitungsController.js */
(function () {
  "use strict";

  angular.module("MeldecenterApp").controller("VerarbeitungsController", function ($scope, _http, Requests, ToolsService) {
    $scope.setTitle("Verarbeitung");
    
    var vm = this;
    
    // ***********************************************************************************************************************
    
    vm.data = {
      typen: [],
      typeWithoutMinCount: 0,
      schritte:[],
      lauefe: [],
      invalid: []
    };
    
    vm.startLauf = function (lauf) {
      _http.post(Requests.verarbeitungslaufStart, {name: lauf}, "Lauf gestartet", "Fehler beim Starten des Laufes");
    };
    
     vm.loadDaten = function () {
      _http.get(Requests.verarbeitungslaufLoadDaten, function (res) {
        vm.data = res;
        vm.data.typeWithoutMinCount = Math.trunc(vm.data.typen.length / 2);
      }, "Fehler beim Laden ");
    };
    
    vm.loadDaten();

    vm.disable3Seconds = function (event) {
      var $button = $(event.currentTarget);
      $button.prop('disabled', true);
      setTimeout(function () {
        $button.prop('disabled', false);
      }, 3000);
    };

    

    
    // ***********************************************************************************************************************
    // ***** EDIT
    
    vm.edit = null; // name, inhalt, matrix
    
    vm.neu = function(){
      vm.edit = {
        name: "Neuer Lauf",
        inhalt: "",
        matrix: []
      };
      vm.resetMatrix();
    };
    
    vm.startEdit = function(lauf){
      _http.get(Requests.verarbeitungslaufLoadLauf + "/" + lauf,
        function (lauf) {
          vm.edit = {
            name: lauf.name,
            inhalt: lauf.inhalt.replace(/_NL_/g, "\n"),
            matrix: []
          };
          vm.readCode();
        }, "Lauf kann nicht geladen werden");
    };
    
    vm.validiere = function () {
      _http.postPlain(Requests.verarbeitungslaufValidate, {
        name: vm.edit.name,
        inhalt: vm.edit.inhalt.replace(/\n/g, "_NL_")
      }, function () {
        ToolsService.showToast("Lauf erfolgreich validiert", "info");
      }, function (e) {
        ToolsService.showToast("Fehler beim Validieren des Laufes: " + e, "error");
      });
    };

    vm.speichere = function () {
      _http.postPlain(Requests.verarbeitungslaufSave,
        {name: vm.edit.name, inhalt: vm.edit.inhalt.replace(/\n/g, "_NL_")}, function () {
          vm.loadDaten();
          ToolsService.showToast("Lauf gespeichert", "info");
        }, function (e) {
          ToolsService.showToast("Fehler beim Speichern des Laufes: " + e, "error");
        });
    };
    
    vm.loesche = function () {
      if(!confirm("Soll der Verarbeitungslauf '" + vm.edit.name + "' wirklich geloescht werden?")) { return; }
      
      _http.post(Requests.verarbeitungslaufDelete, {name: vm.edit.name}, function () {
        ToolsService.showToast("Lauf erfolgreich gelöscht", "info");
        vm.edit = null;
        vm.loadDaten();
      }, "Fehler beim Löschen des Laufes");
    };
    
    vm.hovered = {
      schritt: "",
      type: ""
    };
    vm.hoverTable = function(schritt, type){
      vm.hovered.schritt = schritt[0];
      vm.hovered.type = type;
    };

    // ***********************************************************************************************************************
    // ***** CODE <-> Matrix
    vm.resetMatrix = function () {
      vm.data.schritte.forEach(function (schritt) {
        vm.edit.matrix[schritt] = [];
        vm.data.typen.forEach(function (type) {
          vm.edit.matrix[schritt][type] = false;
        });
      });
    };
    
    vm.readCode = function () {
      var lines = vm.edit.inhalt.split(/[\r\n]+/g);

      vm.resetMatrix();

      lines.forEach(function (line) {

        var withoutParts = line.split("|WITHOUT|");
        var forParts = line.split("|FOR|");
        var lineSchritt = line;
        var partType = 0; // 0 - all, 1 - without, 2 - for
        var parts = [];
        if (withoutParts.length > 1) {
          lineSchritt = withoutParts[0];
          parts = withoutParts[1].split(",");
          partType = 1;
        } else if (forParts.length > 1) {
          lineSchritt = forParts[0];
          parts = forParts[1].split(",");
          partType = 2;
        }

        vm.data.schritte.forEach(function (schritt) {
          if (lineSchritt === schritt[0]) {
            vm.data.typen.forEach(function (type) {
              var target;
              if (partType === 0) {
                target = true;
              } else if (partType === 1) {
                target = parts.indexOf(type) === -1;
              } else if (partType === 2) {
                target = parts.indexOf(type) > -1;
              }

              vm.edit.matrix[schritt][type] = target;
            });
          }
        });
      });
    };
    
    

    

    vm.toggleMatrix = function (schrittToToggle, typeToToggle) {
      if (!schrittToToggle && !typeToToggle) {
        return;
      }
      if (schrittToToggle && typeToToggle) {
        vm.edit.matrix[schrittToToggle][typeToToggle] = !vm.edit.matrix[schrittToToggle][typeToToggle];
      } else {
        // ***** check if all relevant fields are selected
        var allTrue = true;
        vm.data.schritte.forEach(function (schritt) {
          if (!schrittToToggle || (schrittToToggle && schritt === schrittToToggle)) {
            vm.data.typen.forEach(function (type) {
              if (!typeToToggle || (typeToToggle && type === typeToToggle)) {
                allTrue = allTrue && vm.edit.matrix[schritt][type];
              }
            });
          }
        });

        // ***** toggle all relevant fields
        vm.data.schritte.forEach(function (schritt) {
          if (!schrittToToggle || (schrittToToggle && schritt === schrittToToggle)) {
            vm.data.typen.forEach(function (type) {
              if (!typeToToggle || (typeToToggle && type === typeToToggle)) {
                vm.edit.matrix[schritt][type] = !allTrue;
              }
            });
          }
        });
      }

      vm.writeCode();
    };

    vm.selectAll = function (target) {
      vm.data.schritte.forEach(function (schritt) {
        vm.data.typen.forEach(function (type) {
          vm.edit.matrix[schritt][type] = target;
        });
      });

      vm.writeCode();
    };

    vm.writeCode = function () {
      vm.edit.inhalt = "";

      vm.data.schritte.forEach(function (schritt) {
        var typesUsed = [];
        var typesUnused = [];
        vm.data.typen.forEach(function (type) {
          if (vm.edit.matrix[schritt][type]) {
            typesUsed.push(type);
          } else {
            typesUnused.push(type);
          }
        });

        if (typesUsed.length === 0) {
          // nothing
        } else {
          vm.edit.inhalt += schritt[0];

          if (typesUnused.length === 0) {
            //nothing
          } else {
            if (typesUsed.length > vm.data.typeWithoutMinCount) {
              vm.edit.inhalt += "|WITHOUT|" + typesUnused.join(",");
            } else {
              vm.edit.inhalt += "|FOR|" + typesUsed.join(",");
            }
          }
          vm.edit.inhalt += "\n";
        }
      });
    };

   

   
    
    return vm;
  });
})();
