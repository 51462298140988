
/* [src] src/support-app/ActivityLogController.js */
(function () {
  "use strict";

  angular.module("MeldecenterApp").controller("ActivityLogController", function ($scope, Requests, _http, _) {
    var vm = this;
    vm.entries = [];
    vm.sucheNach = 'ALL';
    vm.suchText = '';
    vm.update = update;
    vm.hasInhalt = hasInhalt;

    var formatter = new window.vkbeautify();

    _getAllActivityLogEntries();

    function update() {
      if (vm.sucheNach === 'ALL') {
        _getAllActivityLogEntries();
      } else if (vm.sucheNach === 'ISM') {
        _getActivityLogEntriesByMetadata(vm.suchText);
      } else {
        alert('Typ noch nicht implementiert');
      }
    }

    function hasInhalt(which, entry) {
      var inhalt = (which === 'alt') ? entry.inhaltAltJson : entry.inhaltNeuJson;
      return inhalt && inhalt !== '{}';
    }

    function _getActivityLogEntriesByMetadata(metadata) {
      _http.post(Requests.activitylogByMetadata, {
        params: {metadata: metadata}
      }, function (data) {
        vm.entries = data;
        _formatEntries(vm.entries);
      });
    }

    function _getAllActivityLogEntries() {
      _http.get(Requests.activitylogAll, function (data) {
        vm.entries = data;
        _formatEntries(vm.entries);
      });
    }

    function _formatEntries(entries) {
      _.forEach(entries, function (entry) {
        entry.action = entry.action.replace("\n", "<br />");
        entry.inhaltAltJson = formatter.json(JSON.stringify(entry.inhaltAlt));
        entry.inhaltNeuJson = formatter.json(JSON.stringify(entry.inhaltNeu));
      });
    }
  });
})();
