
/* [src] src/support-app/FiBuController.js */
(function () {
  "use strict";

  angular.module("MeldecenterApp").controller("FiBuController", function ($scope, ToolsService, _http, $http, Requests, $timeout) {
    $scope.setTitle("FiBu");
    
    var vm = this;
    
    vm.data = [];
    vm.rdyForExport = 0;
    
    vm.edit = null;
    vm.editOriginal = "";
    vm.editFehler = "";
    vm.editFehlerJson = "";
    
    vm.filter = ToolsService.qs("id") ? "Bereit_fuer_Export" : "Fehlerhaft";
    $scope.$watch(function(){ return vm.filter;}, function(){ load(); });
    
    vm.currentDate = function(){
      var two = function(x){ return x.toString().length === 1 ? "0" + x : x; };
      var date=new Date();
      return     date.getFullYear()   + "-" +
             two(date.getMonth() + 1) + "-" +
             two(date.getDate()     ) + "_" +
             two(date.getHours()    ) + "-" +
             two(date.getMinutes()  );
    };
    
    // ***************************************************************************************************
    // ***** business
    
    vm.loeschen = function(fb){
      if(!confirm("Soll diese Buchung wirklich gelöscht werden?")){ return; }
      
      _http.post("api/intern/crm/rechnung/fibu/" + fb.id + "/delete", function(){
        ToolsService.showToast("Buchung gelöscht", "info");
        load();
      });
    };
    
    vm.neu = function(){
      _http.post("api/intern/crm/rechnung/fibu/neu", function(edit){
        load();
        vm.edit = edit;
      });
    };
    
    vm.loadFB = function(fbId){
      _http.get("api/intern/crm/rechnung/fibu/"+fbId, function(edit){
        vm.edit = edit;
        vm.editOriginal = angular.toJson(vm.edit);
      });
    };
    
    vm.saveFB = function(){
      $http({method: "POST", url: "api/intern/crm/rechnung/fibu/"+vm.edit.id,
        data: vm.edit,
        headers: {'Content-Type': "application/json; charset=UTF-8"},
        transformResponse: [function (data) {return data;}]
      }).then(function (response) {
        ToolsService.showToast("Änderungen gespeichert", "info");
        vm.loadFB(vm.edit.id);
        vm.editFehler = "";
        vm.editFehlerJson = "";
        load();
      }, function (response) {
        console.log(response);
        vm.editFehlerJson = angular.toJson(vm.edit);
        vm.editFehler = response.data;
      });
    };
    
    vm.neuFBT = function(){
      return {konto:0,belegart:"",betrag:"0,00",stbetrag:"0,00",beleg:"",txt:""};
    };
    
    vm.hasChanged = function(){
      return angular.toJson(vm.edit) !== vm.editOriginal;
    };
    vm.hasChangedAfterFehler = function(){
      return angular.toJson(vm.edit) !== vm.editFehlerJson;
    };
    
    
    // ***************************************************************************************************
    // ***** laden
    
    var load = function(){
      _http.get("/api/intern/crm/rechnung/fibu?filter=" + vm.filter, function (data) {
        vm.data = data;
      });
      _http.get("/api/intern/crm/rechnung/fibu/anzahl_markiert_fuer_export", function (data) {
        vm.rdyForExport = data;
      });
    };
    
    vm.loadWithSmallDelay = function(){
      setTimeout(load, 2500);
    };
    
    
    vm.konten = [];
    _http.get("api/intern/crm/rechnung/fibu/konten", function(konten){
      vm.konten = konten;
    });
    
    
    if(ToolsService.qs("id")) {
      vm.loadFB(ToolsService.qs("id"));
      window.location.href = window.location.href.replace(/\?id=.*/, "");
    }
    
    
    return vm;
  });
})();
