
/* [src] src/reporting-app/UseRemainingVSpaceDirective.js */
(function() {
  "use strict";
  
  angular.module("ReportingApp").directive('useRemainingVSpace', function($timeout) {

    function resize(scope, element, oldSize) {
      if (element.height() !== oldSize) {
        var additionalMargin = parseInt(scope.margin || "0");
        var bodyHeight = $("body").innerHeight();
        var yPosition = $(element).offset().top;
        var remaining = bodyHeight - yPosition - additionalMargin;
        element[0].style.setProperty('max-height', remaining + 'px', 'important');
      }
      return element.height();
    }

    return {
      restrict: 'A',
      replace: true,
      scope: {
        margin: "@margin"
      },
      link: function (scope, element, attrs) {
        
        var oldSize = -1;
        
        function refresh() {
          oldSize = resize(scope, element, oldSize);
        }

        // MutationObserver doesn't work in this case on chrome (couldn't figure out why)
        refresh();
        scope.$watch(function() { return element.height(); }, refresh );
        window.setInterval(refresh, 100);
        window.addEventListener('resize', refresh);
      }
    };
  });
})();