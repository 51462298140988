
/* [src] src/reporting-app/NormalizationService.js */

(function() {
  "use strict";

  angular.module("ReportingApp").service("NormalizationService",

    function(moment) {

      var service = this;
      service.normalizeDate = normalizeDate;
      service.normalizedPercentStringOf = normalizedPercentStringOf;

      function normalizeDate(localDate) {
        var obj = {
          year: 0,
          month: 0,
          day: 0,
          hour: 0,
          minute: 0,
          second: 0
        };
        obj.year = localDate.length > 0 ? localDate[0] : obj.year;
        obj.month = localDate.length > 1 ? localDate[1] - 1 : obj.month;
        obj.day = localDate.length > 2 ? localDate[2]: obj.day;
        obj.hour = localDate.length > 3 ? localDate[3] : obj.hour;
        obj.minute = localDate.length > 4 ? localDate[4] : obj.minute;
        obj.second = localDate.length > 5 ? localDate[5] : obj.second;
        return moment(obj);
      }
      
      function normalizedPercentStringOf(val) {
        var asFloat = parseFloat(val);
        return !isNaN(asFloat) ? (val.toFixed(2).toLocaleString(null, { minimumFractionDigits: 2, maximumFractionDigits: 2}) + "%") : null;
      }
      
      return this;
    }
  );

})();
