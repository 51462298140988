
/* [src] src/support-app/ElstamAbholungsController.js */
(function () {
  "use strict";

  angular.module("MeldecenterApp").controller("ElstamAbholungController", function ($scope, _http, ToolsService, Requests) {
    $scope.setTitle("Elstam Änderungslisten manuell abholen");
    
    var vm = this;
    
    
    vm.path = "...";
    vm.filter = {
      max: 20,
      neu: false,
      von: "",
      bis: ""
    };
    vm.res = {};
    vm.loading = false;
    
    _http.get("api/intern/service/elstam-abholungsservice/path", function(data){
      vm.path = data.path;
    });
    
    vm.starteAbfrage = function(){
      vm.res = {};
    
      if(vm.filter.von && !vm.filter.von.match(/^\d\d\.\d\d\.\d\d\d\d$/)){
        alert("Das 'von' Datum hat nicht das korrekte Format.");
        return;
      }
      if(vm.filter.bis && !vm.filter.bis.match(/^\d\d\.\d\d\.\d\d\d\d$/)){
        alert("Das 'bis' Datum hat nicht das korrekte Format.");
        return;
      }
      
      vm.loading = true;
      
      _http.post("api/intern/service/elstam-abholungsservice", {
        max: vm.filter.max,
        neu: vm.filter.neu,
        von: vm.filter.von,
        bis: vm.filter.bis
      }, function(res){
        vm.res = res;
        vm.loading = false;
      });
      
    };
    
    return vm;
  });
})();
