
/* [src] src/support-app/DatenDetailsController.js */
(function () {
  "use strict";

  angular.module("MeldecenterApp")
    .controller("DatenDetailsController", function (ToolsService, $scope, _http, Requests, ClipboardService) {
      var formatter = new window.vkbeautify();

      var vm = this;

      vm.isAdmin = function () {
        return $scope.userHasRole('ADMIN');
      };
      vm.isQL = function () {
        return $scope.config.qlmode;
      };

      // ******************************************************************************************************************
      // ******************************************************************************************************************
      // ***** Meta-Daten
      vm.meta = {};

      var loadMeta = function () {
        vm.transferInfo = "";
        var table = ToolsService.qs('table');
        var id = ToolsService.qs('id');

        $scope.setTitle("Datendetails - " + $scope.shortName(table) + " " + id);


        _http.get("api/intern/daten/" + table + "/" + id + "/meta", function (meta) {
          vm.meta = meta;
          vm.meta.tableLongName = $scope.longName(vm.meta.table);

          if (Array.isArray(vm.meta.dsList)) {
            if (vm.meta.dsList.length > 11) {
              const firstTen = vm.meta.dsList.slice(0, 10);
              const rest = vm.meta.dsList.slice(10, vm.meta.dsList.length);
              vm.meta.dsList = firstTen;
              vm.meta.dsListHidden = rest;
            }
          }

          if (Array.isArray(vm.meta.ids)) {
            if (vm.meta.ids.length > 11) {
              const firstTen = vm.meta.ids.slice(0, 10);
              const rest = vm.meta.ids.slice(10, vm.meta.ids.length);
              vm.meta.ids = firstTen;
              vm.meta.idsHidden = rest;
            }
          }

          vm.shortStatusIfRequired();
        });
      };

      // ******************************************************************************************************************
      // ******************************************************************************************************************
      // ***** Status-Handling

      vm.extendDsList = function () {
        vm.meta.dsList.push(...vm.meta.dsListHidden);
        vm.meta.dsListHidden = [];
      };

      vm.extendIds = function () {
        vm.meta.ids.push(...vm.meta.idsHidden);
        vm.meta.idsHidden = [];
      };

      // ***** comment mode
      vm.commentMode = false;
      vm.toggleCommentMode = function (targetStatus) {
        if (!vm.isAdmin()) {
          vm.commentMode = true;
        } else if (typeof targetStatus !== 'undefined') {
          vm.commentMode = targetStatus;
        } else {
          vm.commentMode = !vm.commentMode;
        }
      };
      vm.toggleCommentMode(false);


      // ***** short status text
      vm.shortStatus = false;
      vm.shortStatusIfRequired = function () {
        if (!vm.meta.statusHistory) {
          return;
        }
        vm.meta.statusHistory.forEach(function (entry) {
          var max_length = 150;
          if (vm.shortStatus && entry.message.length > max_length) {
            var i = entry.message.indexOf("\n");
            entry.messageToShow = entry.message.substr(0, (i > 0 && i < max_length ? i : max_length)) + "...";
          } else {
            entry.messageToShow = entry.message;
          }
        });
      };


      // ***** save new status
      vm.messageForNewStatus = "";
      vm.saveStatus = function (status, event) {
        // recreate ?
        var recreate = false;
        if (status && vm.meta.recreateEnabled && status === 'BEREITGESTELLT') {
          if (confirm("Soll der Datensatz erneut zum Abholen bereitgestellt werden?")) {
            recreate = true;
          }
        }

        // als Kommentar speichern
        if (!status) {
          status = vm.meta.status;
        }

        // close Tab after saving
        var closeAfterSave = ToolsService.isRightClick(event);


        _http.post("api/intern/daten", {
          "table": vm.meta.table,
          "id": vm.meta.id,
          "status": status,
          "message": vm.messageForNewStatus,
          "recreate": recreate
        }, function (data) {
          var id = data.id;
          if (closeAfterSave) {
            window.close();           // this close doesn't work if tab wasn't opened by js ... then we simply reload
            setTimeout(function () {    // fallback, falls schließen nicht klappt
              if (id === vm.meta.id) {
                location.reload();
              } else {
                location.href = "#datendetails?table=" + vm.meta.table + "&id=" + id;
              }
            }, 100);
          } else {
            if (id === vm.meta.id) {
              location.reload();
            } else {
              location.href = "#datendetails?table=" + vm.meta.table + "&id=" + id;
            }
          }
        });
      };

      // ******************************************************************************************************************
      // ******************************************************************************************************************
      // ***** Inhalt
      vm.formatData = true;
      vm.inhalt = {
        // isf, raw + isfFormatted, rawFormatted
      };
      vm.editIsf = {enabled: false, text: ""};
      vm.editRaw = {enabled: false, text: ""};

      var loadInhalt = function () {
        var table = ToolsService.qs('table');
        var id = ToolsService.qs('id');

        _http.get("api/intern/daten/" + table + "/" + id + "/inhalt", function (inhalt) {
          vm.inhalt = inhalt;

          vm.inhalt.isfFormatted = format(vm.inhalt.isf);
          vm.inhalt.rawFormatted = format(vm.inhalt.raw);

          vm.editIsf.text = vm.inhalt.isf;
          vm.editRaw.text = vm.inhalt.raw;
        });
      };

      vm.copyInhalt = function () {
        var text = vm.formatData ? vm.inhalt.isfFormatted : vm.inhalt.isf;
        ClipboardService.copyTextToClipboard(text);
        ToolsService.showToast("In Zwischenablage kopiert", "info");
      };

      vm.formatIsfEdit = function () {
        vm.editIsf.text = format(vm.editIsf.text);
      };

      vm.formatRawEdit = function () {
        vm.editRaw.text = format(vm.editRaw.text);
      };

      var format = function (data) {
        if (!data) {
          return;
        }

        try {
          if (data.trim().startsWith("{")) {           // json
            return JSON.stringify(JSON.parse(data), null, 4);
          } else if (data.trim().startsWith("<")) {    // xml
            return formatter.xml(data);
          } else {                                     // else
            return data;
          }
        } catch (exception) {
          ToolsService.showToast('Fehler beim Formatieren', 'error');
          return data;
        }
      };

      vm.svparser = function () {
        location.href = encodeURI("#svparser?dsid=" + vm.meta.id + "&table=" + vm.meta.table);
      };

      vm.toggleEdit = function (typ, cmd) {
        if (!vm.isAdmin()) {
          return;
        }
        if (!$scope.config.editDatensatz) {
          return;
        }

        if (typ === 'raw') {
          if (vm.meta.table !== 'QLDS') {
            return;
          }
          vm.editRaw.enabled = !vm.editRaw.enabled;
          if (cmd === 'cancel') {
            vm.editRaw.text = vm.inhalt.raw;
            ToolsService.showToast('Bearbeiten abgebrochen', 'info');
          } else if (cmd === 'save') {
            _http.post("api/intern/daten/rawDaten", {id: vm.meta.id, rawDaten: vm.editRaw.text}, function () {
              ToolsService.showToast('Raw-Inhalt gespeichert', 'info');
              load();
            }, "Fehler beim Speichern");
          }
        } else if (typ === 'isf') {
          vm.editIsf.enabled = !vm.editIsf.enabled;
          if (cmd === 'cancel') {
            vm.editIsf.text = vm.inhalt.isf;
            ToolsService.showToast('Bearbeiten abgebrochen', 'info');
          } else if (cmd === 'save') {
            _http.post("api/intern/daten/isf", {table: vm.meta.table, id: vm.meta.id, isf: vm.editIsf.text}, function () {
              ToolsService.showToast('Inhalt gespeichert', 'info');
              load();
            }, "Fehler beim Speichern");
          }
        }
      };

      // ******************************************************************************************************************
      // ******************************************************************************************************************
      // ***** Transfer-Infos
      vm.transferInfo = "";

      vm.loadTransferInfos = function () {
        _http.getPlain("api/intern/daten/transferinfos/" + vm.meta.id, function (data) {
          vm.transferInfo = data;
          ClipboardService.copyTextToClipboard(vm.transferInfo);
          ToolsService.showToast("Transferinformationen in Zwischenablage kopiert", "info");
        }, function () {
          vm.transferInfo = "Transfer-Informationen konnten nicht geladen werden!";
          ToolsService.showToast("Transfer-Informationen konnten nicht geladen werden", "error");
        });
      };

      // ******************************************************************************************************************
      // ******************************************************************************************************************
      // ***** Sonstige Aktionen
      vm.newBereitgestelltFuer = "";

      vm.saveBereitgestelltFuer = function (kdnr) {
        _http.post(Requests.datenDetailsBereitgestelltFuer, {
          "id": vm.meta.id,
          "kdnr": kdnr
        }, function () {
          location.reload();
        });
      };

      vm.changeEingeliefertVon = function (kdnr) {
        var kdnrNeu = prompt("Bitte neue Kundennummer eingeben:", vm.meta.kunde.kdnr);
        if (!kdnrNeu || kdnrNeu === vm.meta.kunde.kdnr) {
          alert("Abgebrochen");
          return;
        }
        _http.post("api/intern/daten/eingeliefertvon", {
          "id": vm.meta.id,
          "kdnr": kdnrNeu
        }, function () {
          location.reload();
        });
      };

      vm.changeEinlieferungsdetails = function (kdnr) {
        var detailsNeu = prompt("Bitte die neuen Einlieferungsdetails eingeben:", vm.meta.details);
        if (!detailsNeu || detailsNeu === vm.meta.details) {
          alert("Abgebrochen");
          return;
        }
        _http.post("api/intern/daten/einlieferungsdetails", {
          "id": vm.meta.id,
          "details": detailsNeu
        }, function () {
          location.reload();
        });
      };

      vm.changeEingeliefertFuer = function (kdnr) {
        var fuerNeu = prompt("Bitte die neuen 'Eingeliefert für'-Wert eingeben:", vm.meta.firma);
        if (!fuerNeu || fuerNeu === vm.meta.firma) {
          alert("Abgebrochen");
          return;
        }
        _http.post("api/intern/daten/eingeliefertfuer", {
          "id": vm.meta.id,
          "fuer": fuerNeu
        }, function () {
          location.reload();
        });
      };
      vm.changeHinmeldung = function (kdnr) {
        var currentHinId = "";
        if (vm.meta != null && vm.meta.qlds != null) {
          currentHinId = vm.meta.qlds.id;
        }
        var hinNeu = prompt("Hinmeldung-ID eingeben (leer um Zuordnung zu entfernen)", currentHinId);
        if (hinNeu === null || hinNeu === currentHinId) {
          alert("Abgebrochen");
          return;
        }
        _http.post("api/intern/daten/hin/assign", {
          "idsId": vm.meta.id,
          "qldsId": hinNeu
        }, function () {
          history.go(0); // reload
        });
      };
      vm.createBeaIds = function () {
        _http.post("api/intern/bea/erzeuge-ids-mit-dbfe/" + vm.meta.id, {}, function (response) {
          console.log(response);
          if (response.error) {
            ToolsService.showToast("Fehler: " + response.error, "error");
          } else {
            ToolsService.showToast("Rückmeldung wurde erfolgreich erzeugt: <a href='#datendetails?table=IDS&id=" + response.success + "'>#" + response.success + "</a>", 'info');
            loadMeta();
          }
        });
      };
      
      vm.insertLastStatus = function (event) {
        if(!vm.meta || !vm.meta.statusHistory || vm.meta.statusHistory.length<=0){
          ToolsService.showToast("Es existieren keine Einträge in der Historie.", "error");
          return;
        }
        
        var h = vm.meta.statusHistory;
      
        var index = 0;
        if(ToolsService.isRightClick(event)){
          index = prompt("Welcher Eintrag soll genutzt werden? (1 - " + h.length + ")?", "1") - 1;
        }
        if(index < 0 || index >= h.length){
          return;
        }
        var message = h[index].message;
        if(message){
          var messageWithoutUser = message.replace(/ \([^\)]*\)$/,"");
          vm.messageForNewStatus = messageWithoutUser; 
        }
      };


      // ***** QL Parts
      vm.rmd = {
        id: "",
        name: "",
        buttonEnabled: true,
        fertig: false
      };

      $scope.$watch(function () {
        return vm.rmd.id;
      }, function () {
        vm.updateRmdName();
      });

      vm.updateRmdName = function () {
        if (vm.rmd.id) {
          _http.getPlain(Requests.rmdName + "/" + vm.rmd.id, function (data) {
            vm.rmd.name = data;
          });
        }
      };

      vm.ordneZuRmd = function () {
        vm.rmd.buttonEnabled = false;
        _http.post(Requests.rmdAssign, {
          "idsId": vm.meta.id,
          "rmdId": vm.rmd.id
        }, function () {
          history.go(0); // reload
        });
      };

      vm.setzeQLVPVersanddatum = function () {
        vm.rmd.buttonEnabled = false;
        _http.post("api/intern/daten/qlvp/update-sent-date", {"qlvpId": vm.meta.id}, function () {
          history.go(0); // reload
        });
      };

      vm.hin = {
        id: "",
        buttonEnabled: true
      };

      vm.ordneZuHinmeldung = function () {
        vm.hin.buttonEnabled = false;
        _http.post(Requests.hinAssign, {
          "idsId": vm.meta.id,
          "qldsId": vm.hin.id
        }, function () {
          history.go(0); // reload
        });
      };

      vm.textnachrichtUrl = function () {
        var link = "#textnachricht?ds=" + vm.meta.table + "%20" + vm.meta.id;
        if (vm.meta.ism) {
          var institution = vm.meta.ism.split(';')[0];
          var merkmal = vm.meta.ism.split(';')[1];
          if (institution === 'SV') {
            link += "&bbnr=" + merkmal;
          }
        }
        return link;
      };

      vm.storniereLndAbfrage = function () {
        _http.postPlain("api/intern/service/lohnnachweis/stornieren", {
          "id": vm.meta.id
        }, function (id) {
          ToolsService.showToast("Storno wurde erfolgreich erzeugt: <a href='#datendetails?table=QLDS&id=" + id + "'>#" + id + "</a>", 'info');
          load();
        });
      };

      // ******************************************************************************************************************
      // ******************************************************************************************************************

      $scope.$on('pageUpdated', function (event, page) {
        if (page.indexOf("datendetails") > -1) {
          load();
        }
      });

      var load = function () {
        vm.meta = {};
        vm.inhalt = {};
        loadMeta();
        loadInhalt();
      };

      load();

      return vm;
    });
})();
