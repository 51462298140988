
/* [src] src/support-app/ConfigController.js */
(function () {
  "use strict";

  angular.module("MeldecenterApp").controller("ConfigController", function ($scope, _http, Requests, ToolsService) {
    window.unserScope = $scope;
    $scope.setTitle("Systemkonfiguration");
    
    $scope.data = [];
    $scope.loading = false;
    $scope.otfconfig = [];
    $scope.minVersion = {
      version: ""
    };

    $scope.update = function (c) {
      _http.post(Requests.configOtfProperty, {name: c.name, value: c.curr}, "", "Fehler beim Ändern");
    };

    $scope.load = function () {
      $scope.loading = true;
      _http.get(Requests.configOtfProperty, function (value) {
        $scope.otfconfig = value;
      });

      _http.getPlain("api/intern/min-version", function (value) {
        $scope.minVersion.version = value;
      });

      _http.get(Requests.configGetIntern, function (data) {
        $scope.data = data;
        $scope.loading = false;
      });
    };

    $scope.cleanDb = function () {
      _http.post(Requests.configCleanDb, "Datenbank geleert", "Fehler beim Löschen der Datenbank");
    };

    $scope.cleanDtf = function () {
      _http.post(Requests.configCleanDbDfn, "Dateifolgenummern zurück gesetzt", "Fehler beim Zurücksetzen der Dateifolgenummern");
    };
    
    $scope.cleanValidationErrors = function() {
      _http.post("api/intern/validierung/clearstoredfailures", function(){
        location.reload();
      });
    }; 
    
    $scope.reloadConfigFiles = function() {
      _http.post("api/intern/konfiguration/reload-configuration", function(){
      });
    }; 
    
    $scope.sendeTestEmail = function() {
      _http.post("api/intern/test-email",{ mail: $scope.testEmail }, function(){
        ToolsService.showToast("Test-Email wurde verschickt", "info");
      });
    };

    $scope.updateMinVersion = function() {
      _http.post("api/intern/min-version",{ version: $scope.minVersion.version }, function(){
        ToolsService.showToast("Client-Mindestversion wurde angepasst.", "info");
      });
    };
    
    // ************************************************
    $scope.timeDelta = { isActive: false };
    
    $scope.timeDeltaSet = function(enable){
      var tage = 0; 
      if(enable){
        tage = prompt("Time-Delta in Tagen", $scope.timeDelta.tageBisNeujahr);
        if(!tage){ return; }
      }
       
      _http.post("api/intern/config/time-delta",{ active: enable, tage: tage }, function(data){
        $scope.timeDelta = data;
        $scope.loadConfig();
      }); 
    };
    
    $scope.timeDeltaSet();

    // ************************************************


    $scope.load();
  })
  ;
})();
