
/* [src] src/reporting-app/DurationFilter.js */
(function() {
  "use strict";

  angular.module("ReportingApp").filter('durationSec', function(moment) {

    // HELLO WORLD
    return function(value) {
      if (value) {
        var dur = moment.duration(value);
        return dur.humanize();
      } else {
        return "--";
      }
    };
  });
})();