
/* [src] src/support-app/AAGController.js */
(function () {
  "use strict";

  angular.module("MeldecenterApp").controller("AAGController", function ($scope, Requests, _, AAGConstants, _http) {
    $scope.setTitle("AAG Übersicht");

    var vm = this;
    var updateFehlercodes = true;

    // Fields
    vm.gruende = _.keysIn(AAGConstants.GRUENDE);
    vm.maxMonateBack = 24;
    vm.daten = [];
    vm.currentMonat = 0;
    vm.vonSelection = -1;
    vm.sortedFehlercodes = [];
    vm.bisSelection = -1;
    vm.berechnet = {};
    vm.ladeMonat = "";
    vm.fehlercodes = {};
    vm.showUeberblick = true;
    vm.showFehlercodes = false;
    vm.showDetails = false;

    // Funktionen
    vm.vonAufEins = vonAufEins;
    vm.bisAufEins = bisAufEins;
    vm.getIndex = getIndex;
    vm.toLength = toLength;

    /* "Public" functions */
    function vonAufEins() {
      vm.vonSelection = vm.daten[vm.daten.length - 1].hinId;
    }

    function bisAufEins() {
      vm.bisSelection = vm.daten[0].hinId;
    }

    function getIndex(hinId) {
      return _.findIndex(vm.daten, function (d) {
        return d.hinId === hinId;
      });
    }

    function toLength(str, length) {
      return _.padStart(str, length, " ");
    }

    function _addFehlerCode(date, code) {
      var fc = vm.fehlercodes;
      var yearMonth = date.substring(0, 7);

      //***** scan array if yearMonth exists
      fc[yearMonth] = fc[yearMonth] || {yearMonth: yearMonth, daten: []};

      // ***** inc count
      var daten = fc[yearMonth].daten;
      if (!daten[code]) {
        daten[code] = 0;
      }
      daten[code]++;
    }

    vm.grund = function (i) {
      return _.get(AAGConstants.GRUENDE, i);
    };

    function _calculate(vonIdx, bisIdx) {
      var x = vm.berechnet = {
        anzahl: 0,        // alle markierten Datensätze
        anzahlOffen: 0,   // alle ohne Rückmeldung
        minDauer: 99999,
        maxDauer: 0,
        avgDauer: 0,
        kennf: [],
        gab: [],
        von: "",
        bis: ""
      };
      if (vm.daten.length === 0) {
        return;
      }

      x.bis = vm.daten[vonIdx].hinDate;
      x.von = vm.daten[bisIdx].hinDate;
      var mitRueckRelevantFuerAvg = 0;
      for (; vonIdx <= bisIdx; vonIdx++) {
        var d = vm.daten[vonIdx];

        x.anzahl++;
        if (d.rueckId === 0) {      // ohne Rückmeldung
          x.anzahlOffen++;
        } else {                    // mit Rückmeldung

          if (d.warteTage > 0.2) {  // Antwortzeiten berechnen
            mitRueckRelevantFuerAvg++;
            x.minDauer = d.warteTage < x.minDauer ? d.warteTage : x.minDauer;
            x.maxDauer = d.warteTage > x.maxDauer ? d.warteTage : x.maxDauer;
            x.avgDauer += d.warteTage;
          }

          if (x.kennf[d.kennf] === undefined) {
            x.kennf[d.kennf] = 0;
          }
          x.kennf[d.kennf]++;

          if (x.gab[d.gab] === undefined) {
            x.gab[d.gab] = 0;
          }
          x.gab[d.gab]++;
        }
      }

      x.avgDauer = Math.round(x.avgDauer / mitRueckRelevantFuerAvg * 10) / 10;

      x.kennf = "Vollst. ent: " + (x.kennf[1] || 0) + "  |  tw. ent: " + (x.kennf[2] || 0) + "  |  abgelehnt: " + (x.kennf[3] || 0);

      vm.gabToCount = _.map(x.gab, function (v, idx) {
        return {gab: idx, cnt: v};
      });
    }

    function _ladeMonate() {
      vm.ladeMonat = "(Lade Monat " + vm.currentMonat + ")";

      _http.get(Requests.aagDatensaetzePerMonat + "/" + vm.currentMonat,
        function (data) {
          vm.daten = vm.daten.concat(data);
          if (vm.vonSelection === -1 && data.length !== 0) {
            vm.vonSelection = vm.daten[vm.daten.length - 1].hinId;
            vm.bisSelection = vm.daten[0].hinId;
          }

          data.forEach(function (d) {
            if (d.gab) {
              _addFehlerCode(d.rueckDate, d.gab);
            }
          });

          if (vm.currentMonat < vm.maxMonateBack && vm.daten.length < AAGConstants.MAX_DATEN) {
            vm.currentMonat++;
            _ladeMonate();
          } else {
            vm.ladeMonat = "";
            updateFehlercodes = false;
          }
          $scope.$apply();
        }, "Monat konnte nicht geladen werden");
    }


    $scope.$watch(function () {
      return vm.vonSelection + vm.bisSelection;
    }, function () {
      var von = getIndex(vm.vonSelection);
      var bis = getIndex(vm.bisSelection);
      if (von < bis) {
        _calculate(von, bis);
      } else {
        _calculate(bis, von);
      }
    });

    // Timer setzen, um die sortierte Liste der Fehlercodes für die Darstellung zu aktualisieren
    var timerId = window.setInterval(function () {
      if (updateFehlercodes) {
        vm.sortedFehlercodes = _.orderBy(_.values(vm.fehlercodes), ['yearMonth'], ['desc']);
      } else {
        window.clearInterval(timerId);
      }
    }, 250);

    _ladeMonate();
  });
})();
