
/* [src] src/reporting-app/ClipboardService.js */
(function () {
  "use strict";

  angular.module("ReportingApp").service("ClipboardService", function (_) {

      var service = this;
      service.copyTextToClipboard = copyTextToClipboard;
      service.copyHtmlTableToClipboard = copyHtmlTableToClipboard;

      // Taken from https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
      function copyTextToClipboard(text) {
        var textArea = document.createElement("textarea");

        //
        // *** This styling is an extra step which is likely not required. ***
        //
        // Why is it here? To ensure:
        // 1. the element is able to have focus and selection.
        // 2. if element was to flash render it has minimal visual impact.
        // 3. less flakyness with selection and copying which **might** occur if
        //    the textarea element is not visible.
        //
        // The likelihood is the element won't even render, not even a flash,
        // so some of these are just precautions. However in IE the element
        // is visible whilst the popup box asking the user for permission for
        // the web page to copy to the clipboard.
        //

        // Place in top-left corner of screen regardless of scroll position.
        textArea.style.position = 'fixed';
        textArea.style.top = 0;
        textArea.style.left = 0;

        // Ensure it has a small width and height. Setting to 1px / 1em
        // doesn't work as this gives a negative w/h on some browsers.
        textArea.style.width = '2em';
        textArea.style.height = '2em';

        // We don't need padding, reducing the size if it does flash render.
        textArea.style.padding = 0;

        // Clean up any borders.
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';

        // Avoid flash of white box if rendered for any reason.
        textArea.style.background = 'transparent';


        textArea.value = text;

        document.body.appendChild(textArea);

        textArea.select();

        try {
          var successful = document.execCommand('copy');
          var msg = successful ? 'successful' : 'unsuccessful';
          console.log('Copying text command was ' + msg);
        } catch (err) {
          console.log('Oops, unable to copy');
        }

        document.body.removeChild(textArea);
      }

      function copyHtmlTableToClipboard(element, predicateFnCol, predicateFnRow) {
        var headerData = _findHeaderColumns(element, predicateFnCol);
        var rows = _findRows(element, predicateFnRow, headerData.hiddenIndices);
        var text = "";
        for (var headerIdx = 0; headerIdx < headerData.headers.length; ++headerIdx) {
          text += headerData.headers[headerIdx] + "\t";
        }
        text += "\t\n";
        _.forEach(rows, function (row) {
          _.forEach(row, function (col) {
            text += col + "\t";
          });
          text += "\t\n";
        });
        copyTextToClipboard(text);
      }

      function _findHeaderColumns(element, predicateFnCol) {
        var headers = $(element).find("th");
        var result = [];
        var hiddenIndices = [];
        for (var idx = 0; idx < headers.length; ++idx) {
          var header = headers[idx];
          if (!predicateFnCol || predicateFnCol(header)) {
            result.push(header.innerText);
          } else {
            hiddenIndices.push(idx);
          }
        }
        return {headers: result, hiddenIndices: hiddenIndices};
      }

      function _findRows(element, predicateFnRow, hiddenColIndices) {
        var rows = $(element).find("tr");
        var result = [];
        for (var rowIdx = 0; rowIdx < rows.length; ++rowIdx) {
          var row = $(rows[rowIdx]);
          if (row.find("th").length > 0) {
            continue;
          }
          if (!predicateFnRow || predicateFnRow(row)) {
            var cols = row.find("td");
            var resultCols = [];
            for (var colIdx = 0; colIdx < cols.length; ++colIdx) {
              if (_.indexOf(hiddenColIndices, colIdx) < 0) {
                resultCols.push(cols[colIdx].innerText);
              }
            }
            result.push(resultCols);
          }
        }
        return result;
      }

      return this;
    }
  );
  
  angular.module("MeldecenterApp").service("ClipboardService", function (_) {
      var service = this;
      service.copyTextToClipboard = copyTextToClipboard;

      function copyTextToClipboard(text) {
        var textArea = document.createElement("textarea");

        textArea.style.position = 'fixed';
        textArea.style.top = 0;
        textArea.style.left = 0;
        textArea.style.width = '2em';
        textArea.style.height = '2em';
        textArea.style.padding = 0;
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';
        textArea.style.background = 'transparent';
        textArea.value = text;

        document.body.appendChild(textArea);

        textArea.select();

        try {
          var successful = document.execCommand('copy');
          var msg = successful ? 'successful' : 'unsuccessful';
          console.log('Copying text command was ' + msg);
        } catch (err) {
          console.log('Oops, unable to copy');
        }

        document.body.removeChild(textArea);
      }
      return this;
    }
  );

})();
