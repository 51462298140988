
/* [src] src/support-app/ElstamAbmeldungsserviceController.js */
(function () {
  "use strict";

  angular.module("MeldecenterApp").controller("AbmeldungsserviceController", function ($scope, _http, ToolsService, Requests) {
    $scope.setTitle("Elstam Abmeldungsservice");
    
    var vm = this;
    
    // *****************************************************************
    // allgemein
    
    vm.modus = "firma";
    
    
    // *****************************************************************
    // modus firmen
    vm.firmen = "#Steuer ID,Geburtsdatum,Anmeldung,Abmeldung,Steuernummer Arbeitgeber,Bundesland";
    vm.firmenResult = "";
    
    vm.processFirmen = function(simulateOnly){
      if(!vm.kdnr){
        alert("Es muss eine gültige Kundennummer genutzt werden.");
        return;
      }
      _http.post("/api/intern/service/abmeldungsservice/processFirmenCsv",
        { csv: vm.firmen, simulateOnly: simulateOnly, kdnr: vm.kdnr },
        function (result) {
          vm.firmenResult = result.msg;
          vm.loadVersandteAbmeldungen();
        }, "Fehler beim Laden"
      );
    };
    
    // *****************************************************************
    // modus firma
    
    vm.loadStNr = "";
    var month = (new Date().getMonth() + 1);
    if (month < 10) {
      month = "0" + month;
    }
    var day = new Date().getDate();
    if (day < 10) {
      day = "0" + day;
    }
    vm.loadAbmDat = "" + new Date().getFullYear() + month + day;
    vm.loadDsId = "";
    var prefix = "#    Id    , gebdat , refdat ,abmeldedat\n";
    vm.loadingByStNr = false;
    vm.loadingByDsId = false;
    vm.arbeitnehmer = prefix;
    vm.mdAbrMonat = "" + (new Date().getMonth() + 1) + ("" + new Date().getFullYear()).substring(2);
    vm.mdMandantId = "";
    vm.meldedatei = "";
    vm.meldedateiId = "";
    vm.loadingVersandte = true;
    vm.versandteAbmeldungen = [];

    vm.loadVersandteAbmeldungen = function () {
      vm.loadingVersandte = true;
      _http.get("api/intern/service/abmeldungsservice/loadAbmeldungen", function (result) {
          vm.versandteAbmeldungen = result;
          vm.loadingVersandte = false;
      });
    };

    vm.loadArbeitnehmer = function (way) {
      vm.arbeitnehmer = prefix;
      if (way === "byStNr") {
        vm.loadingByStNr = true;
      } else {
        vm.loadingByDsId = true;
      }
      $("#abmeldungsservice #arbeitnehmer").height("200px");

      _http.post("api/intern/service/abmeldungsservice/loadArbeitnehmer",
        {
          way: way,
          stnr: vm.loadStNr,
          abm: vm.loadAbmDat,
          dsid: vm.loadDsId
        },
        function (result) {
          vm.arbeitnehmer = prefix + result.an;
          vm.stnr = result.stnr;
          vm.kdnr = result.kdnr;
          vm.loadingByStNr = false;
          vm.loadingByDsId = false;
          if (result.abrMonat) {
            vm.mdAbrMonat = result.abrMonat;
          }
        });
    };

    vm.erzeugeAbmeldung = function () {
      if(!vm.kdnr){
        alert("Es muss eine gültige Kundennummer genutzt werden.");
        return;
      }
      vm.loadingVersandte = true;
      _http.post("api/intern/service/abmeldungsservice/erzeugeAbmeldung", {
          an: vm.arbeitnehmer,
          stnr: vm.stnr,
          kdnr: vm.kdnr
        },
        function (result) {
          ToolsService.showToast("Datensatz erfolgreich erstellt: <a href='#datendetails?table=QLDS&id=" + result.dsid + "'>#" + result.dsid + "</a>", "info");
          vm.loadVersandteAbmeldungen();
        });
    };
    
    
    vm.entferneAbmeldung = function (abmeldung) {
      vm.loadingVersandte = true;
      abmeldung.deleted = true;
      _http.post("api/intern/service/abmeldungsservice/entferneAbmeldung", {qldsid: abmeldung.dsid}, function () {
        vm.loadVersandteAbmeldungen();
      });
    };
    
    vm.erneuteAbmeldung = function (abmeldung) {
      vm.loadDsId = abmeldung.dsid;
      vm.loadArbeitnehmer('byDsId');
    };
    
    
    vm.loadVersandteAbmeldungen();
    
    return vm;
  });
})();
