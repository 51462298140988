
/* [src] src/reporting-app/ElstamVHInfoService.js */
(function () {
  "use strict";

  angular.module("ReportingApp").service("ElstamVHInfoService",

    function (Requests, $http, _) {

      var service = this;
      service.vhInfo = null;
      service.getInfoForVH = getInfoForVH;

      _loadVH();

      function getInfoForVH(code) {
        if (!service.vhInfo) {
          throw "Not loaded yet";
        }
        return _.find(service.vhInfo, {'code': code});
      }

      function _loadVH() {
        $http.get(Requests.elstam_vh_info, function (data) {
          service.vhInfo = data;
        });
      }

      return this;
    }
  );

})();
