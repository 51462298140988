
/* [src] src/support-app/SystemEventController.js */
(function () {
  "use strict";

  angular.module("MeldecenterApp").controller("SystemEventController", function ($scope, ToolsService, _http, Requests, $timeout) {
    $scope.setTitle("System-Events");
    
    var vm = this;
    
    vm.since = "??";
    vm.events = [];
    
    
    vm.load = function(){
      _http.get("api/intern/systemevents", function(data){
        vm.since = data.since;
        vm.events = data.events;
        buildCollapsedBody();
        vm.collapse(true);
      });
    };
    
    vm.load();
    
    var buildCollapsedBody = function(){
      vm.events.forEach(function(e){

        var getPosition = function(string, subString, index) {
          return string.split(subString, index).join(subString).length;
        };

        e.bodyC = e.body.substr(0, getPosition(e.body, "\n", 2));
      });
    };
    
    vm.collapse = function(c){
      vm.events.forEach(function(e){
        e.collapsed = c;
      });
    };
    
    vm.filterText = "";
    vm.filter = function(e){
      if(!vm.filterText) { return true; }
      
      var ft = vm.filterText.toUpperCase();
      
      if(e.prio.indexOf(ft) > -1){ return true; }
      if(e.art.indexOf(ft) > -1){ return true; }
      if(e.created.indexOf(ft) > -1){ return true; }
      if(e.titel.toUpperCase().indexOf(ft) > -1){ return true; }
      if(e.body.toUpperCase().indexOf(ft) > -1){ return true; }
      
      return false;
    };
    
    return vm;
  });
})();
