
/* [src] src/support-app/QLTicketController.js */
(function () {
  "use strict";

  angular.module("MeldecenterApp").controller("QLTicketController", function ($scope, ToolsService, _http, Requests, ClipboardService, $http) {
    $scope.setTitle("Tickets");

    var vm = this;

    // **************************************************************************
    // **************************************************************************
    // **************************************************************************
    // ***** HELPER ****
    var stopEvent = function(event){
      event.stopPropagation();
      event.preventDefault();
    };

    var currentDate = function(inclHours){
      var two = function(x){
        return x.toString().length === 1 ? "0" + x : x;
      };

      var date=new Date();

      if(inclHours){
        return two(date.getDate()     ) + "." +
               two(date.getMonth() + 1) + "." +
                   date.getFullYear()   + " " +
               two(date.getHours()    ) + ":" +
               two(date.getMinutes()  ) + ":" +
               two(date.getSeconds()  );
      } else {
        return two(date.getDate()     ) + "." +
               two(date.getMonth() + 1) + "." +
                   date.getFullYear();
      }
    };

    var currentUser = function(){
      return $scope.config.user.name.split(" ")[0];
    };

    vm.zzz = stopEvent;
    vm.zzy = currentDate; 

    // **************************************************************************
    // **************************************************************************
    // **************************************************************************
    // ***** BUSINESS ****
    vm.users = $scope.qlServiceUsers;
    vm.open = null;
    vm.original = "";
    vm.data = [];
    vm.filter = {
      status_offen: true,
      status_geschlossen: false,
      bearbeiter: currentUser(),
      ersteller: 'alle'
    };
    vm.links = [];
    vm.kunde = "";
    $scope.$watch(function(){ return JSON.stringify(vm.filter); }, function(){ load(); });

    vm.setFilter = function(filter){
      var user = currentUser();
      switch(filter){
      case 'my-open':
        vm.filter.status_offen = true;
        vm.filter.status_geschlossen = false;
        vm.filter.ersteller = 'alle';
        vm.filter.bearbeiter = user;
        break;
      case 'all-open':
        vm.filter.status_offen = true;
        vm.filter.status_geschlossen = false;
        vm.filter.ersteller = 'alle';
        vm.filter.bearbeiter = 'alle';
        break;
      case 'created-by-me-open':
        vm.filter.status_offen = true;
        vm.filter.status_geschlossen = false;
        vm.filter.ersteller = user;
        vm.filter.bearbeiter = 'alle';
        break;
      }
    };

    vm.ticketTitle = function(str){
      if(!str){
        return "";
      }
      var part1 = str.split("\n")[0];
      return (part1.length>50)
        ? part1.substr(0,50) + " ..."
        : part1;
    };

    $scope.$watch(function(){ return vm.open ? vm.open.inhalt : null; }, function(){ findLinks(); });

    $scope.addGlobalKeyListener({
      pagename: "tickets",
      handler: function(e){
        if(e.key === "Escape" && vm.open){
          vm.close();
          $scope.$apply();
        }
      }
    });

    var focusInhalt = function(){
      setTimeout(function(){ document.getElementById("ticket-inhalt").focus(); }, 0);
    };

    vm.setOpen = function(t){
      vm.original = angular.toJson(t);
      vm.open = t;
      window.location.replace("#tickets?ticket=" + t.id);
      focusInhalt();
      vm.updateKunde();
    };

    vm.setStatus = function(newStatus){
      vm.open.status = newStatus;
      vm.save();
    };

    vm.close = function(){
      window.location.replace("#tickets");
      vm.open = null;
    };

    vm.cancel = function(){
      if(vm.open.id && vm.original){
        var t = JSON.parse(vm.original);
        vm.open.bearbeiter = t.bearbeiter;
        vm.open.ersteller = t.ersteller;
        vm.open.kunden = t.kunden;
        vm.open.inhalt = t.inhalt;
      }

      vm.close();
    };

    vm.save = function(){
      var o = vm.open;
      _http.post("api/intern/crm/tickets", {
          id: o.id,
          status: o.status,
          inhalt: o.inhalt,
          ersteller: o.ersteller,
          bearbeiter: o.bearbeiter,
          kunde: o.kunde
        }, function(ticket){
          if(vm.open){
            vm.setOpen(ticket);
          }
          var found = false;
          vm.data.forEach(function(t, index){
            if(!found && t.id === ticket.id){
              vm.data[index] = ticket;
              found = true;
            }
          });
          if(!found){
            vm.data.unshift(ticket);
          }

          $scope.loadOpenTickets();
      }, "Fehler beim Speichern des Tickets");
    };

    vm.hasChanged = function(){
      return angular.toJson(vm.open) !== vm.original;
    };
    
    
    var kdNrNameMap = [];
    vm.kd = function(kundennummer){
      if(!kundennummer){ return kundennummer; }     
      
      var name = kdNrNameMap[kundennummer];
      if(!name){
        kdNrNameMap[kundennummer] = "...";
        _http.get("api/intern/crm/"+kundennummer + "/name", function(res){
          kdNrNameMap[kundennummer] = res.name.substr(0, 18);
        });
      }
      
      return name + " (" + kundennummer + ")";
    };

    vm.createTicket = function(){
    vm.kunde = "";
      vm.open = {
        status: "Offen",
        inhalt: "",
        ersteller: currentUser(),
        bearbeiter: currentUser(),
        kunde: "",
        meta: {
          erstellt: currentDate(false)
        }
      };
      focusInhalt();
    };

    vm.updateKunde = function(){
      vm.kunde = "";
      if(!vm.open || !vm.open.kunde){ return;  }
      var kdnr = vm.open.kunde;
      if(!/^T?K?[0-9]*$/.test(kdnr)){ return; }
      _http.get("api/intern/crm/" + kdnr + "/name", function(res){
        vm.kunde = res.name;
        if(res.name === 'unbekannt'){
          vm.kundeE = false;
        }else{
          vm.kundeE = true;
        }
      });
    };

    var findLinks = function(){
      if(!vm.open || !vm.open.inhalt){
        vm.links = [];
        return;
      }
      var regexp = /(QLC|KB|MC|QLDS|IDS|QLVP|IVP|MD|RMD|FD)-[0-9]+/g;
      var links = vm.open.inhalt.match(regexp);
      if(!links){
        vm.links = [];
        return;
      }
      vm.links = links.map(function(l){
        return {
          text: l,
          link: 
              l.startsWith("QLC") ? "https://quicklohn.atlassian.net/browse/"+ l            :
              l.startsWith("KB")  ? "https://quicklohn.atlassian.net/browse/"+ l            :
              l.startsWith("MC")  ? "https://quicklohn.atlassian.net/browse/"+ l            :
              l.startsWith("QLDS")? "#datendetails?table=QLDS&id=" + l.replace("QLDS-", "") :
              l.startsWith("IDS") ? "#datendetails?table=IDS&id="  + l.replace("IDS-" , "") :
              l.startsWith("QLVP")? "#datendetails?table=QLVP&id=" + l.replace("QLVP-", "") :
              l.startsWith("IVP") ? "#datendetails?table=IVP&id=" + l.replace("IVP-", "") :
              l.startsWith("MD")  ? "#datendetails?table=MELDEDATEI&id=" + l.replace("MD-", "") :
              l.startsWith("RMD") ? "#datendetails?table=RUECKMELDEDATEI&id=" + l.replace("RMD-", "") :
              l.startsWith("FD")  ? "https://newaccount1628669553354.freshdesk.com/helpdesk/tickets/" + l.replace("FD-", "") :
              ""
        };
      });
    };

    // **************************************************************************
    // **************************************************************************
    // **************************************************************************
    // ***** load & pageUpdated & save
    vm.loading = false;

    var openTicketIfRequired = function(){
      var ticketIdStr = ToolsService.qs("ticket");
      if(!ticketIdStr){ return; }
      var ticketId = parseInt(ticketIdStr);
      if(!vm.open || vm.open.id !== ticketId){
        var found = false;
        vm.data.forEach(function(t){
          if(t.id === ticketId){
            found = true;
            vm.setOpen(t);
          }
        });
        if(!found){
          if(vm.filter.bearbeiter !== 'alle' || vm.filter.ersteller !== 'alle' || !vm.filter.status_offen || !vm.filter.status_geschlossen){
            vm.filter.bearbeiter = 'alle';
            vm.filter.ersteller = 'alle';
            vm.filter.status_offen = true;
            vm.filter.status_geschlossen = true;
          }else{
            ToolsService.showToast("Ticket " + ticketId + " konnte in der aktuellen Liste nicht gefunden werden. Suche wurde erweitert.", "info");
          }
        }
      }
    };

    var load = function () {
      vm.loading = true;

      if(!vm.filter.status_offen && !vm.filter.status_geschlossen){
        vm.data = [];
        vm.loading = false;
        return;
      }

      // build query
      var queryFilter = "";
      if(vm.filter.status_offen && !vm.filter.status_geschlossen){
        queryFilter = "status=offen";
      }
      if(!vm.filter.status_offen && vm.filter.status_geschlossen){
        queryFilter = 'status=geschlossen';
      }
      if(vm.filter.ersteller !== 'alle'){
        if(queryFilter) { queryFilter += "&"; }
        queryFilter += "ersteller=" +  vm.filter.ersteller;
      }
      if(vm.filter.bearbeiter !== 'alle'){
        if(queryFilter) { queryFilter += "&"; }
        queryFilter += "bearbeiter=" + vm.filter.bearbeiter;
      }
      if(queryFilter){ queryFilter = "?" + queryFilter; }

      _http.get("api/intern/crm/tickets" + queryFilter, function (data) {
        vm.data = data;
        vm.loading = false;
        openTicketIfRequired();

        var neuesTicket = ToolsService.qs("neu");
        if(neuesTicket){
          vm.createTicket();
          vm.open.kunde = neuesTicket;
          vm.updateKunde();
        }
      }, "Fehler beim Laden der Tickets");
    };

    $scope.$on('pageUpdated', function (event, page) {
      openTicketIfRequired();
    });

    load();
  });
})();
