
/* [src] src/reporting-app/ZeitraumController.js */
(function() {
  "use strict";
  
  angular.module("ReportingApp").controller("ZeitraumController",

    function(Requests, moment, _, $scope, uibDateParser) {
    
      moment.locale("de");
      var vm_root = this;
      var vm = {};
      vm_root.model = vm;
      vm_root.userFromDateChanged = userFromDateChanged;
      vm_root.userToDateChanged = userToDateChanged;
      vm_root.update = update;

      vm_root.onChangeManualFrom = function(elem) {
        var date = moment(elem.val(), "DD.MM.YYYY");
        if (date.isValid()) {
          elem.val(date.format("DD.MM.YYYY"));
          vm.selectedDate.fromForUser = date.toDate();
        }
      };
      vm_root.onChangeManualTo = function(elem) {
        var date = moment(elem.val(), "DD.MM.YYYY");
        if (date.isValid()) {
          elem.val(date.format("DD.MM.YYYY"));
          vm.selectedDate.toForUser = date.toDate();
        }
      };
      
      var customDateItem = _dateItemTemplate().setCustom(true);
      var lastFrom, lastTo, lastCustom;
      vm.mode = $scope.mode;
      vm.toDateExcluded = $scope.toDateExcluded;
      vm.dates = (vm.mode === 'YEAR') ? getDatesByYear() : getDatesByMonth();
      vm.openFromPopup = function() { vm.popupFrom = !vm.popupFrom; update(); };
      vm.openToPopup = function() { vm.popupTo = !vm.popupTo; update(); };
      vm.selectedDate = $scope.selectedDate;
      
      watchSynchronizedModels();

      /**
       * Prüft, ob das gleiche Model von einem anderen zeitraum-Control verwendet wird und falls ja,
       * synchronisiert die Models um genau das gleiche in beiden Controls zu verwenden
       */
      function watchSynchronizedModels() {
        var changeListener = function() {};

        $scope.$watch(function() { return $scope.selectedDate; }, function() {
          vm = $scope.selectedDate.$$model;
          vm_root.model = vm;
          changeListener();
          changeListener = $scope.$watch(function() { return vm.selectedDate; }, function() {
            update();
          });
        });
      }
      
      function update() {
        if (vm.selectedDate.custom) {
          var from = vm.selectedDate.fromForUser && moment(vm.selectedDate.fromForUser);
          var to = vm.selectedDate.toForUser && moment(vm.selectedDate.toForUser);
          if (to && vm.toDateExcluded) {
            to.add(1, 'days');
          }
          if (from && to) {
            _triggerChange(from, to, true);
          }
        } else {
          _triggerChange(moment(vm.selectedDate.from, 'YYYYMMDD'), moment(vm.selectedDate.to, 'YYYYMMDD'), false);
        }
      }
      
      function _triggerChange(from, to, custom) {
        if (from && to) {
          if (!from.isSame(lastFrom) || !to.isSame(lastTo) || custom !== lastCustom) {
            $scope.selectedDate = vm.selectedDate;
            $scope.onChange()(from, to, custom);
            lastFrom = moment(from);
            lastTo = moment(to);
            lastCustom = custom;
          }
        }
      }
      
      function userFromDateChanged() {
        update();
      }
      
      function userToDateChanged() {
        update();
      }

      function getDatesByMonth() {
        var current = moment().date(1);
        var count = 6;
        var result = [];
        while (count > 0) {
          var item = _dateItemTemplate()
            .setLabel(current.format('MMMM YY'))
            .setFrom(current.format("YYYYMMDD"))
            .setTo(moment(current).add(1, 'months').format("YYYYMMDD"))
            .setInterval('MONTHLY');
          result.push(item);
          if (!vm.selectedDate) {
            vm.selectedDate = item;
          }
          current.subtract(1, 'months');
          --count;
        }
        result.push(customDateItem);
        update();
        return result;
      }

      function getDatesByYear() {
        var current = moment().date(1);
        var count = 3;
        var result = [];
        while (count > 0) {
          var from = moment(current).add(-1, 'years').add(1, 'months');
          var to = current;
          var item = _dateItemTemplate()
            .setLabel(from.format('MMMM YYYY') + " - " + to.format('MMMM YYYY'))
            .setFrom(from.format("YYYYMMDD"))
            .setTo(moment(to).add(1, 'months').format("YYYYMMDD"))
            .setInterval('YEARLY');
          result.push(item);
          if (!vm.selectedDate) {
            vm.selectedDate = item;
          }
          current.subtract(1, 'years');
          --count;
        }
        result.push(customDateItem);
        update();
        return result;
      }

      function _dateItemTemplate() {
        var result = {
          label: '<Frei wählen>',   // Titel des Elements
          from: null,               // Von-Datum (vorselektiert)
          to: null,                 // Bis-Datum (vorselektiert)
          fromForUser: null,        // Von-Datum bei freier Auswahl
          toForUser: null,          // Bis-Datum bei freier Auswahl
          interval: 'MONTHLY',      // Intervall (DAILY, WEEKLY, MONTHLY, FULL)
          custom: false,            // true, falls fromForUser/toForUser verwendet werden soll
          $$model: vm
        };
        result.setLabel = function(label) { result.label = label; return result; };
        result.setFrom = function(from) { result.from = from; return result; };
        result.setTo = function(to) { result.to = to; return result; };
        result.setInterval = function(interval) { result.interval = interval; return result; };
        result.setCustom = function(custom) { result.custom = custom; return result; };
        return result;
      }

      return vm_root;
    }
  );

})();
