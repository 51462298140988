
/* [src] src/reporting-app/ReportingController.js */

(function() {
  "use strict";

  angular.module("ReportingApp").controller("ReportingController",
    
    function($uibModal, ReportingService, _, $scope) {

      var vm = this;

      vm.state = {
        tabIndex: 0
      };
      vm.loading = false;
      vm.onChangeZeitraum = onChangeZeitraum;
      vm.drillDownErfolg = _buildDrillDownErfolg;
      vm.drillDownTeilerfolgFachlich = _buildDrillDownTeilerfolgFachlich;
      vm.drillDownMisserfolgFachlich = _buildDrillDownMisserfolgFachlich;
      vm.drillDownMisserfolgTechnisch = _buildDrillDownMisserfolgTechnisch;

      function onChangeZeitraum(from, to, custom) {
        var sbv = getStatsByVerfahren(from.format("YYYYMMDD"), to.format("YYYYMMDD"), custom ? "FULL" : "MONTHLY");
        if (sbv) {
          vm.statsByVerfahren = sbv;
        }
      }

      function _showDrilldown(drillDown) {
        var modalInstance;
        modalInstance = $uibModal.open({
          animation: true,
          ariaLabelledBy: 'modal-title',
          ariaDescribedBy: 'modal-body',
          size: "lg",
          templateUrl: '/reporting-app/drilldown.html',
          controller: 'DrillDownViewController',
          controllerAs: 'ctrl',
          appendTo: undefined,
          resolve: {
            drillDown: function() {
              return drillDown;
            }
          }
        }).closed.then(function() {
          _.forEach(drillDown.reloadAfter, function(v) {
            v();
          });
        });
        return modalInstance;
      }
      
      function _buildDrillDownErfolg(verfahren) {
        var drilldownData = verfahren.data.drilldownFn(verfahren.data, 'erfolg');
        _showDrilldown(drilldownData);
      }

      function _buildDrillDownTeilerfolgFachlich(verfahren) {
        var drilldownData = verfahren.data.drilldownFn(verfahren.data, 'teilerfolg_fachlich');
        _showDrilldown(drilldownData);
      }

      function _buildDrillDownMisserfolgFachlich(verfahren) {
        var drilldownData = verfahren.data.drilldownFn(verfahren.data, 'misserfolg_fachlich');
        _showDrilldown(drilldownData);
      }

      function _buildDrillDownMisserfolgTechnisch(verfahren) {
        var drilldownData = verfahren.data.drilldownFn(verfahren.data, 'misserfolg_technisch');
        _showDrilldown(drilldownData);
      }

      var oldFrom = "";
      var oldTo = "";
      var oldInterval = "";
      function getStatsByVerfahren(from, to, interval) {
        if (("" + from) === oldFrom && ("" + to) === oldTo && ("" + interval) === oldInterval) {
          return;
        }
        oldFrom = "" + from;
        oldTo = "" + to;
        oldInterval = "" + interval;
        var verfahren = {
          aag: ReportingService.loadAagStats,
          beitragserhebung: ReportingService.loadBeitragserhebungStats,
          beitragsnachweis: ReportingService.loadBNachwStats,
          betriebsdaten: ReportingService.loadBetriebsdatenStats,
          gkvmm: ReportingService.loadGkvMmStats,
          elstam: ReportingService.loadElstamStats,
          lndabfrage: ReportingService.loadLndAbfrageStats,
          lndnachweis: ReportingService.loadLndNachweisStats,
          lsta: ReportingService.loadLStAStats,
          lstb: ReportingService.loadLStBStats,
          sofortmeldung: ReportingService.loadSofortmeldungStats,
          svmeldung: ReportingService.loadSVMeldungStats,
          uvjahresmeldung: ReportingService.loadUVJahresmeldungStats,
          vsnrabfrage: ReportingService.loadVsnrAbfrageStats
        };

        var results = [];
        var loaders = [];
        _preload(verfahren, results, loaders, from, to, interval);
        _loadOrdered(loaders);

        return results;
      }

      function _preload(verfahren, results, listOfLoaders, from, to, interval) {
        _.forEach(verfahren, function(fn, key ) {
          var result = { data: null };
          listOfLoaders.push(fn(from, to, interval, result));
          results.push(result);
        });
      }
      
      function _loadOrdered(loaders) {
        vm.loading = true;

        function proceed() {
          if (current < last) {
            loaders[current]().then(function () {
              ++current;
              proceed();
            }, function(failed) {
              ++current;
              proceed();
            });
          } else {
            vm.loading = false;
          }
        }

        var current = 0, last = loaders.length;

        proceed();
      }

      return vm;
     }
   );
  
})();
