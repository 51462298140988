
/* [src] src/support-app/KundenCsvImportController.js */
(function() {
  "use strict";

  angular.module("MeldecenterApp").controller("KundenCsvImportController", function ($scope, _http, ToolsService, Requests) {
    $scope.setTitle("Kunden CSV-Import");

    var vm = this;

    vm.buttonDisabled = false;
    vm.input = "";
    vm.result = "(bitte Import starten)";

    vm.importStarten = function () {
      // ***** disable button for 5 seconds
      vm.buttonDisabled = true;
      setTimeout(function(){ vm.buttonDisabled = false; $scope.$apply();}, 5000);
      
      _http.post("api/intern/kunden/csv-import", {"csv": vm.input}, function(result){
        vm.result = result.msg;
        ToolsService.showToast("Import abgeschlossen, siehe Protokoll", "info");
      });
    };
  });
})();