
/* [src] src/support-app/EvptRueckmeldungController.js */
(function () {
  "use strict";

  angular.module("MeldecenterApp").controller("EvptRueckmeldungController", function ($scope, $timeout, ToolsService, _http, Requests) {
    $scope.setTitle("Rückmeldungen ins eVpT System einspielen");

    var vm = this;
    
    // *****************************************************************************************************************
    // ***** Rückmeldungen hochladen
    vm.data = {};
    
    _http.get("api/intern/evpt/info", function (data) {
      vm.data = data;
    });
    
    vm.inject = {
      format: "isf",
      type: "ELSTAM_AENDERUNGSLISTE",
      content: ""
    };
    vm.injectUrl = "XX";
    vm.injectResult = {};
    
    $scope.$watch(function(){ return "" + vm.inject.format + vm.inject.type;}, function(){ 
      vm.injectUrl = "POST {SERVER}:{PORT}/api/intern/evpt/inject/" + vm.inject.format + "/" + vm.inject.type; 
    });
    
    
    vm.rueckmeldungSpeichern = function(){
      _http.post("api/intern/evpt/inject/" + vm.inject.format + "/" + vm.inject.type, 
      {content: vm.inject.content},
       function(res){
        vm.injectResult = res;
      });
    };
    
    return vm;
  });
})();