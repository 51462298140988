
/* [src] src/support-app/CKundenListController.js */
(function() {
  "use strict";

  angular.module("MeldecenterApp").controller("CKundenListController", function ($scope, _http, Requests) {
    $scope.setTitle("Kunden-Suche");

    var vm = this;
    
    vm.selectedFilter = "";
    vm.search = {
      fieldsToShow: ["Kdnr", "Name"],
      sortField: "Kdnr",
      sortDirection: "DESC",
      group: null,
      filter: [],
      pagesize: 100,
      page: 1,
      schnellfilter: "" 
    };
    vm.result = {
      fieldNames: ["Kdnr", "Name"],
      groups: [
        {name: "", list: []}
      ],
      groupingEnabled: false,
      total: 0,
      pages: 0, 
      page: 1
    };
    vm.rows = [];
    vm.shownGroups = {};
    
    // ************************************************************************
    vm.fehlerfilter = {
      show: false,
      text: ""
    };
    _http.getPlain("api/intern/crm/fehlerfilter", function(data){ vm.fehlerfilter.text = data; });
    vm.saveFehlerFilter = function(){
      console.log(vm.fehlerfilter.text);
      _http.post("api/intern/crm/fehlerfilter", {data: vm.fehlerfilter.text}, function(res){
      });
    };
    // ************************************************************************
    
    var setSearch = function(fieldsToShow, sortField, sortDirection, group, filter, pagesize){
      vm.search.fieldsToShow = fieldsToShow;
      vm.search.sortField = sortField;
      vm.search.sortDirection = sortDirection;
      vm.search.group = group;
      vm.search.filter = filter;
      vm.search.pagesize = pagesize ? pagesize: 100;
      vm.search.page = 1;
      
      vm.runSearch();
    };
    
    vm.vorlage = function(name){ // jshint ignore:line
      vm.selectedFilter = name;
      switch(name){                                 // Fields to show                                                             Sorted by       Direc     Group By           Filter                                                          Page-Size
        case 'alle-aktiv+tester'        : setSearch(  ["Kdnr", "Name", "Angelegt"],                                               "Angelegt"     , "DESC" , null             , ["Typ<>ExKunde", "Typ<>Kontakt"]                              , 100  ); break;
        case 'alle-aktiv'               : setSearch(  ["Kdnr", "Name"],                                                           "Kdnr"         , "DESC" , null             , ["Aktiv==aktiv"]                                              , 100  ); break;
        case 'alle'                     : setSearch(  ["Kdnr", "Name", "Aktiv", "Typ"],                                           "Kdnr"         , "ASC"  , null             , []                                                            , 100  ); break;
        case 'alle-neu-kunden'          : setSearch(  ["Kdnr", "Angelegt", "Name", "Beginn"],                                     "Angelegt"     , "DESC" , "Angelegt"       , ["Typ==Kunde"]                                                , 1000 ); break;   
        case 'alle-neu-kunden-beginn'   : setSearch(  ["Kdnr", "Angelegt", "Name", "Beginn"],                                     "Beginn"       , "DESC" , "Beginn"         , ["Typ==Kunde"]                                                , 1000 ); break;   
        case 'alle-neuen-tester'        : setSearch(  ["Kdnr", "Angelegt", "Name"],                                               "Angelegt"     , "DESC" , "Angelegt"       , ["Typ==Tester"]                                               , 1000 ); break;
        case 'alle-exkunden'            : setSearch(  ["Kdnr", "Angelegt", "Name"],                                               "Kdnr"         , "DESC" , null             , ["Typ==ExKunde"]                                              , 300  ); break;
        case 'alle-kontakte'            : setSearch(  ["Kdnr", "Angelegt", "Name"],                                               "Kdnr"         , "DESC" , null             , ["Typ==Kontakt"]                                              , 300  ); break;
        case 'group-rechnungsmodell'    : setSearch(  ["Kdnr", "Name", "Rechnungsmodell", "EMail","Mitarbeiter","Bau","Haendler"],"Kdnr"         , "ASC"  , "Rechnungsmodell", ["Aktiv==aktiv"]                                              , 10000); break;
        case 'group-rechnungssicht'     : setSearch(  ["Kdnr", "Name"],                                                           "Kdnr"         , "ASC"  , "Rechnungssicht" , []                                              , 10000); break;
        case 'group-kuendigungen'       : setSearch(  ["Kdnr", "Name", "KDatum", "KGrund"],                                       "KDatum"       , "DESC" , "KDatum"         , ["KDatum<>kein"]                                              , 10000); break;
        case 'group-sperrvermerk-aktiv' : setSearch(  ["Kdnr", "Name", "MCSperre", "Downloadabfrage"],                            "Kdnr"         , "ASC"  , "MCSperre"       , ["MCSperre<>keineSperre", "Aktiv==aktiv"]                     , 10000); break;
        case 'group-sperrvermerk'       : setSearch(  ["Kdnr", "Name", "MCSperre", "Downloadabfrage"],                            "Kdnr"         , "ASC"  , "MCSperre"       , ["MCSperre<>keineSperre"]                                     , 10000); break;
        case 'group-passwortcounter'    : setSearch(  ["Kdnr", "Name", "PWCounter"],                                              "Kdnr"         , "ASC"  , "PWCounter"      , ["PWCounter<>0x"]                                             , 10000); break;
        case 'group-anzahl-ma'          : setSearch(  ["Kdnr", "Name"],                                                           "Kdnr"         , "ASC"  , "Mitarbeiter"    , ["Aktiv==aktiv"]                                              , 10000); break;
        case 'group-bau'                : setSearch(  ["Kdnr", "Name"],                                                           "Kdnr"         , "ASC"  , "Bau"            , ["Aktiv==aktiv"]                                              , 10000); break;
        case 'group-branche'            : setSearch(  ["Kdnr", "Name"],                                                           "Kdnr"         , "ASC"  , "Branche"        , ["Aktiv==aktiv"]                                              , 10000); break;
        case 'group-downloadabfrage'    : setSearch(  ["Kdnr", "Name"],                                                           "Kdnr"         , "ASC"  , "Downloadabfrage", ["Aktiv==aktiv"]                                              , 10000); break;
        case 'zukuenftige-anpassungen'  : setSearch(  ["Kdnr", "Name"],                                                           "Kdnr"         , "ASC"  , "ZAnpassung"     , ["ZAnpassung<>keine"]                                         , 10000); break;
        case 'letzte-anrufer'           : setSearch(  ["Kdnr", "Name", "LastCall", "LastCallUser"],                               "LastCall"     , "DESC" , "LastCall"       , []                                                            , 100  ); break;
        case 'meine-letzten-anrufer'    : setSearch(  ["Kdnr", "Name", "LastCall", "LastCallUser"],                               "LastCall"     , "DESC" , "LastCall"       , ["LastCallUser==" + $scope.config.user.name.split(" ")[0]]    , 100  ); break;
        case 'group-clientversion'      : setSearch(  ["Kdnr", "Name"],                                                           "Kdnr"         , "ASC"  , "ClientVersion"  , ["Aktiv==aktiv"]                                              , 10000); break;
        case 'group-clientversion-ok'   : setSearch(  ["Kdnr", "Name"],                                                           "Kdnr"         , "ASC"  , "ClientVersion"  , ["Aktiv==aktiv", "KDatum==kein"]                                              , 10000); break;
        case 'group-dabeiseit'          : setSearch(  ["Kdnr", "Name"],                                                           "Kdnr"         , "ASC"  , "DabeiSeit"      , ["Aktiv==aktiv"]                                              , 10000); break;
        case 'haendler'                 : setSearch(  ["Kdnr", "Name", 'Angelegt'],                                               "Kdnr"         , "ASC"  , null             , ["IsHaendler==true"]                                          , 10000); break;
        case 'group-haendler'           : setSearch(  ["Kdnr", "Name", 'Angelegt', 'TesterKunde'],                                "Kdnr"         , "ASC"  , "Haendler"       , ["Typ<>ExKunde", "Typ<>Kontakt"]                              , 10000); break;
        case 'plz'                      : setSearch(  ["Plz", "Kdnr", "Name"],                                                    "Plz"          , "ASC"  , null             , ["Aktiv==aktiv"]                                              , 10000); break;
        case 'group-fehler'             : setSearch(  ["Kdnr", "Name"],                                                           "Kdnr"         , "ASC"  , "Fehler"         , []                                                            , 10000); break;
        case 'group-inaktiv3'           : setSearch(  ["Kdnr", "Name", "LastLogin"],                                              "LastLogin"    , "ASC"  , "InaktivMail"    , ["Aktiv==aktiv", "Inaktiv3==inaktiv"]                         , 10000); break;
        case 'group-inaktiv2'           : setSearch(  ["Kdnr", "Name", "LastLogin"],                                              "LastLogin"    , "ASC"  , "InaktivMail"    , ["Aktiv==aktiv", "Inaktiv2==inaktiv"]                         , 10000); break;
        default: console.log("UNBEKANNT '" + name + "'");
      }
    };
    
    vm.min = function(a,b){return Math.min(a,b);};
    
    vm.page = function(delta){
      if(vm.search.page === 1 && delta === -1){ return; }
      if(vm.search.page === vm.result.pages && delta === 1){ return; }
      
      vm.search.page += delta;
      vm.runSearch();
    };
    
    vm.runSearch = function(){
      _http.post("api/intern/crm/search", {data: JSON.stringify(vm.search)}, function(res){
        vm.result = res;
        vm.shownGroups = [];
        vm.shownGroups[""] = true;
        prepareRows();
        document.getElementById("schnellfilter").focus();
      });
    };
    
    vm.exportSearch = function(){
      vm.search.pagesize = 10000000;
      _http.post("api/intern/crm/search", {data: JSON.stringify(vm.search)}, function(res){
        console.log(res);
        
        var txt = "";
        res.groups.forEach(function(group){
          if(group.name){ txt += "### " + group.name + " ###\n"; }
          group.list.forEach(function(row){
            row.values.forEach(function(value){
              txt += value + "\t";
            });
            txt += "\n";
          });
        });
        
        startFileDownload("export.csv", txt);
      });
    };
    
    var startFileDownload = function download(filename, text) {
      var element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
      element.setAttribute('download', filename);
    
      element.style.display = 'none';
      document.body.appendChild(element);
    
      element.click();
    
      document.body.removeChild(element);
    };
        
    // ***** text-Filter
    vm.search.schnellfilter = "";
    vm.search.schnellfilterVolltext = false;
    
    vm.toggleSchnellfilter = function(){
      vm.search.schnellfilterVolltext = !vm.search.schnellfilterVolltext;
      vm.runSearch();
    };

    var timeout = null;
    vm.schnellfilterKeyup = function(event){
      if(timeout) { 
        clearTimeout(timeout); timeout = null; 
      }
    
      if(event.key === "Enter"){
        vm.toggleSchnellfilter();
      }else if(event.key === "Escape"){
        vm.search.schnellfilter = "";
        vm.runSearch();
      }else{
        timeout = setTimeout(function(){
          vm.runSearch();
        }, 500);
      }
    };
    
    $scope.addGlobalKeyListener({
      pagename: "ckundenliste",
      handler: function(e){
        if(e.key === "PageUp"){
          vm.page(-1);
        }else if(e.key === "PageDown"){
          vm.page(1);
        }
      }
    });
    
    vm.toggleGroup = function(groupName){
      if(groupName === ""){ return; }
      vm.shownGroups[groupName] = !vm.shownGroups[groupName];
      prepareRows(); 
    };
    vm.groupShown = function(groupName){
      var x;
      if(true){
        x = true;
      } else {
        x = false;
      }
      return vm.shownGroups[groupName]; 
    };
    
    var prepareRows = function(){
      vm.rows = [];
      vm.result.groups.forEach(function(group){
        if(group.name){
          vm.rows.push({typ: "group", cols: vm.result.fieldNames.length, name: group.name, rows: group.list.length});
        }
        
        if(vm.shownGroups[group.name]){
          group.list.forEach(function(entry){
            vm.rows.push({typ: "entry", kdnr: entry.kdnr, cols: entry.values});
          });
        }
      });
    };
    
    vm.create = function(typ){ // typ: 'tester', 'kontakt'
      var typStr = typ === 'tester' ? "Tester" : "Kontakt";
      if(confirm("Soll wirklich ein neuer " + typStr + " angelegt werden?")){
        _http.postPlain("api/intern/crm/create-"+typ, function (kdnr) {
          location.href = "#kundendetails?kdnr=" + kdnr;
        });
      }
    };
    
    vm.vorlage("alle-aktiv+tester");
    
    // ************************************************************************
    // ************************************************************************
    // ************************************************************************
    
    vm.nutzungsdaten = function(){
      var kdnr = prompt("Kundennummer", "10006");
      var month = "" + (new Date().getMonth() + 1);
      if(month.length === 1) {month = "0" + month;}
      var date = "" + new Date().getFullYear() + "-" + month;
      var zr = prompt("Zeitraum", date);
      location.href = "/api/intern/eauserver/rechnungsdaten?zeitraum=" + zr + "&mandant="+kdnr;
    };

    return true;
  });
})();
