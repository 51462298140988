
/* [src] src/support-app/FirmenController.js */
(function () {
  "use strict";

  angular.module("MeldecenterApp").controller("FirmenController", function ($scope, _http, ToolsService, ClipboardService) {
    $scope.setTitle("Firmen-Suche");
    
    var vm = this;
    
    vm.suchText = "";
    vm.loading = false;
    vm.data = [];
    vm.editMode = false;
    
    $scope.$watch(function(){
      return vm.suchText;
    }, function (newValue) {
      if(newValue === "bearbeiten"){
        vm.editMode = true;
      }
    });

    vm.load = function () {
      var toLoad = ToolsService.qs('load');
      if(toLoad){
        toLoad = window.decodeURIComponent(toLoad);
        _http.post("api/intern/firmen/ism", {firmaName: toLoad}, function (data) {
            if (!data) {
              return;
            }
            var url = "";
            data.forEach(function (ism) {
              if (url !== "") {
                url += ",";
              }
              url += ism.institution + ism.merkmal;
            });

            location.replace("/#ism?ism=" + url + "&showFirma=true");
        }, "Fehler beim Laden der ISM");
      }
    
    
      document.getElementById("suchSchlitz").focus();
      vm.reload(false);
    };
    
    $scope.$on('pageUpdated', function (event, page) {
      if (page.indexOf("firmen") > -1) {
        vm.load();
      }
    });

    
    vm.url = function(firma){
      return "#firmen?load=" + window.encodeURIComponent(firma);
    };
    
    vm.korrigiere = function(firma){
      var neu = prompt("Neuer Firmenname:", firma);
      if(!neu){
        return;
      }
      _http.post("api/intern/firmen/korrektur",{
          firmaAlt: firma,
          firmaNeu: neu
        }
      );
    };
    
    vm.copyToClipboard = function(firma){
      ClipboardService.copyTextToClipboard(firma);
    };
    
    vm.reload = function(forceReload){
      vm.loading = true;
      _http.get("api/intern/firmen?forceReload=" + forceReload, function (data) {
        vm.data = data; 
        vm.loading = false;
      }, "Fehler beim Laden der Daten");
    };

    vm.load();
  });
})();