
/* [src] src/support-app/AAGConstants.js */
(function (angular) {
  'use strict';

  var Gruende = {
    "-1": {
      short: "KERNPRÜFUNGSFEHLER",
      text: "Der Erstattungsantrag enthielt einen Kernprüfungsfehler und wurde daher nicht verarbeitet"
    },
    "0": {
      short: "KEINE ABWEICHUNG",
      text: "Der Erstattungsantrag wurde ohne Abweichungen übernommen"
    },
    "1": {
      short: "Unkorrekter Erstattungssatz",
      text: "Der verwendete Erstattungssatz war nicht korrekt"
    },
    "2": {
      short: "Zeitraum abweichend oder ausserhalb",
      text: "Der Erstattungszeitraum weicht ab bzw. liegt außerhalb des Beschäftigungszeitraums des Mitarbeiters"
    },
    "3": {
      short: "Reduziert auf BBG Ost",
      text: "Der Erstattungsbetrag wurde auf die Beitragsbemessungsgrenze 'OST' reduziert"
    },
    "4": {
      short: "Reduziert auf BBG West",
      text: "Der Erstattungsbetrag wurde auf die Beitragsbemessungsgrenze 'WEST' reduziert"
    },
    "5": {
      short: "Zeitraum teilweise ueberlappt durch EEL",
      text: "Der Erstattungszeitraum liegt teilweise in einem Entgeltersatzleistungs-Zeitraum (EEL)"
    },
    "6": {
      short: "Zeitraum teilweise in Wartefrist",
      text: "Der Erstattungszeitraum liegt teilweise innerhalb der 28-tägigen Wartefrist nach Arbeitsantritt"
    },
    "7": {
      short: "Zeitraum teilweise ausserhalb Anspruch",
      text: "Der Erstattungszeitraum liegt teilweise außerhalb des verbleibenden Fortzahlungszeitraums (z.B. da die Höchstanspruchsdauer überschritten wurde)"
    },
    "8": {
      short: "Zeitraum erster Tag noch gearbeitet",
      text: "Am ersten Tag des Erstattungszeitraums wurde noch teilweise gearbeitet; der Erstattungsbetrag wurde um diesen Anteil reduziert"
    },
    "9": {
      short: "Zeitraum teilweise ausserhalb Mutterschaftsgeldzeitraum",
      text: "Der Erstattungszeitraum liegt teilweise außerhalb des Mutterschaftgeldzeitraums"
    },
    "10": {
      short: "Zuschuss Mutterschaftsgeld nicht korrekt",
      text: "Der Zuschuss zum Mutterschaftsgeld wurde nicht korrekt berücksichtigt"
    },
    "11": {
      short: "Fortgezahlte Arbeitgeberanteile nicht pauschal beruecksichtigt nach Satzung KK",
      text: "Der Gesamtsozialversicherungsbeitrag wurde nicht korrekt nach festem Pauschalsatz berechnet (Abweichung zu den satzungsabhängigen Angaben der Krankenkasse)"
    },
    "12": {
      short: "Fortgezahlte Arbeitgeberanteile nicht in tatsaechlicher Hoehe beruecksichtigt nach Satzung KK",
      text: "Der Gesamtsozialversicherungsbeitrag wurde nicht korrekt nach tatsächlicher Höhe (Abweichung zu den satzungsabhängigen Angaben der Krankenkasse)"
    },
    "13": {
      short: "Zeitraum teilweise bereits erstattet",
      text: "Der Erstattungszeitraum überlappt bereits erstattete Zeiträume"
    },
    "14": {short: "Sonstige Abweichung", text: "Der Abweichungsgrund ist nicht näher definiert"},
    "15": {
      short: "Keine Teilnahme am Umlageverfahren festgestellt",
      text: "Der Arbeitgeber nimmt nicht am Umlageverfahren teil, daher ist keine Erstattung möglich"
    },
    "16": {
      short: "Keine Versicherungsmitgliedschaft",
      text: "Der Arbeitnehmer ist nicht krankenversichert"
    },
    "17": {
      short: "Knappschaft Bahn See Zustaendigkeit",
      text: "Die Knappschaft Minijob-Zentrale ist für den Arbeitnehmer zuständig"
    },
    "18": {
      short: "Zeitraum verjaehrt",
      text: "Der Erstattungszeitraum ist bereits verjährt (§6 Abs. 1 AAG)"
    },
    "19": {
      short: "Beschaeftigungsverbot nicht alleiniger Grund",
      text: "Beschäftigungsverbot ist nicht der alleinige Grund für den Arbeitsausfall"
    },
    "20": {
      short: "Fortgezahlte Arbeitgeberanteile nicht erstattungsfaehig nach Satzung KK",
      text: "Der Gesamtsozialversicherungsbeitrag ist nicht erstattungsfähig (Abweichung zu den satzungsabhängigen Angaben der Krankenkasse)"
    },
    "21": {
      short: "Zeitraum vollstaendig ueberlappt durch EEL",
      text: "Der Erstattungszeitraum liegt vollständig in einem Entgeltersatzleistungs-Zeitraum (EEL)"
    },
    "22": {
      short: "Zeitraum vollstaendig in Wartefrist",
      text: "Der Erstattungszeitraum liegt vollständig innerhalb der 28-tägigen Wartefrist nach Arbeitsantritt"
    },
    "23": {
      short: "Zeitraum vollstaendig ausserhalb Anspruch",
      text: "Der Erstattungszeitraum liegt vollständig außerhalb des verbleibenden Fortzahlungszeitraums (z.B. da die Höchstanspruchsdauer überschritten wurde)"
    },
    "24": {
      short: "Zeitraum vollstaendig ausserhalb Mutterschaftsgeldzeitraum",
      text: "Der Erstattungszeitraum liegt vollständig außerhalb des Mutterschaftgeldzeitraums"
    },
    "25": {
      short: "Zeitraum vollstaendig bereits erstattet",
      text: "Der Erstattungszeitraum ist bereits vollständig erstattet worden"
    },
    "26": {
      short: "Fortgezahlte Arbeitgeberanteile teilweise nicht erstattungsfaehig",
      text: "Der Antrag enthält Arbeitsentgeltbestandteile, die nicht erstattungsfähig sind"
    },
    "27": {
      short: "Keine Erstattung personenbedingt",
      text: "Für die Person besteht kein Erstattungsanspruch nach dem AAG"
    },
    "28": {
      short: "Fehlzeit aufgrund Krankheit Kind",
      text: "Die dem Erstattunsgzeitraum zugrundeliegende Fehlzeit bestand aufgrund Erkrankung des Kindes (nicht erstattungsfähig im AAG-Verfahren)"
    },
    "29": {
      short: "Keine Erstattung fehlende Mitwirkung",
      text: "Aufgrund fehlender Mitwirkung des Arbeitgebers wurde die Erstattung verweigert (§4 Abs. 1 AAG)"
    },
    "30": {
      short: "Teilnahme freiwilliges Ausgleichsverfahren",
      text: "Teilnahme am freiwilligen Ausgleichsverfahren nach § 12 AAG"
    },
    "31": {
      short: "Beschaeftigungsverbot uberlappt Schutzfrist muschg",
      text: "Das Beschäftigungsverbot liegt (teilweise) innerhalb einer Schutzfrist nach dem MuSchG"
    },
    "32": {
      short: "Kein Beschaeftigungsverbot bestehend",
      text: "Für den Erstattungszeitraum besteht kein Beschäftigungsverbot"
    }
  };

  angular
    .module("MeldecenterApp")
    .constant("AAGConstants", {

      MAX_DATEN: 1000000,
      GRUENDE: Gruende
    });

})(window.angular);
