
/* [src] src/reporting-app/LimitWidthDirective.js */
(function() {
  "use strict";

  angular.module("ReportingApp").directive('limitWidth', function($timeout) {

    function resize(width, element) {
      var bodyWidth = $("body").innerWidth();
      var target = bodyWidth * parseFloat(width) / 100.0;
      element[0].style.setProperty('width', target + 'px', 'important');
      element[0].style.setProperty('overflow', "scroll", 'important');
    }

    return {
      restrict: 'A',
      replace: true,
      link: function (scope, element, attrs) {
        
        var timeoutid;

        function refresh() {
          resize(attrs.limitWidth, element);
        }

        function refreshAfterTimeout() {
          if (timeoutid) {
            window.clearTimeout(timeoutid);
          }
          timeoutid = window.setTimeout(refresh, 50);
        }

        // MutationObserver doesn't work in this case on chrome (couldn't figure out why)
        refresh();
        scope.$watch(function() { return element.height(); }, refreshAfterTimeout );
        window.addEventListener('resize', refresh);
      }
    };
  });
})();