
/* [src] src/support-app/ISMController.js */
(function ($) {
  "use strict";

  $.fn.line = function (x1, y1, x2, y2, color) {
    return $(this).each(function () {
      var line = document.createElement("div");
      line.className = "connection-line";
      line.style.borderBottom = "2px solid";
      line.style.borderColor = color;
      line.style.position = "absolute";
      line.style.zIndex = 1000;
      var length = Math.sqrt((x1 - x2) * (x1 - x2) + (y1 - y2) * (y1 - y2));
      line.style.width = length + "px";
      var angle = Math.atan((y2 - y1) / (x2 - x1));
      line.style.top = y1 + 0.5 * length * Math.sin(angle) + "px";
      line.style.left = x1 - 0.5 * length * (1 - Math.cos(angle)) + "px";
      line.style.transform = line.style.MozTransform = line.style.WebkitTransform = line.style.msTransform = line.style.OTransform = "rotate(" + angle + "rad)";

      $(this).append(line);
    });
  };
})(jQuery);


(function () {
  "use strict";

  angular.module("MeldecenterApp").controller("ISMController", function ($scope, ToolsService, _http, Requests, $timeout) {
      var vm = this;

      /********************************************************************/
      /*** ISM CONTROL ****************************************************/
      /********************************************************************/
      vm.ismList = [];

      vm.load = function () {
        if (ToolsService.qs("id")) { // alte Links sollten auch noch funktionieren .. forward zur neuen Url
          _http.get("api/intern/ism/byid/" + ToolsService.qs("id"), function (ism) {
            newIsmSearch([ism]);
          });
        }

        document.getElementById("suchSchlitz").focus();

        vm.ismList = [];
        var queryParam = ToolsService.qs('ism');
        if (!queryParam) {
          vm.loading = false;
          vm.data = [];
          return;
        }
        vm.ismList = queryParam.split(",").map(function (str) {
          return {
            "institution": str.substr(0, 2),
            "merkmal": str.substr(2)
          };
        });

        if (vm.ismList.length === 0) {
          $scope.setTitle("ISM-Details");
        } else if (vm.ismList.length === 1) {
          $scope.setTitle("ISM-Details - " + vm.ismList[0].institution + " " + vm.ismList[0].merkmal);
        } else {
          $scope.setTitle("ISM-Details - " + vm.ismList[0].institution + " " + vm.ismList[0].merkmal + ", ...");
        }

        vm.data = [];
        vm.ismList.forEach(function (ism) {
          loadDetails(ism);
          loadData("qlds", ism.institution, ism.merkmal);
          loadData("ids", ism.institution, ism.merkmal);
        });
      };

      var loadDetails = function (ism) {
        _http.get("api/intern/ism/details/" + ism.institution + "/" + ism.merkmal, function (data) {
          ism.aktuellzugeordnet = data.aktuellzugeordnet;
        });
      };

      vm.assignIsm = function (ism, kdnr) {
        _http.post("api/intern/ism/assign", {
          institution: ism.institution,
          merkmal: ism.merkmal,
          kdnr: kdnr
        }, function () {
          loadDetails(ism);
        });
      };

      vm.removeIsm = function (ism) {
        var newIsmList = vm.ismList.filter(function (_ism) {
          return _ism.merkmal !== ism.merkmal || _ism.institution !== ism.institution;
        });
        if (vm.ismList.length === 1) {
          vm.showIsm = false;
        }
        newIsmSearch(newIsmList);
      };

      vm.onlyIsm = function (ism) {
        vm.ismSuchText = "";
        newIsmSearch([ism]);
      };

      vm.addIsm = function (ism) {
        vm.ismList.push(ism);
        if (vm.ismList.length === 2) {
          vm.showIsm = true;
        }
        newIsmSearch(vm.ismList);
      };

      var newIsmSearch = function (ismList) {
        var noDuplicates = [];
        ismList.forEach(function (ism) {
          var alreadyExists = noDuplicates.some(function (dupIsm) {
            return dupIsm.institution === ism.institution && dupIsm.merkmal === ism.merkmal;
          });
          if (!alreadyExists) {
            noDuplicates.push(ism);
          }
        });

        var queryParam = noDuplicates.map(function (ism) {
          return ism.institution + ism.merkmal;
        }).join(",");
        location.href = "#ism?ism=" + queryParam;
      };

      vm.addIsmOfFirma = function (firma) {
        ismOfFirma(firma, function (isms) {
          newIsmSearch(vm.ismList.concat(isms));
        });
      };

      vm.newIsmSearchForFirma = function (firma) {
        ismOfFirma(firma, function (isms) {
          newIsmSearch(isms);
        });
      };

      var ismOfFirma = function (firmaName, callback) {
        _http.post("api/intern/firmen/ism", {
          firmaName: firmaName
        }, function (data) {
          callback(data);
        });
      };


      /********************************************************************/
      /*** ISM SUCHE ******************************************************/
      /********************************************************************/
      vm.ismSuchText = "";
      vm.ismTrefferliste = [];
      vm.ismTrefferlisteOffen = false;
      vm.ismSucheLoading = false;
      var timeoutPromise;

      $scope.$watch(function () {
        return vm.ismSuchText;
      }, function (newVal) {
        $timeout.cancel(timeoutPromise);
        if (!newVal || newVal.length < 3) {
          vm.ismTrefferliste = [];
          vm.ismTrefferlisteOffen = false;
          vm.ismSucheLoading = false;
          return;
        }
        timeoutPromise = $timeout(function () {
          loadIsmList(newVal);
        }, 500);
      });

      var loadIsmList = function (text) {
        console.log("query for '" + text + "'");

        vm.ismSucheLoading = true;
        $scope.$apply();
        _http.post("api/intern/ism/query", {queryParam: text}, function (data) {
          vm.ismTrefferliste = data;
          vm.ismTrefferlisteOffen = true;
          vm.ismSucheLoading = false;
        });
      };

      /********************************************************************/
      /*** ISM DETAILS ****************************************************/
      /********************************************************************/
      vm.ismDetails = null;

      /********************************************************************/
      /*** BUNCHES ********************************************************/
      /********************************************************************/
      vm.showBunches = false;
      
      $scope.$watch(function () { return vm.showBunches; }, function (value) {
        var one_minute = 60 * 1000;
        var lastTime = 0;
        vm.data.forEach(function(row){
          if(value){
            var currentTime = new Date(row.date).getTime(); //new Date(row.formattedDate.replace(/(\d{2})\.(\d{2})\.(\d{4}) (\d{2}):(\d{2}):(\d{2})/,'$3-$2-$1T$4:$5:$6')).getTime();
            row.bunchStart = lastTime > 0 && lastTime - one_minute > currentTime;
            row.hideDate = !row.bunchStart && lastTime !== 0;
            lastTime = currentTime;
          }else{
            row.bunchStart = false;
            row.hideDate = false;
          }
        });
      });
      
      /********************************************************************/
      /*** TYP-Filter******************************************************/
      /********************************************************************/
      vm.typFilterOnKey = function(event){
        if(event.key === "Escape"){
          event.currentTarget.value = "";
          vm.load();
        }
        if(event.key === "Enter"){
          var filter = event.currentTarget.value.toLowerCase();
          var asteriskInFront = filter.startsWith("*");
          var asteriskAtEnd = filter.endsWith("*");
          filter = filter.replaceAll("*", "");
          
          // ***** filter type
          var data = vm.data;
          var i = 0;
          while(i < data.length){
            var entry = data[i];
            var entryType = entry.content.type.toLowerCase();
            
            var matching = false;
            
            if(asteriskInFront && asteriskAtEnd) {
              matching = entryType.indexOf(filter) > -1;
            }else if(asteriskInFront){
              matching = entryType.endsWith(filter);
            }else if(asteriskAtEnd){
              matching = entryType.startsWith(filter);
            }else{
              matching = entryType === filter;
            }
            
            if(matching){
              i++;
            }else{
              data.splice(i, 1); // aussortieren
            }
          }
        }
      };
      
      
      /********************************************************************/
      /*** LSTB ***********************************************************/
      /********************************************************************/
      vm.lstbFeature = false;
      
      $scope.$watch(function () { return vm.lstbFeature; }, function (value) {
        if(!value) { return; }
        var data = vm.data;
        
        // ***** filter LSTB
        var i = 0;
        while(i < data.length){
          var entry = data[i];
          if(entry.isRight || entry.content.type !== "LSTB"){
            data.splice(i, 1);
          }else{
            i++;
          }
        }
        
        // ***** remove existing
        vm.showConnections = false;
        $(".connection-line").remove(); // remove all connections
        
        // ***** draw lines
        setTimeout(function(){
          var i = 0;
          while(i < data.length){
            if(data[i].content.lstb && data[i].content.lstb.refkmid){
              var refkmid = data[i].content.lstb.refkmid;

              var i2 = 0;
              while(i2 < data.length){
                if(data[i2].content.lstb && data[i2].content.lstb.kmid === refkmid){
                  showConnection(i, i2);
                }
                i2++;
              }
            }
            i++;
          }
        }, 350);
      });
      
      /********************************************************************/
      /*** CONNECTIONS ****************************************************/
      /********************************************************************/
      vm.showConnections = false;
    

      // @formatter:off
      $scope.$watch(function () { return vm.showConnections; }, function () { drawConnections(); });
      $scope.$watch(function () { return vm.showShortInfo;   }, function () { setTimeout(function() { drawConnections();}, 100); });
      $scope.$watch(function () { return vm.showFirma;       }, function () { setTimeout(function() { drawConnections();}, 100); });
      $scope.$watch(function () { return vm.showKunde;       }, function () { setTimeout(function() { drawConnections();}, 100); });
      $scope.$watch(function () { return vm.showIsm;         }, function () { setTimeout(function() { drawConnections();}, 100); });
      $scope.$watch(function () { return vm.showStatus;      }, function () { setTimeout(function() { drawConnections();}, 100); });
      $scope.$watch(function () { return vm.showBunches;     }, function () { setTimeout(function() { drawConnections();}, 100); });
      // @formatter:on

      var drawConnections = function () {
        $(".connection-line").remove(); // remove all connections
        if (!vm.showConnections) {
          return;
        }
        vm.data.forEach(function (dRight, iRight) {
          if (dRight.isRight && dRight.content && dRight.content.hinid) {
            var hinid = dRight.content.hinid;
            vm.data.forEach(function (dLeft, iLeft) {
              if (dLeft.isLeft && dLeft.content && dLeft.content.id === hinid) {
                showConnection(iLeft, iRight);
              }
            });
          }
        });
      };

      var colors = ["ff4000", "40ff00", "80ff33", "ff00bf", "00ff00", "ff0000", "00bfff", "ff00ff", "0080ff", "bf00ff", "0040ff", "ff8000", "ffff00", "00ff40", "0000ff", "ff0040", "4000ff", "ff0080", "8000ff", "ffbf00", "bfff00"];
      var colorIndex = 0;

      var showConnection = function (i1, i2) {
        var $ismDaten = $("#ismDaten");
        var deltaX = $ismDaten.position().left;
        var deltaY = $ismDaten.position().top + $ismDaten.parent().parent().parent().scrollTop();
        var diff = 3;

        var l = $($(".left")[i1]);
        var r = $($(".right")[i2]);
        var color = "#" + colors[colorIndex];
        colorIndex++;
        if (colorIndex >= colors.length) {
          colorIndex = 0;
        }
          
        $("#ism")
          .line(
            deltaX + diff + l.position().left + l.width(),  // diagonal
            deltaY + l.position().top + l.height() / 2,
            deltaX - diff + r.position().left,
            deltaY + r.position().top + r.height() / 2,
            color
          )
          .line(
            deltaX + diff + l.position().left + l.width(),  // left side
            deltaY + diff + l.position().top,
            deltaX + diff + l.position().left + l.width(),
            deltaY - diff + l.position().top + l.height(),
            color
          )
          .line(
            deltaX - diff + r.position().left,              // right side
            deltaY + diff + r.position().top,
            deltaX - diff + r.position().left,
            deltaY - diff + r.position().top + r.height(),
            color
          );
      };


      /********************************************************************/
      /*** ISM VIEW *******************************************************/
      /********************************************************************/
      vm.showShortInfo = ToolsService.qs('showShortInfo') !== 'false';
      vm.showId = false;
      vm.showStatus = true;
      vm.showIsm = false;
      vm.showFirma = ToolsService.qs('showFirma') === 'true';
      vm.showKunde = ToolsService.qs('showKunde') === 'true';
      vm.loading = false;
      vm.loadingCounter = 0;
      vm.data = [];

      var loadData = function (table, institution, merkmal) {
        vm.loadingCounter++;
        vm.loading = true;

        _http.post(Requests.ismDetailDaten, {
          table: table,
          institution: institution,
          merkmal: merkmal
        }, function (data) {
          append(table, data);
          vm.loadingCounter--;
          if (vm.loadingCounter === 0) {
            vm.loading = false;
            if(vm.showBunches){
              vm.showBunches = false;
              setTimeout(function(){vm.showBunches = true; $scope.$apply();}, 10);
            }
          }
        });
      };

      var append = function (table, data) {
        data.forEach(function (d) {
          var entry = {
            content: d.content,
            date: d.date,
            formattedDate: d.dateF,
            isLeft: table === "qlds",
            isRight: table === "ids",
            url: "#datendetails?table=" + table.toUpperCase() + "&id=" + d.content.id
          };

          vm.data.push(entry);
        });
        vm.data.sort(function (a, b) {
          if (a.date < b.date) {
            return 1;
          } else if (a.date > b.date) {
            return -1;
          } else {
            return 0;
          }
        });
      };
      
      vm.deleteMode = false;
      vm.removeIfEnabled = function(event, entryToRemove){
        if(!vm.deleteMode) { return; }
        event.stopPropagation();
        event.preventDefault();
        vm.removeEntry(entryToRemove);
      };

      vm.removeEntry = function (entryToRemove) {
        var index = -1;
        vm.data.forEach(function (entry, currentIndex) {
          if (entryToRemove.content.id === entry.content.id) {
            index = currentIndex;
          }
        });
        if (index > -1) {
          vm.data.splice(index, 1); // remove 1 element at index
        }
        drawConnections();
      };

      if(ToolsService.qs('short') === 'true'){
        setTimeout(function(){
          vm.showBunches = true;
          vm.showShortInfo = false;
          $scope.$apply();
        }, 100);
      }
      
      /********************************************************************/
      /*** ISM ohne Kunde *************************************************/
      /********************************************************************/
      vm.showIsmOhneKunde = false;
      vm.ismOhneKunde = [];
      vm.ismSucheLoading = false;
      
      vm.showIsmOhneKundeTrue = function(){
        vm.showIsmOhneKunde = true;
        vm.ismSucheLoading = true;
        
        _http.get("api/intern/ism/ohne-kunde", function (isms) {
            vm.ismOhneKunde = isms;
            vm.ismSucheLoading = false;
        });
      };

      /********************************************************************/
      /********************************************************************/
      $scope.$on('pageUpdated', function (event, page) {
        if (page.indexOf("ism") > -1) {
          vm.load();
        }
      });
      vm.load();
    }
  );
})();