
/* [src] src/support-app/RechnungsDetailsController.js */
(function () {
  "use strict";

  angular.module("MeldecenterApp").controller("RechnungsDetailsController", function ($scope, ToolsService, _http, Requests, $timeout) {
    
    var vm = this;
    $scope.setTitle("Rechnung #" + ToolsService.qs("id"));
    
    // **********************************************************************
    // ***** ID
    
    vm.id = ToolsService.qs("id");   // neu oder id
    vm.rechnung = {
      status: 'Draft', 
      posten: [],
      art: 'Allgemein'
    };
    vm.artAdmin = false;
    vm.originalJson = "";
    
    vm.anyChanges    = function(){ return vm.originalJson !== angular.toJson(vm.rechnung);      };
    vm.isNeu         = function(){ return vm.id === 'neu';                                      };
    vm.isDraft       = function(){ return !vm.isNeu() && vm.rechnung.status === 'Draft';        };
    vm.isEditable    = function(){ return vm.isNeu() || vm.isDraft();                           };
    vm.isFreigegeben = function(){ return !vm.isNeu() && vm.rechnung.status === 'Freigegeben';  };
    vm.isStorniert   = function(){ return !vm.isNeu() && vm.rechnung.status === 'Storniert';    };
    vm.isStorno      = function(){ return vm.rechnung.art === 'Storno';    };
    
    // **********************************************************************
    // ***** Laden
    
    if(vm.isNeu() && ToolsService.qs("kdnr")){
      vm.rechnung.kdnr = ToolsService.qs("kdnr");
      window.location.replace("#rechnung?id=neu");
    }

    vm.load = function(){
      vm.id = ToolsService.qs("id");
      if(!vm.isNeu()){
        _http.get("api/intern/crm/rechnung/" + vm.id, function(rechnung){
          vm.rechnung = rechnung;
          vm.originalJson = angular.toJson(vm.rechnung);
        });
      }
    };
    
    vm.load();
    
    $scope.$on('pageUpdated', function (event, page) {
      if (page.indexOf("rechnung.html") > -1) {
        vm.load();
      }
    });
    
    // **********************************************************************
    // ***** Speichern
    vm.unlockArtAdmin = function(){
      if($scope.config.user.name.toLowerCase().indexOf("helmut") > -1){
        vm.artAdmin = true;
        return;
      }
      if(prompt("Passwort") === "admin"){ vm.artAdmin = true; }
    };
        
    vm.save = function(){
      _http.postPlain("api/intern/crm/rechnung", {r: angular.toJson(vm.rechnung)}, function(id){
        window.location.replace("#rechnung?id=" + id);
        vm.load();
        ToolsService.showToast('Erfolgreich gespeichert', 'info');
      }, function(fehler){
        ToolsService.showToast('Fehler beim Speichern: ' + fehler, 'error');
      });
    };
    
    vm.revert = function(){
      vm.load();
    };
    
    vm.freigeben = function(){
      if(!confirm("Soll die Rechnung wirklich freigegeben werden?")) { return; }
      _http.post("api/intern/crm/rechnung/" + vm.rechnung.id + "/freigeben", function(){
        vm.load();
        ToolsService.showToast('Erfolgreich freigegeben', 'info');
      });
    };

    vm.loeschen = function(){
      if(!confirm("Soll die Rechnung wirklich gelöscht werden?")) { return; }
      _http.post("api/intern/crm/rechnung/" + vm.rechnung.id + "/loeschen", function(){
        location.href = "#ckundenliste";
      });
    };
    
    
    vm.stornoEdit = false;
    vm.stornoText = "";
    
    vm.stornieren = function(){
      _http.postPlain("api/intern/crm/rechnung/" + vm.rechnung.id + "/stornieren",{text:vm.stornoText}, function(id){
        vm.stornoEdit = false;
        vm.stornoText = "";
        location.href = "#rechnung?id=" + id;
        vm.load();
      });
    };
    
    vm.sendMail = function(){
      if(!confirm("Soll die Rechnung wirklich per Mail an den Kunden gesendet werden?")) { return; }
      _http.post("api/intern/crm/rechnung/" + vm.rechnung.id + "/mail", function(){
        ToolsService.showToast('E-Mail versandt', 'info');
      });
    };
    
    
    // **********************************************************************
    // ***** Vorlagen
    
    vm.vorlageEinweisung = function(){
      if(!vm.rechnung.kdnr) { ToolsService.showToast("Bitte Kundennummer hinterlegen", "error"); return; }
      if(!vm.kundenname || vm.kundenname === 'unbekannt') { ToolsService.showToast("Bitte gültige Kundennummer hinterlegen", "error"); return; }
    
      var date=new Date();
      var day=date.getDate();
      if((String(day)).length===1){day='0'+day;}
      var month=date.getMonth() + 1;
      if((String(month)).length===1){ month='0'+month;}
      var dateT=day+ '.' + month + '.' + date.getFullYear();
    
      var einheiten = prompt("Wieviele Stunden wurde eingewiesen?", "1,00");
      if(!einheiten){ ToolsService.showToast("abgebrochen", "error"); return; }
      var datum = prompt("Datum der Einweisung?",  dateT);
      if(!datum){ ToolsService.showToast("abgebrochen", "error"); return; }
      
      
      _http.post("api/intern/crm/rechnung/vorlage/schulung",{
        einheiten: einheiten, datum: datum, kdnr: vm.rechnung.kdnr
      }, function(rechnung){
          var id = vm.rechnung.id;
          var kdnr = vm.rechnung.kdnr;
          
          vm.rechnung = rechnung;
          
          vm.rechnung.id = id;
          vm.rechnung.kdnr = kdnr;
      });
    };
    
    // **********************************************************************
    // ***** Kundenname
    vm.kundenname = "unbekannt";
    
    $scope.$watch(function(){ return vm.rechnung.kdnr; }, function(){
      if(vm.rechnung.kdnr){
         _http.get("api/intern/crm/" + vm.rechnung.kdnr + "/name", function(res){
          vm.kundenname = res.name;
        });
      }else{
        vm.kundenname = "unbekannt";
      }
    });
    
    // **********************************************************************
    // ***** Posten + Gesamt
    vm.gesamt = "???";
    
    var concatPosten = function(){
      if(!vm.rechnung || !vm.rechnung.posten){ return "-";}
      var x = "";
      vm.rechnung.posten.forEach(function(p){ 
        x += p.netto + "x" + p.mwst + "+";
      });
      return x;
    };
    
    $scope.$watch(function(){
      return concatPosten();
    }, function(){
      vm.calcGesamt();
    });
    
    vm.calcGesamt = function(){
      _http.get("api/intern/crm/rechnung/calcgesamt/" + concatPosten(), function(result){
        vm.gesamt = result.gesamt;
      });
    };
    
    vm.addPosten = function(){
      vm.rechnung.posten.push({
        text: "",
        konto: "8403",
        mwst: "19,00",
        netto: ""
      });
    };
    
    vm.removePosten = function(idx){
      vm.rechnung.posten.splice(idx, 1);
    };
    
    vm.updateMwst = function(posten){
      vm.konten.forEach(function(k){
        if("" +k.kontonummer === posten.konto){
          posten.mwst = k.mwst;
        }
      });
    };
    
    // **********************************************************************
    // ***** Konten
    vm.konten = [];
    _http.get("api/intern/crm/rechnung/fibu/konten?onlyertrag=true", function(konten){
      vm.konten = konten;
    });
    
    return vm;
  });
})();
