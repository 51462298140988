
/* [src] src/support-app/ODSController.js */
(function () {
  "use strict";

  angular.module("MeldecenterApp").controller("ODSController", function ($scope, ToolsService, _http, Requests, $timeout) {
    $scope.setTitle("Online-Datensicherung");
    
    var vm = this;
    
    /**
     * Enthält alle Einträge der Liste
     *
     * Daten-Einträge haben folgende Felder:
     *  - kdnr, bbnr, stnr, abrmonat, datum (formatiert dd.MM.yyyy HH:mm:ss), name
     *  - horizontal: true/false ... gibt an, ob nach dem Eintrag eine horizontale Linie kommt (bei Gruppierungen)
     *  - selected: true/false ... gibt an, ob die zugehörige Checkbox selektiert ist
     */
    vm.daten = [];

    vm.pending = false;
    vm.kunden = 0;

    /************************************************************************************************/
    /************************************************************************************************/
    // ***** Secure mode
    vm.downloadMode = false;

    vm.hardReload = function(){
      _http.post("api/intern/ods/files/forceReload", function(){
        vm.reload();
      });
    };
    
    vm.downloadEntry = function(entry){
      window.location="api/intern/ods/file/download/" + encodeURIComponent(entry.filename);
    };

    /************************************************************************************************/
    /************************************************************************************************/
    // ***** Order
    vm.sortBy = "kdnr";
    
    vm.changeOrder = function(col){
      if(vm.sortBy === col){
        vm.sortBy = col+"Desc";
      }else {
        vm.sortBy = col;
      }
      vm.reload();
    };

    vm.classForOrder = function(col){
      if(vm.sortBy.startsWith(col)){
        return "order orderSelected";
      }else{
        return "order";
      }
    };
    
    /************************************************************************************************/
    /************************************************************************************************/
    // ***** Filter
    var reloadActive = false;
    vm.filter = {
      nurLetzteDesMandanten: false
    };


    $scope.$watch(function(){ return vm.filter.nurLetzteDesMandanten; }, function () { vm.reload(); });
    $scope.$watch(function(){ return vm.filter.kdnr;                  }, function () { vm.reload(); });
    $scope.$watch(function(){ return vm.filter.name;                  }, function () { vm.reload(); });
    $scope.$watch(function(){ return vm.filter.bbnr;                  }, function () { vm.reload(); });
    $scope.$watch(function(){ return vm.filter.stnr;                  }, function () { vm.reload(); });
    $scope.$watch(function(){ return vm.filter.abrmonat;              }, function () { vm.reload(); });
    $scope.$watch(function(){ return vm.filter.datum;                 }, function () { vm.reload(); });


    vm.reload = function(force){
      if(reloadActive|| force){
        vm.pending = true;
        var url = "api/intern/ods/files?";
        url += "sortBy=" + vm.sortBy;
        url += "&nurLetzte=" + vm.filter.nurLetzteDesMandanten;
        if(vm.filter.kdnr    ){ url += "&" +     "kdnr=" + vm.filter.kdnr    ; }
        if(vm.filter.name    ){ url += "&" +     "name=" + vm.filter.name    ; }
        if(vm.filter.bbnr    ){ url += "&" +     "bbnr=" + vm.filter.bbnr    ; }
        if(vm.filter.stnr    ){ url += "&" +     "stnr=" + vm.filter.stnr    ; }
        if(vm.filter.abrmonat){ url += "&" + "abrmonat=" + vm.filter.abrmonat; }
        if(vm.filter.datum   ){ url += "&" +    "datum=" + vm.filter.datum   ; }

        _http.get(url, function(json){
          vm.daten = json;
          vm.pending = false;
        });
      }
    };

    /************************************************************************************************/
    /************************************************************************************************/
    // ***** ..

    setTimeout(function(){
      reloadActive = true;
      vm.reload();
    }, 500);
    
    return vm;
  });
})();
