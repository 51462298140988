
/* [src] src/reporting-app/ReportingGraphController.js */

(function() {
  "use strict";

  angular.module("ReportingApp").controller("ReportingGraphController",

    function($scope, Chart, ReportingGraphService, _, Requests, ClipboardService, ElstamVHInfoService, moment) {

      var vm = this;
      var canvas;
      var ctx;
      var chart;
      vm.settings = _readSettings();
      vm.chartData = {};
      vm.graphActive = true;
      vm.loading = true;
      vm.createdRules = [];

      vm.reports = [];
      Array.prototype.push.apply(vm.reports, addReportBlock("lsta", "LStA", Requests.lsta));
      Array.prototype.push.apply(vm.reports, addReportBlock("lstb", "LStB", Requests.lstb));
      Array.prototype.push.apply(vm.reports, addReportBlock("elstam", "ELStAM", Requests.elstam, ElstamVHInfoService.getInfoForVH));
      Array.prototype.push.apply(vm.reports, addReportBlock("aag", "AAG", Requests.aag));
      Array.prototype.push.apply(vm.reports, addReportBlock("gkvmm", "GKV Monatsm.", Requests.gkvmm));
      Array.prototype.push.apply(vm.reports, addReportBlock("uvsd", "UV Stammdaten", Requests.lndabfrage));
      Array.prototype.push.apply(vm.reports, addReportBlock("uvnw", "UV Lohnnachw.", Requests.lndnachweis));
      Array.prototype.push.apply(vm.reports, addReportBlock("uvjm", "UV Jahresm.", Requests.uvjm));
      Array.prototype.push.apply(vm.reports, addReportBlock("sofortmeldung", "Sofortmeldung", Requests.sofortmeldung));
      Array.prototype.push.apply(vm.reports, addReportBlock("svmeldung", "SV-Meldung", Requests.svmeldung));
      
      vm.shouldShowDataset = shouldShowDataset;
      vm.toggleShowDataset = toggleShowDataset;
      vm.onChangeZeitraum = onChangeZeitraum;
      vm.onChangeReport = update;
      vm.onChangeInterval = onChangeInterval;
      vm.isError = isError;
      vm.isWarning = isWarning;
      vm.isSuccess = isSuccess;
      vm.getClassNameForDataset = getClassNameForDataset;
      vm.setColumnWidth = setColumnWidth;
      vm.update = update;
      vm.copy = copy;
      vm.openLinkTo = openLinkTo;
      vm.canLinkTo = canLinkTo;
      
      buildContextMenu();
      watchSettingsChanges();

      function addReportBlock(baseKey, baseTitle, request, codeTranslationFn) {
        var result = [
          {
            key: baseKey,
            title: baseTitle + " Erfolg/Misserfolg",
            request: request,
            renderMode: ReportingGraphService.RENDER_ABSOLUTE
          },
          {
            key: baseKey+"_pct",
            title: baseTitle + " Erfolg/Misserfolg %",
            request: request,
            renderMode: ReportingGraphService.RENDER_PERCENT
          },
          {
            key: baseKey + "_laufzeit",
            title: baseTitle + " Laufzeit",
            request: request,
            renderMode: ReportingGraphService.RENDER_ABSOLUTE,
            duration: true
          },
          {
            key: baseKey + "_laufzeit_histogramm",
            title: baseTitle + " Laufzeit Histogramm",
            request: request,
            renderMode: ReportingGraphService.RENDER_ABSOLUTE,
            durationHistogram: true
          }
        ];
        if (codeTranslationFn) {
          result.push({
            key: baseKey + "_detail",
            title: baseTitle + " Detail",
            request: request,
            detail: true,
            renderMode: ReportingGraphService.RENDER_ABSOLUTE,
            codeTranslationFn: codeTranslationFn
          });
        }
        return result;
      }

      function openLinkTo(dataset, index) {
        var targets = dataset.dataBasedOn[index];
        var link = "/#datenliste?table=QLDS&id=";
        var isFirst = true;
        _.forEach(targets, function(target) {
          if (!isFirst) {
            link += ',';
          }
          isFirst = false;
          link += target.datensatzId;
        });
        window.open(link, '_blank');
      }

      function canLinkTo(dataset, index) {
        var targets = dataset.dataBasedOn[index];
        return targets && targets.length > 0;
      }

      function watchSettingsChanges() {
        $scope.$watch(function() { return JSON.stringify(vm.settings); }, function() {
          window.localStorage.setItem("reportingSettings", JSON.stringify(vm.settings));
        });
      }

      function buildContextMenu() {
        var $contextMenu = $("#contextMenu");
        window.addEventListener("contextmenu", function(e) {
          var table = $(e.target).closest("#data-table");
          if (table.length > 0) {
            $contextMenu.css({
              display: "block",
              left: e.pageX,
              top: e.pageY
            });
            e.preventDefault();
          }
        });
  
        $contextMenu.on("click", "a", function() {
          $contextMenu.hide();
        });
        $contextMenu.on("mouseleave", function() {
          $contextMenu.hide();
        });
      }
      
      function copy() {
        ClipboardService.copyHtmlTableToClipboard($("#data-table"), function(col) {
          return !($(col).hasClass("th-hidden"));
        });
      }
      
      function _getActiveReport() {
        return _.find(vm.reports, { key: vm.settings.activeReport });
      }
      
      function _readSettings() {
        var parsed;
        try {
          parsed = JSON.parse(window.localStorage.getItem("reportingSettings"));
        } catch (x) {
          // ignore
        }
        parsed = parsed || {};
        parsed.from = parsed.from || null;
        parsed.to = parsed.to || null;
        parsed.interval = parsed.interval || null;
        parsed.mode = parsed.mode || 'TABLE';
        parsed.activeReport = parsed.activeReport || '';
        parsed.showDatasets = parsed.showDatasets || [];
        parsed.colWidths = parsed.colWidths || [];
        return parsed;
      }
      
      function sanitizeClassNameString(str) {
        return str.replace(/[^A-Za-z0-9\-_]/g, '-');
      }
      
      function getClassNameForDataset(dataset) {
        if (dataset === null) {
          return "col_zeitraum";
        }
        return vm.settings.activeReport + "_" + sanitizeClassNameString(dataset.label) + "_column";
      }
      
      function isError(dataset) {
        return dataset.status && dataset.status.startsWith("MISSERFOLG");
      }
      
      function isWarning(dataset) {
        return dataset.status && dataset.status.startsWith("TEILERFOLG");
      }
      
      function isSuccess(dataset) {
        return dataset.status && dataset.status.startsWith("ERFOLG");
      }
      
      function shouldShowDataset(dataset) {
        var result = _.get(vm.settings.showDatasets, vm.settings.activeReport + "_" + dataset.label, true);
        return result;
      }
      
      function toggleShowDataset(dataset) {
        vm.settings.showDatasets[vm.settings.activeReport + "_" + dataset.label] = !shouldShowDataset(dataset);
      }
      
      function onChangeZeitraum(from, to) {
        vm.settings.from = from;
        vm.settings.to = to;
        update();
      }
      
      function onChangeInterval() {
        update();
      }

      function _prepare() {
        canvas = document.getElementById("barChart");
        ctx = canvas.getContext('2d');
        canvas.style.backgroundColor = 'rgba(222,222,222,255)';
        if (chart) {
          chart.destroy();
        }
      }
      
      function update() {
        var reportInfo = _getActiveReport();
        var promise;
        if (reportInfo && vm.settings.from && vm.settings.to && vm.settings.interval) {
          if (reportInfo.duration) {
            promise = ReportingGraphService.buildVerfahrenLaufzeitLineGraphData(
              reportInfo.request, vm.settings.from, vm.settings.to, vm.settings.interval,
              _renderLineChartLogarithmicTime, reportInfo.codeTranslationFn);
          } else if (reportInfo.durationHistogram) {
            promise = ReportingGraphService.buildVerfahrenHistogrammGraphData(
              reportInfo.request, vm.settings.from, vm.settings.to, vm.settings.interval,
              _renderHistogramChartLinearTime, reportInfo.codeTranslationFn);
          } else {
            if (reportInfo.detail) {
              promise = ReportingGraphService.buildVerfahrenDetailLineGraphData(
                reportInfo.request, vm.settings.from, vm.settings.to, vm.settings.interval,
                reportInfo.renderMode, _renderLineChart, reportInfo.codeTranslationFn);
            } else {
              promise = ReportingGraphService.buildVerfahrenSuccessLineGraphData(
                reportInfo.request, vm.settings.from, vm.settings.to, vm.settings.interval,
                reportInfo.renderMode, _renderLineChart);
            }
          }
        }
        if (promise) {
          vm.loading = true;
          promise.then(function() {
            _buildTableDataset();
            _updateCssClasses();
            vm.loading = false;
          });
        } else {
          vm.loading = false;
          vm.graphActive = false;
        }
      }
      
      function _buildTableDataset() {
        if (vm.chartData.datasets) {
          vm.chartData.allDatasets = vm.chartData.datasets.concat(vm.chartData.hiddenDatasets || []);
        }
      }
      
      function _existsCssRule(ruleName) {
        return vm.createdRules[ruleName];
      }
      
      function _updateCssClasses() {
        _.forEach(vm.chartData.allDatasets, function(dataset) {
          var className = getClassNameForDataset(dataset);
          var existingCssRule = _existsCssRule(className);
          var width = vm.settings.colWidths[className];
          if (!width) {
            width = (dataset.label.length * 10) + 10;
            vm.settings.colWidths[className] = width;
          }
          if (!existingCssRule) {
            document.styleSheets[0].insertRule(
              "." + className + " { min-width: " + width + "px; max-width:" + width + "px; width:" + width + "px; }");
          }
        });
      }
      
      function setColumnWidth(dataset, width) {
        var rule = _existsCssRule(getClassNameForDataset(dataset));
        if (!rule) {
          throw "Unbekanntes Dataset/CSS-Rule: " + getClassNameForDataset(dataset);
        }
        _setColumnWidthForCssRule(rule, width);
      }

      function _setColumnWidthForCssRule(rule, width) {
        rule.style.setProperty("max-width", width + "px", "Important");
        rule.style.setProperty("width", width + "px", "Important");
        rule.style.setProperty("min-width", width + "px", "Important");
      }

      function _renderLineChart(chartData, logarithmic) {
        if (logarithmic) {
          _renderLineChartLogarithmicTime(chartData);
        } else {
          _renderLineChartLinear(chartData);
        }
      }

      function _renderLineChartLinear(chartData) {
        if (chartData) {
          vm.graphActive = true;
          vm.chartData = chartData;
        } else {
          vm.graphActive = false;
          vm.chartData = {};
          return;
        }

        _prepare();
        
        var options = {
          legend: { position: "right" },
          tooltips: _standardTooltipSettings(),
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              },
              scaleLabel: {
                display: true,
                labelString: chartData.labelString,
                fontSize: 20
              }
            }]
          }
        };
// Chart declaration:
        chart = new Chart(ctx, {
          type: 'line',
          data: chartData,
          options: options
        });
      }

      function _renderHistogramChartLinearTime(chartData) {
        if (chartData) {
          vm.graphActive = true;
          vm.chartData = chartData;
        } else {
          vm.graphActive = false;
          vm.chartData = {};
          return;
        }

        _prepare();
        
        var options = {
          legend: { position: "right" },
          tooltips: _standardTooltipSettings(),
          scales: {
            yAxes: [{
              stacked: true,
              ticks: {
                beginAtZero: true,
                max: 100
              },
              scaleLabel: {
                display: true,
                labelString: chartData.labelString,
                fontSize: 20
              }
            }]
          }
        };
// Chart declaration:
        chart = new Chart(ctx, {
          type: 'line',
          data: chartData,
          options: options
        });
      }

      function _renderLineChartLogarithmicTime(chartData) {
          if (chartData) {
            vm.graphActive = true;
            vm.chartData = chartData;
          } else {
            vm.graphActive = false;
            vm.chartData = {};
            return;
          }

          _prepare();
          
          var options = {
            legend: { position: "right" },
            tooltips: _durationTooltipSettings(),
            scales: {
              yAxes: [{
                type: 'logarithmic',
                ticks: {
                  beginAtZero: true,
                  callback: function(value) {
                    var dur = moment.duration(value * 1000);
                    return dur.humanize();
                  }
                },
                afterBuildTicks: function(chart){
                  chart.ticks = [
                    60,
                    300,
                    600,
                    1800,
                    3600,
                    7200,
                    86400,
                    864000
                  ];
                },
                scaleLabel: {
                  display: true,
                  labelString: chartData.labelString,
                  fontSize: 20
                }
              }]
            }
          };

// Chart declaration:
        chart = new Chart(ctx, {
          type: 'line',
          data: chartData,
          options: options
        });
      }
      
      function _standardTooltipSettings() {
        return {
          callbacks: {
            label: function(tooltipItem, data) {
              return data.datasets[tooltipItem.datasetIndex].label + ": " + data.datasets[tooltipItem.datasetIndex].formattedData[tooltipItem.index];
            }
          }
        };
      }
      
      function _durationTooltipSettings() {
        return {
          callbacks: {
            beforeLabel: function (tooltipItem, data) {
              return data.datasets[tooltipItem.datasetIndex].label;
            },
            label: function (tooltipItem, data) {
              var val =
                data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
              return moment.duration(val * 1000).humanize();
            }
          }
        };
      }

      return vm;
    }
  );

})();
