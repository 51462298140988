
/* [src] src/support-app/LohnnachweisController.js */
(function () {
  "use strict";

  angular.module("MeldecenterApp").controller("LohnnachweisController", function ($scope, _http, Requests) {
    $scope.setTitle("LND Lohnnachweis");
    
    $scope.showBeschreibungen = false;
    $scope.beschreibungen = [
      ["DSLN", "Y01", "1", "Die zu stornierende Meldung ist nicht bekannt", "Für dieses Meldejahr konnte kein elektronischer Lohnnachweis als Ursprungsmeldung mit dieser Vorgangs-ID ermittelt werden."],
      ["DSLN", "Y02", "1", "Falscher Übermittlungsweg für die Stornierung dieser Meldung", "Für dieses Meldejahr wurde noch kein elektronischer Lohnnachweis mit dieser Vorgangs-ID über diesen Übertragungsweg gemeldet."],
      ["DSLN", "Y03", "0", "Das Mitglied ist nicht bekannt", "Die Zugangsdaten für die Mitgliedsnummer sind bei diesem Unfallversicherungsträger nicht gültig."],
      ["DSLN", "Y04", "0", "Das Mitglied zur stornierten Meldung ist nicht bekannt", "Die Stornierung kann mit diesen Zugangsdaten für die Mitgliedsnum- mer bei diesem Unfallversicherungsträger nicht vorgenommen werden."],
      ["DSLN", "Y05", "0", "Das Mitglied ist für dieses Meldejahr nicht bekannt", "Für dieses Meldejahr ist für die Mitgliedsnummer kein elektronischer Lohnnachweis zu erstatten."],
      ["DSLN", "Y06", "1", "Für das Mitglied ist bereits eine Meldung gespeichert", "Für dieses Meldejahr ist für die Mitgliedsnummer bereits ein elektronischer Lohnnachweis dieser meldenden/die Abrechnung durchführenden Stelle gemeldet."],
      ["DSLN", "Y07", "1", "Es wird keine Meldung für dieses Mitglied erwartet", "Für diese Mitgliedsnummer wird aufgrund des Beitragsmaßstabes kein elektronischer Lohnnachweis erwartet."],
      ["DSLN", "Y10", "1", "Die Meldung wird nicht mit Arbeitsentgelten erwartet", "Für diese Mitgliedsnummer gilt der Beitragsmaßstab nach Anzahl der „Arbeitsstunden“ (2) oder „Versicherten“ (3)."],
      ["DSLN", "Y11", "1", "Die Meldung wird auf Basis von Arbeitsentgelten erwartet", "Für diese Mitgliedsnummer gilt für den elektronischen Lohnnachweis der Beitragsmaßstab „“Entgelt“ (1)."],
      ["DSLN", "Y12", "1", "Die Gefahrtarifstelle ist bei diesem Mitglied nicht gültig", "Die Gefahrtarifstelle ist bei dieser Mitgliedsnummer in diesem Meldejahr nicht veranlagt."],

      ["DSAS", "Y01", "1", "Der zu stornierende Abruf ist nicht bekannt", "Für dieses Meldejahr wurden noch keine Stammdaten mit dieser Vorgangs-ID abgefragt."],
      ["DSAS", "Y02", "1", "Falscher Übermittlungsweg für die Stornierung dieses Abrufs", "Für dieses Meldejahr wurden noch keine Stammdaten mit dieser Vorgangs-ID über diesen Übertragungsweg abgefragt."],
      ["DSAS", "Y03", "0", "Das Mitglied ist nicht bekannt", "Die Zugangsdaten für die Mitgliedsnummer sind bei diesem Unfallversicherungsträger nicht gültig."],
      ["DSAS", "Y04", "0", "Das Mitglied zum stornierten Abruf ist nicht bekannt", "Die Stornierung kann mit diesen Zugangsdaten für die Mitgliedsnummer bei diesem Unfallversicherungsträger nicht vorgenommen werden."],
      ["DSAS", "Y05", "1", "Die übermittelte laufende Nummer ist nicht bekannt", "Die meldende/die Abrechnung durchführende Stelle wurde der Stammdatendatei noch nicht bekannt gegeben."],
      ["DSAS", "Y06", "0", "Die Abfrage der Stammdaten kann nicht registriert werden", "Die meldende/die Abrechnung durchführende Stelle kann nicht registriert werden."],
      ["DSAS", "Y07", "0", "Die Abfrage der Stammdaten ist für das Meldejahr nicht möglich", "Es liegen keine Stammdaten für die Mitgliedsnummer bei diesem Unfallversicherungsträger mit diesen Zugangsdaten vor."],
      ["DSAS", "Y08", "1", "Es gab bereits einen Abruf für dieses Mitglied und Beitragsjahr", "Es erfolgte bereits ein Abruf der Stammdaten durch die meldende/die Abrechnung durchführende Stelle für diese Mitgliedsnummer."]
    ];

    $scope.daysBackStart = 0;
    $scope.daysBackEnd = 45;
    $scope.currentDayBack = $scope.daysBackStart;
    $scope.list = [];
    $scope.filteredList = [];
    $scope.codes = [];
    $scope.hideDeleted = true;

    $scope.$watch("hideDeleted", function () {
      filterCodes();
    });

    var isFiltered = function (e) {
      if ($scope.hideDeleted && e.status === "GELOESCHT") {
        return true;
      }
      var code = $scope.codes.find(function (code) {
        return code.name === e.code;
      });
      return !code.selected;
    };

    var filterCodes = function () {
      $scope.filteredList = [];
      $scope.codes.forEach(function (c) {
        c.counter = 0;
      });
      $scope.list.forEach(function (day) {
        var dayList = [];
        day.entries.forEach(function (entry) {
          if (!isFiltered(entry)) {
            dayList.push(entry);
            $scope.codes.find(function (code) {
              return code.name === entry.code;
            }).counter++;
          }
        });
        if (dayList.length > 0) {
          $scope.filteredList.push({
            "datum": day.datum,
            "entries": dayList
          });
        }
      });


    };

    $scope.select = function (selectedCode) {
      selectedCode.selected = !selectedCode.selected;
      filterCodes();
    };

    $scope.selectOnly = function (selectedCode) {
      $scope.codes.forEach(function (code) {
        code.selected = code.name === selectedCode.name;
      });

      filterCodes();
    };

    $scope.loadNext = function () {
      if ($scope.currentDayBack > $scope.daysBackEnd) {
        $scope.currentDayBack = -1;
        return;
      }

      _http.get(Requests.lohnnachweisLadeBestandsfehler + "/" + $scope.currentDayBack, function (result) {
        if (result.entries.length > 0) {
          $scope.list.push(result);
          result.entries.forEach(function (e) {
            var existingCode = $scope.codes.find(function (code) {
              return code.name === e.code;
            });
            if (existingCode) {
              existingCode.counter++;
            } else {
              $scope.codes.push({
                "name": e.code,
                "selected": e.code !== "DSASY03",
                "counter": 1
              });
              $scope.codes.sort(function (c1, c2) {
                return ((c1.name === c2.name) ? 0 : ((c1.name > c2.name) ? 1 : -1));
              });
            }
          });
        }
        $scope.loadNext();
        filterCodes();
      }, "Fehler beim Laden der Daten");

      $scope.currentDayBack++;
    };

    $scope.load = function () {
      $scope.list = [];
      $scope.currentDayBack = $scope.daysBackStart;
      $scope.loadNext();
    };
    $scope.load();
  });
})();
