
/* [src] src/support-app/meldecenter.js */
(function() {
  "use strict";

  (function () {
  
    /**
     *  Security - XSS Injection
     * ==========================
     * Wird in AngularJs ng-bind-html="var" verwendet, wird der HTML-Code aus 'var' an der entsprechenden Stelle eingefügt.
     * Hierbei werden HTML-Tags, wie z.B. '<a href ..' korrekt als Links dargestellt. An dieser Stelle ist es dann allerdings
     * auch möglich (bösartige) Scripte ('<script>alert(..') einzubinden, was potentiell gefährlich ist. Aus diesem Grund ruft
     * AngularJs vor solchen Aktionen $sanitize auf, um den eingefügten Code daraufhin zu prüfen.
     * Die Standard-Implementierung bricht an dieser Stelle bei jedem HTML-Tag ab und liefert nur einen leeren String zurück,
     * somit kann auch kein a-Tag geschrieben werden.
     * Normalerweise sollte ngSanitize funktionieren, aber ich habs nicht zum laufen bekommen und es ist schon derb veraltet.
     * Stattdessen wurde die Methode überschrieben und es wird DOMPurify verwendet, was wohl dem aktuellsten Stand der Technik
     * entspricht.
     * DOMPurify lässt sich noch auf 1000 Arten konfigurieren, wir beginnen erstmal mit der Standard-Konfiguration.
     */
    angular.module("MeldecenterApp").factory("$sanitize",  function(){
      return function(html) {
        return DOMPurify.sanitize(html); // jshint ignore:line
      };
    });
  
  
  
    // redirect on missing slash
    console.log("location::: " + location.href);
    if (location.href.substr(-1) !== "/"
      && location.href.substr(-5) !== ".html"
      && location.href.indexOf('?') === -1
      && location.href.indexOf("#") === -1) {
      location.href = location.href + "/";
    }
  }());

  /**
   * Sobald man einen "ng-include" tag verwendet, schaltet angular automatisch auf sein routing um.
   * Hierdurch wird index.html#abc ungefragt zu index.html#/abc ... die folgenden Zeilen verhindern das
   * SO: 23299966 und 18611214 @formatter:off
   */
  angular.module("MeldecenterApp").config(['$provide', function ($provide) {
    $provide.decorator('$browser', ['$delegate', function ($delegate) {
      $delegate.onUrlChange = function () {
      };
      $delegate.url = function () {
        return "";
      };
      return $delegate;
    }]);
  }]); // @formatter:on

  var menuShow = true;

  window.toggleMenu = function() {
    menuShow = !menuShow;
    if (menuShow) {
      $("#contenttitlebackground").css("left", 215);
      $("#contentboxbackground").css("left", 215);
      $("#contenttitlebackground").css("top", 88);
      $("#contentboxbackground").css("top", 138);
      $("#menuFadeout").css("left", 217);

      setTimeout(function() {
        $("#contenttitlebackground").css("border-radius", "0 0 0 0");
        $("#contenttitlebackground").css("box-shadow", "0 0 0 0 grey");
        $("#contentboxbackground").css("box-shadow", "0 0 0 0 grey");
        $("#contenttitlebackground").css("left", 210);
        $("#contentboxbackground").css("left", 210);
        $("#contenttitlebackground").css("top", 83);
        $("#contentboxbackground").css("top", 133);
      }, 500);
    } else {
      $("#contenttitlebackground").css("box-shadow", "-14px 0 8px 0 grey");
      $("#contentboxbackground").css("box-shadow", "-14px 0 8px 0 grey");
      $("#contenttitlebackground").css("left", 215);
      $("#contentboxbackground").css("left", 215);
      $("#contenttitlebackground").css("top", 88);
      $("#contentboxbackground").css("top", 138);
      $("#contenttitlebackground").css("border-radius", "22px 0 0 0");

      setTimeout(function() {
        $("#contenttitlebackground").css("left", 10);
        $("#contentboxbackground").css("left", 10);
        $("#menuFadeout").css("left", 12);
      }, 500);
    }
  };

})();
