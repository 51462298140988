
/* [src] src/reporting-app/ReportingGraphService.js */
(function () {
  "use strict";

  angular.module("ReportingApp").service("ReportingGraphService",

    function (Requests, _, ElstamVHInfoService, NormalizationService, moment, GetQueryCacheService) {

      var service = this;
      service.RENDER_ABSOLUTE = 'RENDER_ABSOLUTE';
      service.RENDER_PERCENT = 'RENDER_PERCENT';
      service.buildVerfahrenSuccessLineGraphData = buildVerfahrenSuccessLineGraphData;
      service.buildVerfahrenDetailLineGraphData = buildVerfahrenDetailLineGraphData;
      service.buildVerfahrenLaufzeitLineGraphData = buildVerfahrenLaufzeitLineGraphData;
      service.buildHealthInsuranceLineGraphData = buildHealthInsuranceLineGraphData;
      service.buildVerfahrenHistogrammGraphData = buildVerfahrenHistogrammGraphData;

      var colors = [
        "#4DB6AC",
        "#81C784",
        "#AED581",
        "#DCE775",
        "#FFF176",
        "#FFD54F",
        "#FFB74D",
        "#FF8A65",
        "#E57373",
        "#F06292",
        "#BA68C8",
        "#9575CD",
        "#7986CB",
        "#64B5F6",
        "#4FC3F7",
        "#90A4AE"
      ];

      function _addStandardDatasetTooltips(chartData) {
        chartData.tooltipForDatasetLabel = chartData.tooltipForDatasetLabel || {};
        var tooltips = {
          "Erfolg #": "Anzahl erfolgreiche Meldungen",
          "Teilerfolg #": "Anzahl teilweise erfolgreicher Meldungen",
          "Misserfolg fachl. #": "Anzahl fachlich fehlgeschlagener Meldungen",
          "Misserfolg techn. #": "Anzahl technisch fehlgeschlagener Meldungen",
          "Total": "Anzahl Meldungen insgesamt",
          "Erfolg %": "Anzahl erfolgreiche Meldungen in Prozemt",
          "Teilerfolg %": "Anzahl teilweise erfolgreicher Meldungen in Prozent",
          "Misserfolg fachl. %": "Anzahl fachlich fehlgeschlagener Meldungen in Prozent",
          "Misserfolg techn. %": "Anzahl technisch fehlgeschlagener Meldungen in Prozent",
        };
        _.merge(chartData.tooltipForDatasetLabel, tooltips);
      }

      function _normalizeRawLabelData(chartData) {
        if (chartData.isNormalized) {
          return;
        }
        _.forEach(chartData.pointsInTime.rawLabelData, function (data) {
          data.lowerBound = NormalizationService.normalizeDate(data.lowerBound);
          data.upperBoundExcl = NormalizationService.normalizeDate(data.upperBoundExcl);
          data.fmt = data.lowerBound.format("DD.MM.YYYY") + "-" + data.upperBoundExcl.format("DD.MM.YYYY");
        });
        chartData.isNormalized = true;
      }

      function _getTooltipLabels(chartData) {
        var result = {};
        _normalizeRawLabelData(chartData);
        for (var idx = 0; idx < chartData.pointsInTime.labels.length; ++idx) {
          result[chartData.pointsInTime.labels[idx]] = chartData.pointsInTime.rawLabelData[idx].fmt;
        }
        return result;
      }

      function _getStatusOf(values) {
        return values.datensatzStatus;
      }

      function _buildLineGraphDataset(dataset, label, color, backgroundColor, hidden, formatter, forcePercent) {
        var values = _setEmptyValuesToZero(dataset.values);
        var formattedValues = _.clone(values);
        var percent = false;
        if (forcePercent && !formatter) {
          formatter = function (val) {
            return NormalizationService.normalizedPercentStringOf(val || 0.0);
          };
          percent = true;
        }
        if (formatter) {
          formattedValues = _.map(formattedValues, formatter);
        }
        return {
          label: label,
          percent: percent,
          data: values,
          dataBasedOn: dataset.valuesBasedOn,
          formattedData: formattedValues,
          status: _getStatusOf(dataset),
          fill: false,
          backgroundColor: backgroundColor || color,
          borderColor: color,
          spanGaps: true,
          lineTension: 0.1,
          hidden: hidden
        };
      }

      function _buildVerfahrenHistogrammGraphDataset(dataset, label, color, hidden, forcePercent) {
        var values = _setEmptyValuesToZero(dataset.values);
        var formattedValues = _.clone(values);
        var percent = false;
        if (forcePercent) {
          formattedValues = _.map(formattedValues, function (val) {
            return NormalizationService.normalizedPercentStringOf(val || 0.0);
          });
          percent = true;
        }
        return {
          label: label,
          percent: percent,
          data: values,
          dataBasedOn: dataset.valuesBasedOn,
          formattedData: formattedValues,
          status: _getStatusOf(dataset),
          fill: true,
          radius: 1,
          borderWidth: 1,
          borderColor: "grey",
          backgroundColor: color,
          spanGaps: true,
          lineTension: 0.1,
          hidden: hidden
        };
      }

      function buildVerfahrenDetailLineGraphData(request, from, to, interval, renderMode, dataConsumerFn, codeTranslationFn) {
        return GetQueryCacheService
          .getCached(request, {params: {interval: interval, from: from.format("YYYYMMDD"), to: to.format("YYYYMMDD")}})
          .then(function (rawData) {
            var data = rawData.data;
            if (!data.pointsInTime) {
              dataConsumerFn(null);
              return;
            }
            var chartData = data.pointsInTime && {
              labels: data.pointsInTime.labels,
              tooltipsForLabels: _getTooltipLabels(data),
              tooltipForDatasetLabel: {},
              labelString: "Anzahl",
              datasets: [
                _buildLineGraphDataset(data.erfolg, "Erfolg #", "green", null, true),
                _buildLineGraphDataset(data.teilerfolg_fachlich, "Teilerfolg #", "yellow", null, true),
                _buildLineGraphDataset(data.misserfolg_fachlich, "Misserfolg fachl. #", "red", null, true),
                _buildLineGraphDataset(data.misserfolg_technisch, "Misserfolg techn. #", "red", "black", true),
                _buildLineGraphDataset(data.total, "Total", "blue", null, true)
              ]
            };

            var idx = 0;
            _.forEach(data.hinweise, function (values, key) {
              if (key !== "") {
                var vhInfo = codeTranslationFn && codeTranslationFn(key);
                chartData.tooltipForDatasetLabel[key] = vhInfo ? (key + ": " + vhInfo.text) : key;
                chartData.datasets.push(
                  _buildLineGraphDataset(values, key, colors[++idx]));
              }
            });

            _addStandardDatasetTooltips(chartData);
            dataConsumerFn(chartData);
          }, _createHandleErrorFunction(dataConsumerFn));
      }

      function buildVerfahrenSuccessLineGraphData(request, from, to, interval, renderMode, dataConsumerFn) {
        var erfolgKey = (renderMode === service.RENDER_ABSOLUTE) ? "erfolg" : "erfolg_pct";
        var teilErfolgFachlichKey = (renderMode === service.RENDER_ABSOLUTE) ? "teilerfolg_fachlich" : "teilerfolg_fachlich_pct";
        var missErfolgFachlichKey = (renderMode === service.RENDER_ABSOLUTE) ? "misserfolg_fachlich" : "misserfolg_fachlich_pct";
        var missErfolgTechnischKey = (renderMode === service.RENDER_ABSOLUTE) ? "misserfolg_technisch" : "misserfolg_technisch_pct";
        var labelSuffix = (renderMode === service.RENDER_ABSOLUTE) ? " #" : "";
        var labelString = (renderMode === service.RENDER_ABSOLUTE) ? "Anzahl" : "Prozent";
        return GetQueryCacheService
          .getCached(request, {params: {interval: interval, from: from.format("YYYYMMDD"), to: to.format("YYYYMMDD")}})
          .then(function (rawData) {
            var data = rawData.data;
            if (!data.pointsInTime) {
              dataConsumerFn(null);
              return;
            }
            var chartData = data.pointsInTime && {
              labels: data.pointsInTime.labels,
              tooltipsForLabels: _getTooltipLabels(data),
              labelString: labelString,
              hiddenDatasets: [],
              datasets: [
                _buildLineGraphDataset(data[erfolgKey], "Erfolg" + labelSuffix, "green", null, false, null, renderMode === service.RENDER_PERCENT),
                _buildLineGraphDataset(data[teilErfolgFachlichKey], "Teilerfolg" + labelSuffix, "yellow", null, false, null, renderMode === service.RENDER_PERCENT),
                _buildLineGraphDataset(data[missErfolgFachlichKey], "Misserfolg fachl." + labelSuffix, "red", null, false, null, renderMode === service.RENDER_PERCENT),
                _buildLineGraphDataset(data[missErfolgTechnischKey], "Misserfolg techn." + labelSuffix, "red", "black", false, null, renderMode === service.RENDER_PERCENT)
              ]
            };

            var totalDataset = _buildLineGraphDataset(data.total, "Anzahl", "blue");
            if (renderMode === service.RENDER_ABSOLUTE) {
              chartData.datasets.push(totalDataset);
            } else {
              chartData.hiddenDatasets.push(totalDataset);
            }

            _addStandardDatasetTooltips(chartData);
            dataConsumerFn(chartData);
          }, _createHandleErrorFunction(dataConsumerFn));
      }

      function buildVerfahrenLaufzeitLineGraphData(request, from, to, interval, dataConsumerFn) {
        return GetQueryCacheService
          .getCached(request, {params: {interval: interval, from: from.format("YYYYMMDD"), to: to.format("YYYYMMDD")}})
          .then(function (rawData) {
            var data = rawData.data;
            if (!data.pointsInTime) {
              dataConsumerFn(null);
              return;
            }

            function formatDuration(duration) {
              return duration ? moment.duration(duration * 1000).humanize() : "--";
            }

            var chartData = data.pointsInTime && {
              labels: data.pointsInTime.labels,
              tooltipsForLabels: _getTooltipLabels(data),
              labelString: "Dauer",
              hiddenDatasets: [
                _buildLineGraphDataset(data.total, "Anzahl", "blue", null, true)
              ],
              datasets: [
                _buildLineGraphDataset(data.laufzeitMin, "Minimale Laufzeit", "green", null, false, formatDuration),
                _buildLineGraphDataset(data.laufzeitMax, "Maximale Laufzeit", "red", null, false, formatDuration),
                _buildLineGraphDataset(data.laufzeitAvg, "Durchschn. Laufzeit", "yellow", null, false, formatDuration),
                _buildLineGraphDataset(data.laufzeitMed, "Median Laufzeit", "blue", null, false, formatDuration)
              ]
            };

            _addStandardDatasetTooltips(chartData);
            dataConsumerFn(chartData);
          }, _createHandleErrorFunction(dataConsumerFn));
      }

      function buildVerfahrenHistogrammGraphData(request, from, to, interval, dataConsumerFn) {
        return GetQueryCacheService
          .getCached(request, {
            params: {
              interval: interval,
              from: from.format("YYYYMMDD"),
              to: to.format("YYYYMMDD"),
              options: ['histogramDetails']
            }
          })
          .then(function (rawData) {
            var data = rawData.data;
            if (!data.pointsInTime) {
              dataConsumerFn(null);
              return;
            }
            var chartData = data.pointsInTime && {
              labelString: "Anteil in %",
              labels: data.pointsInTime.labels,
              tooltipsForLabels: _getTooltipLabels(data),
              hiddenDatasets: [
                _buildLineGraphDataset(data.total, "Anzahl", "blue", null, true)
              ],
              datasets: []
            };

            var idx = 0;
            _.forEach(data.histogramme, function (data, key) {
              chartData.datasets.push(_buildVerfahrenHistogrammGraphDataset(data, _buildHistogramLabel(key), colors[++idx], false, true));
            });

            _addStandardDatasetTooltips(chartData);
            dataConsumerFn(chartData);
          }, _createHandleErrorFunction(dataConsumerFn));
      }

      function _buildHistogramLabel(key) {
        if (key.length === 0 || key[0] !== 'D') {
          throw "Unbekanntes Format für Histogramm-Label: " + key;
        }
        var remaining = key.substr(1);
        var indexOfSlash = remaining.indexOf('-');
        var part1 = remaining.substr(0, indexOfSlash);
        var part2 = remaining.substr(indexOfSlash + 1);
        var val1, val2;
        if (part1 !== '*') {
          val1 = moment.duration(part1 * 1);
        }
        if (part2 !== '*') {
          val2 = moment.duration(part2 * 1);
        }
        if (val1 && val2) {
          return val1.humanize() + " bis " + val2.humanize();
        } else if (val1) {
          return "Mehr als " + val1.humanize();
        } else if (val2) {
          return "Weniger als " + val2.humanize();
        } else {
          return "Ohne";
        }
      }

      function _setEmptyValuesToZero(data) {
        return _.map(data, function (v) {
          return v || 0;
        });
      }

      function buildHealthInsuranceLineGraphData(from, to, interval, renderMode, dataConsumerFn) {
        return GetQueryCacheService.getCached(Requests.krankenkassendaten, {
          params: {
            interval: interval,
            from: from.format("YYYYMMDD"),
            to: to.format("YYYYMMDD")
          }
        })
          .then(function (rawData) {
            var data = rawData.data;
            var chartData = data.pointsInTime && {
              labels: data.pointsInTime.labels,
              tooltipsForLabels: _getTooltipLabels(data),
              datasets: [
                _buildLineGraphDataset(data.u1_avg, "U1 Ø", "green"),
                _buildLineGraphDataset(data.u1_med, "U1 med.", "red"),
                _buildLineGraphDataset(data.u1_min, "U1 min", "blue"),
                _buildLineGraphDataset(data.u1_max, "U1 max", "yellow")
              ]
            };

            _addStandardDatasetTooltips(chartData);
            dataConsumerFn(chartData);
          }, _createHandleErrorFunction(dataConsumerFn));
      }

      function _createHandleErrorFunction(dataConsumerFn) {
        return function (failed) {
          var errorCode = (failed && failed.status) || "Unbekannter Fehler";
          var errorMessage = (failed && failed.data && failed.data.message) || "Unbekannter Fehlertext";
          alert("Fehler beim Aufbereiten der Daten für das Reporting: " + errorCode + "/ " + errorMessage);
          dataConsumerFn(null);
        };
      }

      return service;
    }
  );


})();
