
/* [src] src/support-app/ElstamFehlendeElstamsController.js */
(function () {
  "use strict";

  angular.module("MeldecenterApp").controller("FElstamController", function ($scope, _http) {
    $scope.setTitle("Elstam Fehlende Antworten");

    var vm = this;

    vm.loading = true;
    vm.data = {};
    vm.config = {};

    // **********************************************************
    // ***** Config
    $scope.$watch(function () {
      return "" +
        vm.config.seit +
        vm.config.wait +
        vm.config.mailFrom +
        vm.config.mailCC +
        vm.config.mailReplyTo +
        vm.config.mailTo +
        vm.config.mailType;
    }, function () {
      _http.post("api/intern/service/fehlendeelstams/config", {config: JSON.stringify(vm.config)});
    });

    var loadConfig = function () {
      _http.get("api/intern/service/fehlendeelstams/config", function (config) {
        vm.config = config;
        vm.load();
      });
    };

    vm.load = function () {
      $scope.loading = true;
      _http.get("api/intern/service/fehlendeelstams/load/" + vm.config.seit + "/" + vm.config.wait,
        function (data) {
          $scope.data = data;
          $scope.loading = false;
        });
    };

    vm.createAnswer = function (row, _typ) {
      var typ;
      if (_typ === 'mail') {
        typ = vm.config.mailType === 'MC' ? 'mail-mc' : 'mail-client';
      } else {
        typ = _typ;
      }
      _http.post("api/intern/service/fehlendeelstams/createAnswer", {
        id: row.id,
        typ: typ
      }, function (answer) {
        row.answer = answer;
        if (answer.mailto) {
          mailViaLocalClient(answer);
        }
      });
    };

    var mailViaLocalClient = function (answer) {
      var cc = answer.cc ? "&cc=" + answer.cc : "";

      document.location = ""
        + "mailto:" + answer.mailto
        + "?subject=" + answer.title
        + "&body=" +  encodeURI(answer.content)
        + cc;
    };

    loadConfig();
  });
})();