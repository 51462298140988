
/* [src] src/support-app/ZertifikateController.js */
(function() {
  "use strict";

  angular.module("MeldecenterApp").controller("ZertifikateController", function ($scope, _http, Requests) {
    $scope.setTitle("Zertifikate");
    
    var vm = this;
    
    _http.getPlain(Requests.certificatesLoad, function (value) {
      vm.content = value;
    }, "Zertifikat-Informationen können nicht geladen werden");
    
    /************************************************************/
    vm.createCEZMode = false;
    vm.cezDaten = {
      name : "Firmenname",
      email : "mail@firmenname.de",
      herstellerId : "12345",
      herstellerVersion : "Produkt inkl. Version",
      plzOrt : "12345 Berlin",
      strasseNr : "Hauptstr. 1",
      land : "DE",
      organisation : "Orga 1",
      abteilung : "Abt 1",
      beschreibung : "Dieses Zertifikat dient zur Absicherung der Kommunikation zwischen der Quick-Lohn Software GmbH und dem Finanzamt",
      pin : "XXXX"
    };
    
    vm.createCEZ = function(){
      fetch("api/intern/certificate/create-cez", { 
        method: 'post',
        headers: {
          "Content-Type": "application/json"
        },
        body: angular.toJson(vm.cezDaten) })
        .then(res => res.blob())
        .then(res => {
          const aElement = document.createElement('a');
          aElement.setAttribute('download', "eric_private.p12");
          const href = URL.createObjectURL(res);
          aElement.href = href;
          aElement.setAttribute('target', '_blank');
          aElement.click();
          URL.revokeObjectURL(href);
        });
    
    };
    
    return vm;
  });
})();