
/* [src] src/support-app/SVParserController.js */
(function () {
  "use strict";

  angular.module("MeldecenterApp").controller("SVParserController", function ($scope, _http, Requests, ToolsService) {
    $scope.setTitle("SV-Meldungs-Parser");

    var vm = this;

    var o1 = document.getElementById("output");
    o1.onscroll = function(event){
      var o2 = document.getElementById("output2");
      if(!o2){ return; }
      o2.scrollTop = o1.scrollTop;
    };
    o1.onmouseup = function(event){
      var o2 = document.getElementById("output2");
      if(!o2){ return; }
      o2.style.width = o1.style.width;
      o2.style.height = o1.style.height;
    };

    vm.input = "";
    
    if(ToolsService.qs("ds")){    // ***** check url for direkt input
      vm.input = ToolsService.qs("ds");
      setTimeout(function(){
        vm.load();
        $scope.$apply();
      }, 300);
    }
    
    if(ToolsService.qs("dsid")){    // ***** check url for ds reference
      var id = ToolsService.qs("dsid");
      var table = ToolsService.qs("table");
      _http.get("api/intern/daten/" + table + "/" + id + "/inhalt", function(inhalt){
        vm.input = inhalt.isf;
        vm.load();
      });
    }
    vm.annahmestelle = "15451439";
    vm.output = "";

    vm.load = function () {
      _http.post("api/intern/svparser/parse", {input: vm.input}, function (result) {
        vm.result = result;
      }, function (message) {
        ToolsService.showToast("Meldung konnte nicht geparsed werden", "error");
        vm.result = message;
      });
    };

    vm.validate = function () {
      _http.postPlain("api/intern/svparser/validate", {input: vm.input, annahmestelle: vm.annahmestelle}, function (result) {
        vm.result.output = result;
      }, function (message) {
        vm.result.output = message;
      });
    };
  });
})();
