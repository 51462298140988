
/* [src] src/support-app/MeldeCenterApp.module.js */
/**
 * Meldecenter-Modul definieren
 */
(function() {
  "use strict";

  var app = angular.module("MeldecenterApp", ["jsonFormatter", "ui.bootstrap"]);
  app.constant('_', window._);
})();