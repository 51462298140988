
/* [src] src/support-app/UeberblickController.js */
(function() {
  "use strict";

  angular.module("MeldecenterApp").controller("UeberblickController", function ($scope, _http, Requests, _) {
    $scope.setTitle("Überblick");
    var vm = this;
    
    
    // ******************************************************************************************************
    // ***** Konfig + Structure
    vm.settingsOpen = false;
    vm.config = {
      short: false,
      dateFilter: "OFF",
      showFailedAlthoughDateFilter: false,
      shortNumbers: false
    };
    var configLoaded = false;
    vm.tables = [];
    
    vm.loadStructure = function(){
      vm.data = [];
      _http.get("api/intern/ueberblick/structure", function (data) {
        vm.config = data.config;
        vm.tables = data.tables;
        vm.loadData();
        setTimeout(function(){ configLoaded = true; },0);
      });
    };
    var saveConfig = function(){
      if(!configLoaded) { return; }
      _http.post("api/intern/ueberblick/config", vm.config,  function (data) {
        vm.loadStructure();
      });
    };
    
    $scope.$watch(function(){ return angular.toJson(vm.config);}, function(){ saveConfig(); });
    
    vm.typeName = function(typeName, v){
    
      if(v === 0){
          var LENGTH = 15;
          if(typeName.length>LENGTH){
            return typeName.substr(0,LENGTH-2) + "...";
          }
          else { return  typeName;}
      }else if(v === 1){
        return typeName.replace("_", "_\n");
      }
    };
    
    // ******************************************************************************************************
    
    var DELAY = 500; // ms
    vm.data = [];
  
    vm.loadData = function () {
      var queryId = "" + vm.config.short + "__" + vm.config.dateFilter + "__" + vm.config.showFailedAlthoughDateFilter;
    
      _http.get("api/intern/ueberblick/data/" + queryId, function (data) {
        vm.data = data.data;
        
        // if query is not completed ... try again to load all data
        if(!data.complete){
          setTimeout(function(){vm.loadData();}, DELAY ); 
        }
      }, "Fehler beim Laden der Daten");
    };
    
    vm.isUndefined = function(e){ return typeof(e) === 'undefined';};
    
    vm.loadStructure();
    return vm;
  });
})();