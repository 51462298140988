
/* [src] src/support-app/NichtZugeordneteAenderungslisten.js */
(function () {
  "use strict";

  angular.module("MeldecenterApp").controller("NichtZugeordneteAenderungslistenController", function ($scope, _http, Requests, ToolsService) {
    $scope.setTitle("Nicht zugeordnete Änderungslisten");

    var vm = this;

    vm.loading = false;
    vm.data = [];

    vm.load = function () {
      vm.loading = true;
      _http.get("api/intern/service/nichtzugeordneteaenderungslisten",
        function (data) {
          vm.data = data;
          vm.loading = false;
        }, "Fehler beim Laden der Listen");

      _http.getPlain("api/intern/service/nichtzugeordneteaenderungslisten/blacklist",
        function (blacklist) {
          vm.blacklist = blacklist;
        }, "Fehler beim Laden der Blacklist");
    };

    vm.saveBlacklist = function(){
      _http.post("api/intern/service/nichtzugeordneteaenderungslisten/blacklist",{blacklist: vm.blacklist},"Blacklist gespeichert", function (ex) {
        ToolsService.showToast("Fehler beim Speichern der Blacklist:\n" + ex.message, "error");
      });
    };
    
    vm.changeComment = function(entry){
      var result = prompt("Bitte Kommentar für " + entry.stnr, entry.kommentar);
      if(!result) { return; }
      entry.kommentar = result;
      _http.post("api/intern/service/nichtzugeordneteaenderungslisten/kommentar",{
        stnr: entry.stnr,
        kommentar: entry.kommentar}
      );
    };

    vm.load();
    return vm;
  });
})();
