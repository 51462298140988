
/* [src] src/reporting-app/GetQueryCacheService.js */
(function () {
  "use strict";

  angular.module("ReportingApp").service("GetQueryCacheService",

    function ($http, $q) {

      var service = this;
      var cache = {};
      service.getCached = getCached;
      service.clearCache = clearCache;

      function clearCache() {
        cache = {};
      }

      function getCached(request, params) {
        var key = request + "/" + JSON.stringify(params);
        if (cache[key]) {
          var data = cache[key];
          var result = $q.when(data);
          result.success = result.then;
          return result;
        }

        return $http
          .get(request, params)
          .then(function (data) {
            cache[key] = data;
            var result = $q.when(data);
            result.success = result.then;
            return result;
          }, function (failedData) {
            var result = $q.reject(failedData);
            return result;
          });
      }
    }
  );

})();
