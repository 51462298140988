
/* [src] src/support-app/MandantenListController.js */
(function () {
  "use strict";

  angular.module("MeldecenterApp").controller("MandantListController", function ($scope, _http, ToolsService) { //@formatter:off
    $scope.setTitle("Mandanten-Suche");
    
    $scope.loading = true;
    $scope.treffer = "0";
    $scope.mandanten = [];
    $scope.toShow = [];
    $scope.filter = {
      idnr: "",
      groupid: "",
      kdnr: "",
      name: "",
      stnr: "",
      estnr: "",
      btrnr: ""
    };

    $scope.$watch("filter.idnr", function () {
      $scope.filterList();
    });
    $scope.$watch("filter.groupid", function () {
      $scope.filterList();
    });
    $scope.$watch("filter.kdnr", function () {
      $scope.filterList();
    });
    $scope.$watch("filter.name", function () {
      $scope.filterList();
    });
    $scope.$watch("filter.stnr", function () {
      $scope.filterList();
    });
    $scope.$watch("filter.estnr", function () {
      $scope.filterList();
    });
    $scope.$watch("filter.btrnr", function () {
      $scope.filterList();
    });
    //@formatter:on


    $scope.filterList = function () {
      var indexMandant = 0, indexShow = 0;

      while (true) {
        if (indexMandant === $scope.mandanten.length) { // mandantenliste zu ende??
          $scope.treffer = "" + indexShow;
          $scope.toShow = $scope.toShow.slice(0, indexShow);
          break;
        }
        if (indexShow === 100) {                        // schon hundert erreicht?
          $scope.treffer = "> 99";
          break;
        }

        var m = $scope.mandanten[indexMandant];
        if (mandantMatchesFilter(m)) {
          $scope.toShow[indexShow] = {
            mimId: m[0],
            kdnr: m[1],
            mandantId: m[2],
            name: m[3],
            stnr: m[4],
            estnr: m[5],
            btrnr: m[6]
          };
          indexShow++;
        }

        indexMandant++;
      }
    };

    var mandantMatchesFilter = function (m) { //@formatter:off
      if ($scope.filter.idnr && m[0].indexOf($scope.filter.idnr) === -1) {
        return false;
      }
      if ($scope.filter.kdnr && m[1].indexOf($scope.filter.kdnr) === -1) {
        return false;
      }
      if ($scope.filter.groupid && m[2].indexOf($scope.filter.groupid) === -1) {
        return false;
      }
      if ($scope.filter.name && m[3].toLowerCase().indexOf($scope.filter.name.toLowerCase()) === -1) {
        return false;
      }
      if ($scope.filter.stnr && m[4].indexOf($scope.filter.stnr) === -1) {
        return false;
      }
      if ($scope.filter.estnr && m[5].indexOf($scope.filter.estnr) === -1) {
        return false;
      }
      if ($scope.filter.btrnr && m[6].indexOf($scope.filter.btrnr) === -1) {
        return false;
      }
      return true;
    }; //@formatter:on

    $scope.openMandant = function (m) {
      location.href = "#mandantendetails?mimid=" + m.mimId;
    };

    $scope.load = function () {
      $scope.loading = true;
      _http.getPlain("api/intern/mandanten", function (data) {   // TODO move to const
        $scope.mandanten = [];
        data.split("$^").forEach(function (e) {
          $scope.mandanten.push(e.split("$|"));
        });
        $scope.filter.kdnr = ToolsService.qs('kdnr');
        if (!$scope.filter.kdnr) {
          $scope.filter.kdnr = "";
        }
        $scope.filterList();
        $scope.loading = false;
      }, "Fehler beim Laden");
    };

    $scope.load();
  });
})();
