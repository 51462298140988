
/* [src] src/support-app/ConfigService.js */
(function () {
  "use strict";

  angular.module("MeldecenterApp").service("ConfigService",

    function (Requests, _http, _) {

      var service = this;

      service.applyConfig = function ($scope) {
        $scope.brand = {};

        $scope.config = {
          testSystem: false,
          user: null
        };

        _http.get("branding", function (data) {
          $scope.brand = data;
        }, function () {
          alert("Branding konnte nicht geladen werden.");
        });
      };

      return service;
    }
  );

})();
