
/* [src] src/support-app/ElstamRueckmeldungController.js */
(function () {
  "use strict";

  angular.module("MeldecenterApp").controller("ElstamRueckmeldungController", function ($scope, _http, ToolsService, Requests) {
    $scope.setTitle("Elstam Rückmeldungserzeugung");
    
    var vm = this;
    
    
    vm.stnr = "";
    vm.idnr = "";
    
    var m = new Date().getMonth();
    
    vm.lfdnr = "" + new Date().getFullYear();
    vm.lfdnr += m<10 ? "0"+m : "" + m;
    vm.lfdnr += "001";  
    
    vm.tt = "9876543210"; 
    vm.ndt = "1234567890"; 
    
    
    vm.erzeugeRueckmeldung = function(){
      _http.post("api/intern/service/elstam/erzeuge-rueckmeldung",
        { stnr: vm.stnr, idnr: vm.idnr, lfdnr : vm.lfdnr, tt: vm.tt, ndt: vm.ndt},
        function (result) {
          var id = result.id;
          ToolsService.showToast("Abmeldungsbestätigung wurde erfolgreich erzeugt: <a href='#datendetails?table=IDS&id=" + id + "'>IDS " + id + "</a>",'info');
        }, "Fehler beim Laden"
      );
    };
    
    
    return vm;
  });
})();
