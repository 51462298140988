
/* [src] src/support-app/TextnachrichtController.js */
(function () {
  "use strict";

  angular.module("MeldecenterApp").controller("TextnachrichtController", function ($scope, _http, Requests, ToolsService) {
    $scope.setTitle("Textnachricht erzeugen");
    
    var vm = this;
    
    vm.bbnr = ToolsService.qs("bbnr");
    vm.firmaName = "";
    vm.title = "";
    vm.inhalt = "";
    vm.linkTo = ToolsService.qs("ds");
    vm.linkType = "";
    vm.linkMessage = "Textnachricht (_link_) erzeugt, in der dem Kunden mitgeteilt wird, dass ";
    vm.buttonDisabled = false;
    
    $scope.$watch(function(){
      return vm.bbnr;
    }, function(newBbnr){
      _http.get("api/intern/textnachricht/firmaForBbnr/" + newBbnr, function(result){
        vm.firmaName = result.firmaName;
      }, function(error){
        vm.firmaName = "";
      });
    });
    
    $scope.$watch(function(){
      return vm.linkTo;
    }, function(newLinkTo){
      _http.get("api/intern/textnachricht/typeForEntity/" + newLinkTo, function(result){
        vm.linkType = result.type;
      }, function(error){
        vm.linkType = "";
      });
    });
    
    vm.create = function(){
      vm.buttonDisabled = true;
      _http.post("api/intern/textnachricht",{
        bbnr: vm.bbnr,
        title: vm.title,
        inhalt: vm.inhalt,
        linkTo: vm.linkTo,
        linkMessage: vm.linkMessage
      }, function(result){
        ToolsService.showToast("Textnachricht erfolgreich erzeugt (" + result.link + ")" , "info");
      });
    };  
    
    return vm;
  });
})();
