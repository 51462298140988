
/* [src] src/support-app/HaendlerRechnungsController.js */
(function () {
  "use strict";

  angular.module("MeldecenterApp").controller("HaendlerRechnungsController", function ($scope, _http, Requests) {
    $scope.setTitle("Händler-Rechnungen");

    var vm = this;
    
    vm.filter_srs  = true;
    vm.filter_srns = true;
    vm.filter_name = "";
    
    vm.haendler = [];
    
    vm.filterListe = function(h){
      if(!vm.filter_srs && h.srs){ return false; }
      if(!vm.filter_srns && !h.srs){ return false; }
      if(vm.filter_name && h.name.toLowerCase().indexOf(vm.filter_name.toLowerCase()) === -1){ return false; }
      
      return true;
    };
    
    vm.loadHaendler = function(){
      _http.get("api/intern/crm/haendler/liste",
        function (haendler) {
          vm.haendler = haendler;
        }, "Fehler beim Laden der Listen");
    };
    
    vm.kundenrechnungen = function(h){
      _http.get("api/intern/crm/haendler/" + h.kdnr + "/kunden-rechnung",
        function (kundenrechnungen) {
          h.kundenrechnungen = kundenrechnungen;
          h.showRechnungen = true;
        }, "Fehler beim Laden der Listen");
    };
    
    vm.erstelleRechnung = function(h){
      if(!confirm("Soll die Rechnung für den Händler jetzt wirklich erstellt werden?")){ return; }
      _http.post("api/intern/crm/haendler/" + h.kdnr + "/rechnung-erstellen",
        function (data) {
          window.open("#rechnung?id=" + data.id,'_blank');
        }, "Fehler beim Laden der Listen");
    };
    
    
    
    vm.loadHaendler();
    
    return vm;
  });
})();
