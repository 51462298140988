
/* [src] src/support-app/QLCVersionenController.js */
(function () {
  "use strict";

  angular.module("MeldecenterApp").controller("QLCVersionenController", function ($scope, _http, ToolsService, ClipboardService) {
    $scope.setTitle("QLC Versionen");
    
    var vm = this;
    
    vm.versionen = [];
    vm.showDelete = false;
    
    vm.load = function(){
      _http.get("api/intern/qlcversion", function(result){
        vm.versionen = result;
        vm.versionen.forEach(function(jahresversionen){
          jahresversionen.forEach(function(version){
            version.newInfo = version.info;
          });
        });
      });
    };
    
    vm.formatDate = function(x){
      return x.substr(6,2)+"."+x.substr(4,2) +"." +x.substr(0,4);
    };
    
    vm.updateVersion = function(version){
      if(version.status === "GESPERRT"){
        var isLastOfVersion = vm.versionen.some(function(jv){
          return jv.indexOf(version) === 0;
        });
        if(isLastOfVersion){
          alert("Die aktuellste Version kann nicht gesperrt werden");
          vm.load();
          return;
        }
      }
    
      _http.post("api/intern/qlcversion",{
        version: "" + version.version.jahresversion + " - " + version.version.erstelldatum.substr(0,4) +
                 "." + version.version.erstelldatum.substr(4,2) + "." + version.version.erstelldatum.substr(6,2) + 
                 "." + version.version.revision,
        status: version.status,
        info: version.newInfo
      });
      version.info = version.newInfo;
    };
    
    vm.delete = function(version){
      _http.delete("api/intern/qlcversion",{
        version: "" + version.version.jahresversion + " - " + version.version.erstelldatum.substr(0,4) +
                 "." + version.version.erstelldatum.substr(4,2) + "." + version.version.erstelldatum.substr(6,2) + 
                 "." + version.version.revision
      }, function(){
        ToolsService.showToast("Version gelöscht", "info");
        vm.load();
      });
    };
    
    vm.load();
  });
})();